import React from 'react';
import withRouter from '@/hocs/withRouter';
import { Header, Button, Form, Grid, Segment, Message, Table } from 'semantic-ui-react';
import { Container } from '../style';
import { inviteUser } from '../../api/admin/auth';
import { getAllAdminUsers, deleteAdminUser, updateAdminUser } from '../../api/admin/users';
import LinkNavbar from '../../components/linkNavbar';
import ModalConfirm from '../../components/ModalConfirm';
import { getDateTime } from '../../utils/datetime';

const emptyFormData = {
    email: '',
    password: '',
    role: '',
    first_name: '',
    last_name: '',
    slack_handle: '',
};

class AdminInviteUsers extends React.Component {
    state = {
        formData: emptyFormData,
        calendarLinks: [],
        errorMsg: '',
        success: false,
        users: [],
        editID: null,
        temporaryDeletingID: null,
        isConfirmModalShown: false,
    };

    async componentDidMount() {
        const users = await getAllAdminUsers();

        this.setState({ users });
    }

    delete = async (id) => {
        await deleteAdminUser(id);

        const users = await getAllAdminUsers();

        this.setState({ users, editID: null });
    };

    handleCloseModalConfirm = () => {
        this.setState({ isConfirmModalShown: false });
    };

    handleConfirmedDelete = () => {
        this.delete(this.state.temporaryDeletingID);
        this.handleCloseModalConfirm();
    };

    renderBody() {
        const { users } = this.state;

        return users.map((row) => {
            return (
                <Table.Row key={row.id}>
                    <Table.Cell key="1">
                        <Header as="h4">
                            <Header.Content>
                                {row.email}
                                <Header.Subheader>
                                    Last logged in:{' '}
                                    {row.last_logged_in ? getDateTime(row.last_logged_in) : ''}
                                </Header.Subheader>
                                <Header.Subheader># {row.id}</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Table.Cell>
                    <Table.Cell key="first_name">
                        <span>{row.first_name}</span>
                    </Table.Cell>
                    <Table.Cell key="last_name">
                        <span>{row.last_name}</span>
                    </Table.Cell>
                    <Table.Cell key="3">
                        <span>{row.role}</span>
                    </Table.Cell>
                    <Table.Cell key="slack_handle">
                        <span>{row.slack_handle || ''}</span>
                    </Table.Cell>
                    <Table.Cell key="4">
                        <Button
                            size="mini"
                            content="EDIT"
                            color="olive"
                            labelPosition="right"
                            icon="edit"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({
                                    formData: row,
                                    calendarLinks: row.calendar_links || [],
                                    editID: row.id,
                                });
                            }}
                        />{' '}
                        <Button
                            size="mini"
                            content="DEL"
                            color="red"
                            labelPosition="right"
                            icon="trash alternate"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({
                                    isConfirmModalShown: true,
                                    temporaryDeletingID: row.id,
                                });
                            }}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    render() {
        const { formData, calendarLinks, errorMsg, success, editID } = this.state;

        const errorBlock = errorMsg ? <Message negative>{errorMsg}</Message> : '';
        const successBlock = success ? (
            <Message>User has been successfully added/updated!</Message>
        ) : (
            ''
        );

        return (
            <Container>
                <LinkNavbar title="Manage Users" />
                <Grid centered columns={2} padded>
                    <Grid.Column>
                        <Segment>
                            {successBlock}
                            <Form
                                onSubmit={async () => {
                                    try {
                                        formData.calendar_links = calendarLinks;

                                        if (editID) {
                                            await updateAdminUser(editID, formData);
                                        } else {
                                            await inviteUser(formData);
                                        }

                                        const users = await getAllAdminUsers();
                                        this.setState({
                                            errorMsg: '',
                                            success: true,
                                            users: users,
                                            editID: null,
                                            formData: emptyFormData,
                                            calendarLinks: [],
                                        });
                                    } catch (err) {
                                        this.setState({
                                            errorMsg: err.response.data.message,
                                            success: false,
                                        });
                                    }
                                }}
                            >
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Email"
                                        name="email"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.email = data.value;

                                                return { formData };
                                            })
                                        }
                                        value={formData.email}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Password"
                                        name="password"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.password = data.value;

                                                return { formData };
                                            })
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Select
                                        fluid
                                        label="Role"
                                        name="role"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.role = data.value;

                                                return { formData };
                                            })
                                        }
                                        options={[
                                            { value: 'admin', text: 'Admin' },
                                            { value: 'system', text: 'System' },
                                            { value: 'designer', text: 'Designer' },
                                        ]}
                                        selection
                                        value={formData.role}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="First Name"
                                        name="first_name"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.first_name = data.value;

                                                return { formData };
                                            })
                                        }
                                        value={formData.first_name}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Last Name"
                                        name="last_name"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.last_name = data.value;

                                                return { formData };
                                            })
                                        }
                                        value={formData.last_name}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Slack Handle"
                                        name="slack_handle"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.slack_handle = data.value;

                                                return { formData };
                                            })
                                        }
                                        value={formData.slack_handle}
                                    />
                                </Form.Field>
                                <Segment>
                                    {(calendarLinks || []).map((link, index) => {
                                        return (
                                            <Form.Field key={index}>
                                                <Form.Input
                                                    fluid
                                                    label={`Calendar Link #${index + 1}`}
                                                    name={`calendar_link_${index + 1}`}
                                                    onChange={(ev, data) => {
                                                        const { calendarLinks } = this.state;
                                                        calendarLinks[index].calendar_link =
                                                            data.value;

                                                        this.setState({ calendarLinks });
                                                    }}
                                                    value={link.calendar_link}
                                                />
                                                <Button
                                                    size="mini"
                                                    color="red"
                                                    content="Remove Calendar Link"
                                                    type="button"
                                                    icon="trash alternate"
                                                    labelPosition="right"
                                                    onClick={(e) => {
                                                        const { calendarLinks } = this.state;
                                                        calendarLinks.splice(index, 1);
                                                        this.setState({ calendarLinks });
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </Form.Field>
                                        );
                                    })}
                                    <Button
                                        size="mini"
                                        color="teal"
                                        content="Add Calendar Link"
                                        type="button"
                                        icon="plus square outline"
                                        labelPosition="right"
                                        onClick={(e) => {
                                            const { calendarLinks } = this.state;
                                            calendarLinks.push({ calendar_link: '' });
                                            this.setState({ calendarLinks });
                                            e.stopPropagation();
                                        }}
                                    />
                                </Segment>
                                <Button
                                    size="mini"
                                    content={editID ? 'Update' : 'Invite'}
                                    color="teal"
                                    labelPosition="right"
                                    icon="edit"
                                    type="submit"
                                />
                                {editID && (
                                    <Button
                                        size="mini"
                                        content="Cancel"
                                        color="olive"
                                        labelPosition="right"
                                        icon="cancel"
                                        onClick={() => {
                                            this.setState({
                                                editID: null,
                                                formData: emptyFormData,
                                                calendarLinks: [],
                                            });
                                        }}
                                    />
                                )}
                            </Form>
                            {errorBlock}
                        </Segment>
                    </Grid.Column>
                </Grid>
                <Grid>
                    <Grid.Column>
                        <Table celled selectable sortable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>First Name</Table.HeaderCell>
                                    <Table.HeaderCell>Last Name</Table.HeaderCell>
                                    <Table.HeaderCell>Role</Table.HeaderCell>
                                    <Table.HeaderCell>Slack</Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{this.renderBody()}</Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid>
                {this.state.isConfirmModalShown && (
                    <ModalConfirm
                        onCancel={this.handleCloseModalConfirm}
                        onDelete={this.handleConfirmedDelete}
                        onClose={this.handleCloseModalConfirm}
                    ></ModalConfirm>
                )}
            </Container>
        );
    }
}

AdminInviteUsers.propTypes = {};

export default withRouter(AdminInviteUsers);
