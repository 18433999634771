import axios from 'axios';

const headers = {};

export const adminAPIInstance = axios.create({
    baseURL: process.env.ADMIN_API_URL,
    timeout: 100000,
    headers,
});

export const identityInstance = axios.create({
    baseURL: process.env.IDENTITY_API_URL,
    timeout: 100000,
    headers,
});

export const surveyAPIInstance = axios.create({
    baseURL: process.env.SURVEY_API_URL,
    timeout: 1000000,
    headers,
});

export const freqAPIInstance = axios.create({
    baseURL: process.env.ADS_API_URL,
    timeout: 1000000,
    headers,
});
