import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import { Dimmer, Loader, Grid, Card } from 'semantic-ui-react';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import { getGlobalStats } from '../../api/admin/analytics';
import { getUserSession } from '../../api/admin/auth';

class Home extends Component {
    state = {
        isLoading: false,
        globalStats: [],
    };

    componentDidMount() {
        const res = getUserSession();
        const isSystemUser = res && (res.role === 'system' || res.role === 'admin');

        if (isSystemUser) {
            this.fetchData();
        }
    }

    async fetchData() {
        this.setState({ isLoading: true });

        // Do requests in parallel
        try {
            const globalStats = await getGlobalStats();

            this.setState({
                isLoading: false,
                globalStats: globalStats.stats || [],
            });
        } catch (err) {
            this.setState({
                isLoading: false,
            });
        }
    }

    render() {
        const res = getUserSession();
        const isSystemUser = res && (res.role === 'system' || res.role === 'admin');

        if (!isSystemUser) {
            return (
                <Container>
                    <LinkNavbar title="Home" />
                </Container>
            );
        }

        if (this.state.isLoading) {
            return (
                <Dimmer active inverted>
                    <Loader size="large">Loading</Loader>
                </Dimmer>
            );
        }

        let profilesCount = 0;
        this.state.globalStats.forEach((stat) => {
            if (stat.type === 'profiles_count') {
                profilesCount = stat.value;
            }
        });

        return (
            <Container>
                <LinkNavbar title="Home" />
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header>{profilesCount}</Card.Header>
                                    <Card.Meta>Psychological Profiles Collected</Card.Meta>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

export default withRouter(Home);
