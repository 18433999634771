import { Component } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import Home from './containers/home';
import SignIn from './containers/sign-in/index';
import Users from './containers/users';
import Companies from './containers/companies';
import Games from './containers/games';
import Genres from './containers/genres';
import GamesTagging from './containers/gamesTagging';
import Taxonomy from './containers/taxonomy';
import Actions from './containers/actions';
import FrequencyReviews from './containers/frequency-reviews';
import FrequencyReviewPage from './containers/frequency-review-page';
import Creatives from './containers/creatives';
import CreativeDetails from './containers/creative-details';
import Deeplinks from './containers/deeplinks';
import AccountSettings from './containers/account-settings/index';
import AdminInviteUsers from './containers/invite-users/index';
import AssessmentCreatives from './containers/assessment-creatives';
import AssessmentRequests from './containers/assessment-requests';
import AssessmentRequestDetails from './containers/assessment-request-details';
import TeamAssessments from './containers/team-assessments';
import { getUserSession } from './api/admin/auth';

import 'semantic-ui-css/semantic.min.css';
import './style.css';

class App extends Component {
    async componentDidMount() {
        const userSession = await this.checkUserSession();

        if (userSession) {
            // check expiration date every 10 seconds
            setInterval(
                (function (self: any) {
                    return function () {
                        self.checkUserSession();
                    };
                })(this),
                10000
            );
        }
    }

    async checkUserSession() {
        const { pathname } = window.location;

        let userSession;
        try {
            userSession = await getUserSession();
        } catch (e) {
            userSession = undefined;
        }

        if (!userSession && pathname !== '/sign-in') {
            window.location.href = '/sign-in';
        }

        return userSession;
    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route element={<Home />} path="/home" />
                    <Route element={<SignIn />} path="/sign-in" />
                    <Route element={<Users />} path="/users" />
                    <Route element={<Companies />} path="/companies" />
                    <Route element={<Games />} path="/games" />
                    <Route element={<FrequencyReviewPage />} path="/frequency-reviews/:id" />
                    <Route element={<FrequencyReviews />} path="/frequency-reviews/" />
                    <Route element={<CreativeDetails />} path="/creatives/:id" />
                    <Route element={<Creatives />} path="/creatives/" />
                    <Route element={<Genres />} path="/genres" />
                    <Route element={<GamesTagging />} path="/games/tagging" />
                    <Route element={<Taxonomy />} path="/taxonomy" />
                    <Route element={<Actions />} path="/actions" />
                    <Route element={<Deeplinks />} path="/deeplinks" />
                    <Route element={<AccountSettings />} path="/account-settings" />
                    <Route element={<AdminInviteUsers />} path="/invite-users" />
                    <Route element={<AssessmentCreatives />} path="/assessment-creatives" />
                    <Route element={<AssessmentRequestDetails />} path="/assessment/requests/:id" />
                    <Route element={<AssessmentRequests />} path="/assessment/requests" />
                    <Route element={<TeamAssessments />} path="/teamAssessments" />
                    <Route element={<Navigate to="/home" />} path="/" />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;
