import React, { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import {
    Button,
    Message,
    Dimmer,
    Loader,
    AccordionTitle,
    Accordion,
    AccordionContent,
    AccordionAccordion,
    Icon,
    Input,
    Label,
    Modal,
    ModalHeader,
    ModalContent,
    ModalActions,
    List,
    ListItem,
    ListList,
} from 'semantic-ui-react';
import { getTaxonomy, upsertTaxonomyItem, deleteTaxonomyItem } from '../../api/admin/taxonomy';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import ModalConfirm from '../../components/ModalConfirm';
import moment from 'moment';

const TAXONOMY_TYPES = {
    feature_client: 'Features (client-facing)',
    feature_player: 'Features (player-facing)',
};

const TOP_LEVEL = {
    uuid: 'top_level',
    id: null,
};

interface Props {
    history: any;
    navigate: any;
    location: any;
    params: any;
}
class Taxonomy extends Component<Props> {
    state = {
        isLoading: false,
        isAdding: false,
        isDeleting: false,
        isMoving: false,
        isLinking: false,
        isEditing: false,

        taxonomyType: 'feature_client',
        taxonomy: [],
        taxonomyAll: {},

        uuidToDelete: undefined,
        modalConfirm: false,

        modalMove: false,
        itemToMove: undefined,
        moveToItem: undefined,

        modalLink: false,
        itemToLink: undefined,
        uuidsToLink: [],

        modalEdit: false,
        itemToEdit: undefined,
        editName: '',

        opened: [],
        newItemName: '',
        errMessage: '',
    };

    async componentDidMount() {
        await this.loadTaxonomy(this.state.taxonomyType, true);
    }

    handleCloseModalConfirm = () => {
        this.setState({ modalConfirm: false, uuidToDelete: undefined });
    };

    handleConfirmedDelete = async () => {
        const { uuidToDelete } = this.state;
        if (!uuidToDelete) {
            return;
        }

        this.setState({ isDeleting: true });
        this.handleCloseModalConfirm();

        try {
            await deleteTaxonomyItem(uuidToDelete);
            await this.loadTaxonomy(this.state.taxonomyType, false);
            this.setState({ errMessage: '', isDeleting: false });
        } catch (e) {
            this.setState({ errMessage: 'Unable to delete item', isDeleting: false });
        }
    };

    async loadTaxonomy(type: string, resetState: boolean) {
        this.setState({ isLoading: true, taxonomyAll: {}, taxonomy: [], taxonomyType: type });
        if (resetState) {
            this.setState({ opened: [] });
        }

        try {
            const taxonomyAll = {};
            for (const type of Object.keys(TAXONOMY_TYPES)) {
                const res = await getTaxonomy(`type=${type}`);
                taxonomyAll[type] = res.items || [];
            }

            this.setState({
                taxonomyAll,
                taxonomy: taxonomyAll[type],
                isLoading: false,
                errMessage: '',
            });
        } catch (e) {
            this.setState({ errMessage: 'Unable to load taxonomy', isLoading: false });
        }
    }

    getFlattenedTaxonomy(taxonomy) {
        const result = [];

        taxonomy.forEach((item) => {
            result.push(item);
            if (item.children) {
                result.push(...this.getFlattenedTaxonomy(item.children));
            }
        });

        return result;
    }

    handleAccordionClick = (e, titleProps) => {
        const { index } = titleProps;
        const { opened } = this.state;
        const newOpened = [...opened];
        if (newOpened.includes(index)) {
            newOpened.splice(newOpened.indexOf(index), 1);
        } else {
            newOpened.push(index);
        }

        this.setState({ opened: newOpened, newItemName: '' });
    };

    renderTaxonomy() {
        const { taxonomy } = this.state;
        const flattenedTaxonomy = this.getFlattenedTaxonomy(taxonomy);

        return (
            <div>
                <div>
                    <Button
                        icon
                        labelPosition="left"
                        onClick={() => {
                            this.setState({
                                opened: flattenedTaxonomy.map((item) => item.uuid),
                            });
                        }}
                    >
                        <Icon name="expand" />
                        Expand All
                    </Button>
                    <Button
                        icon
                        labelPosition="left"
                        onClick={() => {
                            this.setState({ opened: [] });
                        }}
                    >
                        <Icon name="compress" />
                        Collapse All
                    </Button>
                </div>
                <br />

                <Accordion fluid styled>
                    {this.renderLevelItems(taxonomy, '', null)}
                </Accordion>
            </div>
        );
    }

    renderLevelItems(items, parentUUID: string, parentID: number) {
        const { opened, newItemName, isAdding, isDeleting } = this.state;

        const list = items.map((item) => {
            return (
                <React.Fragment key={item.uuid}>
                    <AccordionTitle
                        active={opened.includes(item.uuid)}
                        index={item.uuid}
                        onClick={this.handleAccordionClick}
                        className="taxonomy-item-title"
                    >
                        <Icon name={`angle ${opened.includes(item.uuid) ? 'down' : 'right'}`} />{' '}
                        {item.name}
                    </AccordionTitle>
                    <AccordionContent
                        active={opened.includes(item.uuid)}
                        className="taxonomy-item-content"
                    >
                        <div className="taxonomy-item-actions">
                            <Label>
                                <Icon name="clock" />
                                {moment(item.updated_at).format('YYYY-MM-DD')}
                            </Label>{' '}
                            <Button
                                icon
                                inverted
                                size="tiny"
                                color="olive"
                                labelPosition="left"
                                onClick={() => {
                                    this.setState({
                                        itemToEdit: item,
                                        modalEdit: true,
                                        editName: item.name,
                                    });
                                }}
                            >
                                <Icon name="edit outline" />
                                RENAME
                            </Button>{' '}
                            <Button
                                icon
                                inverted
                                size="tiny"
                                color="purple"
                                labelPosition="left"
                                onClick={() => {
                                    this.setState({
                                        itemToMove: item,
                                        modalMove: true,
                                    });
                                }}
                            >
                                <Icon name="resize vertical" />
                                MOVE
                            </Button>{' '}
                            <Button
                                icon
                                inverted
                                size="tiny"
                                color="blue"
                                labelPosition="left"
                                onClick={() => {
                                    this.setState({
                                        itemToLink: item,
                                        uuidsToLink: item.links || [],
                                        modalLink: true,
                                    });
                                }}
                            >
                                <Icon name="linkify" />
                                LINK
                            </Button>{' '}
                            <Button
                                icon
                                inverted
                                size="tiny"
                                color="red"
                                labelPosition="left"
                                disabled={isDeleting}
                                onClick={() => {
                                    this.setState({
                                        uuidToDelete: item.uuid,
                                        modalConfirm: true,
                                    });
                                }}
                            >
                                <Icon name="trash" />
                                {isDeleting ? '...' : 'DELETE'}
                            </Button>
                        </div>
                        <AccordionAccordion>
                            {this.renderLevelItems(item.children, item.uuid, item.id)}
                        </AccordionAccordion>
                    </AccordionContent>
                </React.Fragment>
            );
        });

        const addNewIndex = `${parentUUID}_add_new`;
        return (
            <div>
                {list}
                <AccordionTitle
                    active={opened.includes(addNewIndex)}
                    index={addNewIndex}
                    onClick={this.handleAccordionClick}
                >
                    <Icon name="plus square outline" /> Add New
                </AccordionTitle>
                <AccordionContent active={opened.includes(addNewIndex)}>
                    <Input
                        placeholder="Name"
                        action={{
                            disabled: isAdding,
                            color: 'teal',
                            content: isAdding ? '...' : 'Add',
                            onClick: async () => this.addNewItem(parentID, newItemName),
                        }}
                        onChange={(e, { value }) => this.setState({ newItemName: value })}
                    />
                </AccordionContent>
            </div>
        );
    }

    renderTaxonomyMoveList() {
        const { taxonomy, moveToItem } = this.state;

        return (
            <div>
                <List>
                    <ListItem>
                        <Button
                            icon
                            labelPosition="right"
                            size="mini"
                            color={
                                moveToItem && moveToItem.uuid === TOP_LEVEL.uuid
                                    ? 'purple'
                                    : 'black'
                            }
                            onClick={() => {
                                this.setState({ moveToItem: TOP_LEVEL });
                            }}
                        >
                            Top Level
                            <Icon name="right arrow" />
                        </Button>
                        <ListList>{this.renderTaxonomyMoveListItems(taxonomy)}</ListList>
                    </ListItem>
                </List>
            </div>
        );
    }

    renderTaxonomyMoveListItems(items) {
        const { itemToMove, moveToItem } = this.state;

        return items.map((item) => {
            return (
                <ListItem key={item.uuid}>
                    <Button
                        icon
                        labelPosition="right"
                        size="mini"
                        disabled={itemToMove && itemToMove.uuid === item.uuid}
                        color={moveToItem && moveToItem.uuid === item.uuid ? 'purple' : 'black'}
                        onClick={() => {
                            this.setState({ moveToItem: item });
                        }}
                    >
                        {item.name}
                        <Icon name="right arrow" />
                    </Button>
                    {item.children.length > 0 && (
                        <ListList>{this.renderTaxonomyMoveListItems(item.children)}</ListList>
                    )}
                </ListItem>
            );
        });
    }

    renderTaxonomyLinkList() {
        const { taxonomyAll } = this.state;

        return (
            <div>
                <List>
                    {Object.keys(taxonomyAll).map((type) => {
                        return (
                            <ListItem key={type}>
                                <Button disabled size="mini" color="teal">
                                    {TAXONOMY_TYPES[type]}
                                </Button>
                                <ListList>
                                    {this.renderTaxonomyLinkListItems(taxonomyAll[type])}
                                </ListList>
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        );
    }

    renderTaxonomyLinkListItems(items) {
        const { itemToLink, uuidsToLink } = this.state;

        return items.map((item) => {
            return (
                <ListItem key={item.uuid}>
                    <Button
                        icon
                        labelPosition="left"
                        size="mini"
                        disabled={itemToLink && itemToLink.uuid === item.uuid}
                        color={uuidsToLink.includes(item.uuid) ? 'purple' : 'black'}
                        onClick={() => {
                            const newUuidsToLink = [...uuidsToLink];
                            if (newUuidsToLink.includes(item.uuid)) {
                                newUuidsToLink.splice(newUuidsToLink.indexOf(item.uuid), 1);
                            } else {
                                newUuidsToLink.push(item.uuid);
                            }
                            this.setState({ uuidsToLink: newUuidsToLink });
                        }}
                    >
                        {item.name}
                        <Icon name="check square outline" />
                    </Button>
                    {item.children.length > 0 && (
                        <ListList>{this.renderTaxonomyLinkListItems(item.children)}</ListList>
                    )}
                </ListItem>
            );
        });
    }

    async addNewItem(parentID: number, name: string) {
        if (!name) {
            return;
        }

        this.setState({ errMessage: '', isAdding: true });

        try {
            await upsertTaxonomyItem({
                type: this.state.taxonomyType,
                parent_id: parentID,
                name,
            });

            this.loadTaxonomy(this.state.taxonomyType, false);
            this.setState({ isAdding: false, newItemName: '' });
        } catch (e) {
            this.setState({ errMessage: 'Unable to add a new item', isAdding: false });
        }
    }

    async renameItem() {
        const { itemToEdit, editName } = this.state;
        if (!itemToEdit || !editName) {
            return;
        }

        this.setState({ errMessage: '', isEditing: true });

        try {
            itemToEdit.name = editName;
            await upsertTaxonomyItem(itemToEdit);

            this.loadTaxonomy(this.state.taxonomyType, false);
            this.setState({
                isEditing: false,
                modalEdit: false,
                itemToEdit: undefined,
                editName: '',
            });
        } catch (e) {
            this.setState({
                errMessage: 'Unable to rename an item',
                modalEdit: false,
                itemToEdit: undefined,
                isEditing: false,
            });
        }
    }

    async moveItem() {
        const { itemToMove, moveToItem } = this.state;
        if (!itemToMove || !moveToItem) {
            return;
        }

        this.setState({ errMessage: '', isMoving: true });

        try {
            itemToMove.parent_id = moveToItem.id;
            await upsertTaxonomyItem(itemToMove);

            this.loadTaxonomy(this.state.taxonomyType, false);
            this.setState({
                isMoving: false,
                itemToMove: undefined,
                moveToItem: undefined,
                modalMove: false,
            });
        } catch (e) {
            this.setState({
                errMessage: 'Unable to move an item',
                itemToMove: undefined,
                moveToItem: undefined,
                isMoving: false,
                modalMove: false,
            });
        }
    }

    async linkItem() {
        const { itemToLink, uuidsToLink } = this.state;
        if (!itemToLink) {
            return;
        }

        this.setState({ errMessage: '', isLinking: true });

        try {
            itemToLink.links = uuidsToLink;
            await upsertTaxonomyItem(itemToLink);

            this.loadTaxonomy(this.state.taxonomyType, false);
            this.setState({
                isLinking: false,
                itemToLink: undefined,
                uuidsToLink: [],
                modalLink: false,
            });
        } catch (e) {
            this.setState({
                errMessage: 'Unable to link an item',
                itemToLink: undefined,
                uuidsToLink: [],
                isLinking: false,
                modalLink: false,
            });
        }
    }

    render() {
        const {
            errMessage,
            itemToMove,
            itemToLink,
            moveToItem,
            isMoving,
            isLinking,
            itemToEdit,
            isEditing,
        } = this.state;

        return (
            <Container>
                <LinkNavbar title="Taxonomy" />
                {this.state.isLoading && (
                    <Dimmer active inverted>
                        <Loader size="large">Loading</Loader>
                    </Dimmer>
                )}
                {errMessage && <Message negative>{errMessage}</Message>}
                <div>
                    {Object.keys(TAXONOMY_TYPES).map((type) => (
                        <Button
                            key={type}
                            onClick={() => {
                                this.loadTaxonomy(type, true);
                            }}
                            active={this.state.taxonomyType === type}
                        >
                            {TAXONOMY_TYPES[type]}
                        </Button>
                    ))}
                </div>
                <br />
                {!this.state.isLoading && this.renderTaxonomy()}
                {this.state.modalConfirm && (
                    <ModalConfirm
                        onCancel={this.handleCloseModalConfirm}
                        onDelete={this.handleConfirmedDelete}
                        onClose={this.handleCloseModalConfirm}
                        message={
                            <Message
                                info
                                header="Are you sure?"
                                content="All children will be deleted as well."
                            />
                        }
                    ></ModalConfirm>
                )}
                <Modal
                    open={this.state.modalMove}
                    closeIcon
                    onClose={() =>
                        this.setState({
                            modalMove: false,
                            itemToMove: undefined,
                            moveToItem: undefined,
                        })
                    }
                >
                    <ModalHeader>
                        Select where to move the item {'"'}
                        {itemToMove && itemToMove.name}
                        {'"'}:
                    </ModalHeader>
                    <ModalContent scrolling>{this.renderTaxonomyMoveList()}</ModalContent>
                    <ModalActions>
                        <Button
                            color="black"
                            onClick={() =>
                                this.setState({
                                    modalMove: false,
                                    itemToMove: undefined,
                                    moveToItem: undefined,
                                })
                            }
                        >
                            CANCEL
                        </Button>
                        <Button
                            content={isMoving ? '...' : 'MOVE'}
                            color="purple"
                            labelPosition="right"
                            icon="resize vertical"
                            disabled={!moveToItem || !itemToMove || isMoving}
                            onClick={async () => this.moveItem()}
                        />
                    </ModalActions>
                </Modal>
                <Modal
                    open={this.state.modalLink}
                    closeIcon
                    onClose={() =>
                        this.setState({
                            modalLink: false,
                            itemToLink: undefined,
                            uuidsToLink: [],
                        })
                    }
                >
                    <ModalHeader>
                        Select which taxonomy items to link with {'"'}
                        {itemToLink && itemToLink.name}
                        {'"'}:
                    </ModalHeader>
                    <ModalContent scrolling>{this.renderTaxonomyLinkList()}</ModalContent>
                    <ModalActions>
                        <Button
                            color="black"
                            onClick={() =>
                                this.setState({
                                    modalLink: false,
                                    itemToLink: undefined,
                                    uuidsToLink: [],
                                })
                            }
                        >
                            CANCEL
                        </Button>
                        <Button
                            content={isLinking ? '...' : 'LINK'}
                            color="blue"
                            labelPosition="right"
                            icon="resize vertical"
                            disabled={!itemToLink || isLinking}
                            onClick={async () => this.linkItem()}
                        />
                    </ModalActions>
                </Modal>
                <Modal
                    open={this.state.modalEdit}
                    closeIcon
                    onClose={() =>
                        this.setState({
                            modalEdit: false,
                            itemToEdit: undefined,
                            editName: '',
                        })
                    }
                >
                    <ModalHeader>Rename taxonomy item</ModalHeader>
                    <ModalContent>
                        <Input
                            fluid
                            placeholder="Name"
                            value={this.state.editName}
                            onChange={(e, { value }) => this.setState({ editName: value })}
                        />
                    </ModalContent>
                    <ModalActions>
                        <Button
                            color="black"
                            onClick={() =>
                                this.setState({
                                    modalEdit: false,
                                    itemToEdit: undefined,
                                    editName: '',
                                })
                            }
                        >
                            CANCEL
                        </Button>
                        <Button
                            content={isEditing ? '...' : 'RENAME'}
                            color="olive"
                            labelPosition="right"
                            icon="edit outline"
                            disabled={!itemToEdit || isEditing}
                            onClick={async () => this.renameItem()}
                        />
                    </ModalActions>
                </Modal>
            </Container>
        );
    }
}

Taxonomy.propTypes = {};

export default withRouter(Taxonomy);
