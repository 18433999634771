import {
    createAdminPostEndpoint,
    createAdminGetEndpoint,
    createAdminDeleteEndpoint,
} from './index';

export const upsertTaxonomyItem = createAdminPostEndpoint('taxonomy');

export const getTaxonomy = async (filtersStr: string) => {
    const res = await createAdminGetEndpoint(`taxonomy?${filtersStr}`)();
    const metadata = res.data || {};

    return metadata.data || { games: [], total_count: 0 };
};

export const deleteTaxonomyItem = async (id) => {
    const res = await createAdminDeleteEndpoint(`taxonomy/${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};
