import {
    createAdminPostEndpoint,
    createAdminGetEndpoint,
    createAdminDeleteEndpoint,
    createAdminPatchEndpoint,
} from './index';

export const createUser = createAdminPostEndpoint('users');

export const editUser = async (id, req) => {
    const res = await createAdminPatchEndpoint(`users/${id}`)(req);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const getAllUsers = async (filtersStr) => {
    const res = await createAdminGetEndpoint(`users?${filtersStr}`)();
    const metadata = res.data || {};

    return metadata.data || { users: [], total_count: 0 };
};

export const getAllAdminUsers = async () => {
    const res = await createAdminGetEndpoint('users/admin')();
    const metadata = res.data;

    return metadata.data || [];
};

export const deleteUser = async (id) => {
    const res = await createAdminDeleteEndpoint(`users/${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const deleteAdminUser = async (id) => {
    const res = await createAdminDeleteEndpoint(`users/admin/${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const updateAdminUser = async (id, payload) => {
    const res = await createAdminPatchEndpoint(`users/admin/${id}`)(payload);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};
