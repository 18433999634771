import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import {
    Table,
    Button,
    Dropdown,
    Pagination,
    Form,
    Header,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalContent,
    ModalActions,
    Popup,
    Statistic,
} from 'semantic-ui-react';
import { getAllGamesFiltered, deleteGame, getGameStats } from '../../api/admin/games';
import { getDashboardConfigFlags, getConfigNestedOptions } from '../../api/admin/config';

import { getAllCompanies } from '../../api/admin/companies';
import { getResponses, deleteResponses } from '../../api/survey-api/endpoints';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import GameRegistrationFormContainer from './gameRegistrationFormContainer';
import ModalConfirm from '../../components/ModalConfirm';

class Games extends Component {
    state = {
        games: [],
        gameStats: undefined,
        companies: [],
        limit: 100,
        offset: 0,
        companyID: '',
        query: '',
        genresOptions: [],
        configFlags: [],
        defaultConfigFlags: undefined,
        activePage: 1,
        totalPages: 1,
        idToDelete: null,
        isConfirmModalShown: false,
        currentGame: undefined,
        modalOpen: false,
        dataReady: false,
    };

    async componentDidMount() {
        const companies = (await getAllCompanies()) || [];
        const games = await getAllGamesFiltered(this.filterStr());
        let genresOptions = [];

        const config = await getConfigNestedOptions();
        genresOptions = this.getGenresOptions('', config.genres_taxonomy);

        const configFlags = (await getDashboardConfigFlags()) || {
            flags: [],
            default: {},
        };

        this.setState((prevState) => ({
            companies,
            games: games.games,
            totalPages: Math.ceil(games.total_count / prevState.limit),
            genresOptions,
            configFlags: configFlags.flags.reverse(),
            defaultConfigFlags: configFlags.default,
            dataReady: true,
        }));
    }

    handleDashboardConfigChange = async (e, { name, value }) => {
        if (name === 'assessment_type') {
            const configFlags = await getDashboardConfigFlags(value);

            this.setState({
                configFlags: configFlags.flags.reverse(),
            });
        }
    };

    getGenresOptions(prefix, genres) {
        const list = [];
        genres.forEach((g) => {
            const tmpG = g;

            tmpG.text = prefix + tmpG.name;
            list.push(tmpG);
            if (tmpG.children && tmpG.children.length) {
                const childrenOptions = this.getGenresOptions(
                    `${prefix + tmpG.name} | `,
                    tmpG.children
                );
                list.push(...childrenOptions);
            }
        });

        return list;
    }

    filterStr = () => {
        const params = [];
        params.push(`limit=${this.state.limit}`);
        params.push(`offset=${this.state.offset}`);
        params.push(`company_id=${this.state.companyID}`);
        params.push(`query=${this.state.query}`);

        return params.join('&');
    };

    handlePaginationChange = (e, { activePage }) => {
        this.setState(
            (prevState) => ({
                activePage,
                offset: (activePage - 1) * prevState.limit,
            }),
            async () => {
                const games = await getAllGamesFiltered(this.filterStr());
                this.setState((prevState) => ({
                    games: games.games,
                    totalPages: Math.ceil(games.total_count / prevState.limit),
                }));
            }
        );
    };

    handleFilterChange = async (field, value) => {
        this.setState({ [field]: value });
    };

    filterGames = async () => {
        this.setState(
            {
                offset: 0,
                activePage: 1,
            },
            async () => {
                const games = await getAllGamesFiltered(this.filterStr());
                this.setState((prevState) => ({
                    games: games.games,
                    totalPages: Math.ceil(games.total_count / prevState.limit),
                }));
            }
        );
    };

    addGame = (game) =>
        this.setState((prevProps) => {
            const previousGames = prevProps.games || [];

            return {
                games: [...previousGames, game],
                modalOpen: false,
            };
        });

    editGame = (game) =>
        this.setState((prevProps) => {
            const previousGames = prevProps.games || [];
            previousGames.forEach((g, index) => {
                if (g.id === game.id) {
                    previousGames[index] = { ...previousGames[index], ...game };
                }
            });

            return { games: previousGames, currentGame: undefined, modalOpen: false };
        });

    deleteGameFromTheList = (id) =>
        this.setState((prevProps) => {
            const newGames = prevProps.games.filter((game) => game.id !== id);

            return { games: newGames };
        });

    deleteGame = async (id) => {
        try {
            await deleteGame(id);
            this.deleteGameFromTheList(id);
        } catch (e) {
            console.log(e);
        }
    };

    handleCloseModalConfirm = () => {
        this.setState({ isConfirmModalShown: false });
    };

    handleConfirmedDelete = () => {
        this.deleteGame(this.state.idToDelete);
        this.handleCloseModalConfirm();
    };

    renderBody() {
        const { companies, games } = this.state;
        const gamesList = games || [];
        const companiesList = companies || [];

        return gamesList.map((gameParam) => {
            const game = gameParam;

            const gameCompany =
                companiesList.filter((company) => company.id === game.company)[0] || {};
            const gameURL = `https://${gameCompany.subdomain}.solsten.io/${game.slug}`;
            const alchemerURL = `https://app.alchemer.com/builder/build/id/${game.survey_id}`;
            if (game.genres && game.genres.length) {
                game.genre_id = game.genres[0].id;
            }

            let surveyActionsDropdown = null;
            if (game.survey_id) {
                surveyActionsDropdown = (
                    <Dropdown icon="setting" inline className="icon">
                        <DropdownMenu>
                            <DropdownItem
                                icon="cloud download"
                                text="Download raw files from Alchemer"
                                onClick={async () => {
                                    await getResponses(game.id);
                                }}
                            />
                            <DropdownItem
                                icon="trash alternate"
                                text="Delete responses from Alchemer"
                                onClick={async () => {
                                    await deleteResponses(game.id);
                                }}
                            />
                        </DropdownMenu>
                    </Dropdown>
                );
            }

            return (
                <Table.Row key={game.id}>
                    <Table.Cell key="name">
                        <Header as="h4">
                            <Header.Content>
                                <a href={gameURL} target="_blank" rel="noreferrer">
                                    {game.name}
                                </a>
                                <Header.Subheader>{game.id}</Header.Subheader>
                                <Header.Subheader>{game.storage_bucket_name}</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Table.Cell>
                    <Table.Cell key="company">{gameCompany.name || ''}</Table.Cell>
                    <Table.Cell key="data_type">{game.dashboard_config.namespace}</Table.Cell>
                    <Table.Cell key="assessment">
                        {game.dashboard_config.assessment_type}
                        {game.survey_id && (
                            <>
                                {' '}
                                |{' '}
                                <a href={alchemerURL} target="_blank" rel="noreferrer">
                                    {game.survey_id}
                                </a>
                            </>
                        )}
                        {surveyActionsDropdown}
                    </Table.Cell>
                    <Table.Cell key="actions">
                        <Popup
                            on="click"
                            onClose={() => this.setState({ gameStats: undefined })}
                            onOpen={async () => {
                                try {
                                    const gameStats = await getGameStats(game.id);
                                    this.setState({ gameStats });
                                } catch (e) {
                                    this.setState({ gameStats: undefined });
                                }
                            }}
                            trigger={
                                <Button
                                    size="mini"
                                    content="STATS"
                                    color="teal"
                                    labelPosition="right"
                                    icon="pie graph"
                                />
                            }
                            wide
                        >
                            {this.state.gameStats === undefined ? (
                                <>Loading...</>
                            ) : (
                                <>
                                    <Statistic
                                        size="mini"
                                        label="PLAYERS"
                                        value={this.state.gameStats.size || 0}
                                    />
                                    {this.state.gameStats.clusters
                                        .filter((clusterStats) => {
                                            return clusterStats.index >= 0;
                                        })
                                        .map((clusterStats) => {
                                            const clusterLabel = `Cluster ${
                                                clusterStats.index + 1
                                            }`;

                                            return (
                                                <Statistic
                                                    size="mini"
                                                    key={clusterStats.index}
                                                    label={clusterLabel}
                                                    value={clusterStats.size}
                                                />
                                            );
                                        })}
                                </>
                            )}
                        </Popup>{' '}
                        <Button
                            size="mini"
                            content="EDIT"
                            color="olive"
                            labelPosition="right"
                            icon="edit outline"
                            disabled={!this.state.dataReady}
                            onClick={() =>
                                this.setState({
                                    modalOpen: true,
                                    currentGame: game,
                                })
                            }
                        />{' '}
                        <Button
                            size="mini"
                            content="DEL"
                            color="red"
                            labelPosition="right"
                            icon="trash alternate outline"
                            onClick={() =>
                                this.setState({
                                    idToDelete: game.id,
                                    isConfirmModalShown: true,
                                })
                            }
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    renderTable() {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Company</Table.HeaderCell>
                        <Table.HeaderCell>Data Type</Table.HeaderCell>
                        <Table.HeaderCell>Assessment</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderBody()}</Table.Body>
            </Table>
        );
    }

    render() {
        const { activePage, totalPages, companyID, companies, query } = this.state;
        const companiesOptions = companies.map((c) => ({ value: c.id, text: c.name }));
        companiesOptions.unshift({ value: '', text: 'All Companies' });

        return (
            <Container>
                <LinkNavbar title="Dashboards" />
                <Modal
                    open={this.state.modalOpen}
                    closeIcon
                    onClose={() =>
                        this.setState({
                            modalOpen: false,
                            currentGame: undefined,
                        })
                    }
                >
                    <ModalHeader>
                        {this.state.currentGame ? 'Edit Dashboard' : 'Add Dashboard'}
                    </ModalHeader>
                    <ModalContent>
                        <GameRegistrationFormContainer
                            addGame={this.addGame}
                            companies={this.state.companies}
                            configFlags={this.state.configFlags}
                            defaultConfigFlags={this.state.defaultConfigFlags}
                            genresOptions={this.state.genresOptions}
                            handleDashboardConfigChange={this.handleDashboardConfigChange}
                            editGame={this.editGame}
                            game={this.state.currentGame}
                        />
                    </ModalContent>
                    <ModalActions>
                        <Button
                            color="black"
                            onClick={() =>
                                this.setState({
                                    modalOpen: false,
                                })
                            }
                        >
                            CANCEL
                        </Button>
                    </ModalActions>
                </Modal>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <Button
                                color="teal"
                                content="New Dashboard"
                                labelPosition="right"
                                icon="plus square outline"
                                disabled={!this.state.dataReady}
                                onClick={() =>
                                    this.setState({
                                        modalOpen: true,
                                    })
                                }
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Select
                            fluid
                            name="company"
                            onChange={(ev, option) =>
                                this.handleFilterChange('companyID', option.value)
                            }
                            options={companiesOptions}
                            placeholder="Filter by company"
                            search
                            selection
                            value={companyID}
                        />
                        <Form.Input
                            placeholder="Type dashboard name to search"
                            name="query"
                            onChange={(ev, option) =>
                                this.handleFilterChange('query', option.value)
                            }
                            value={query}
                        />
                        <Form.Field>
                            <Button
                                color="olive"
                                content="Filter"
                                labelPosition="right"
                                icon="search"
                                onClick={this.filterGames}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                {this.renderTable()}
                <br />
                <br />
                <Pagination
                    activePage={activePage}
                    boundaryRange={1}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    nextItem={null}
                    onPageChange={this.handlePaginationChange}
                    prevItem={null}
                    siblingRange={1}
                    size="mini"
                    totalPages={totalPages}
                />
                {this.state.isConfirmModalShown && (
                    <ModalConfirm
                        onCancel={this.handleCloseModalConfirm}
                        onDelete={this.handleConfirmedDelete}
                        onClose={this.handleCloseModalConfirm}
                    ></ModalConfirm>
                )}
            </Container>
        );
    }
}

export default withRouter(Games);
