import { createAdminGetEndpoint, createAdminPostEndpoint, createAdminPutEndpoint } from './index';

export const getAllCustomActions = async () => {
    const res = await createAdminGetEndpoint('actions/custom')();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const assignCustomActions = async (req) => {
    const res = await createAdminPostEndpoint('actions/custom')(req);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const getUnlockRequests = async () => {
    const res = await createAdminGetEndpoint('actions/unlock-requests')();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const unlockAction = async (id, gameID) => {
    const res = await createAdminPutEndpoint(`games/${gameID}/actions/${id}/unlock`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};
