import T from 'prop-types';
import { Modal, Header, Button } from 'semantic-ui-react';

const ModalComponent = ({ handleCloseModal, isOpen, isError, title, content }) => (
    <Modal basic onClose={handleCloseModal} open={isOpen}>
        <Header
            color={isError ? 'red' : 'green'}
            content={title}
            icon={isError ? 'warning' : 'bell'}
        />
        <Modal.Content>
            <h3>{content}</h3>
        </Modal.Content>
        <Modal.Actions>
            <Button
                size="mini"
                content="OK"
                color="teal"
                labelPosition="right"
                icon="check"
                onClick={handleCloseModal}
            />
        </Modal.Actions>
    </Modal>
);

ModalComponent.propTypes = {
    handleCloseModal: T.func.isRequired,
    isOpen: T.bool.isRequired,
    isError: T.bool.isRequired,
    title: T.string.isRequired,
    content: T.string.isRequired,
};

export default ModalComponent;
