import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import { Button, Header, Form, List, Checkbox, Image } from 'semantic-ui-react';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import { getCreative, updateCreative } from '../../api/freq-api/endpoints';
import { getDateOfRequest } from '../../utils/datetime';
import Modal from '../users/modal';

const STATUS_MAP = {
    0: 'Uploading',
    1: 'Processing',
    2: 'Ready',
};

interface Props {
    history: any;
    navigate: any;
    location: any;
    params: any;
}

class CreativeDetails extends Component<Props> {
    state = {
        id: undefined,
        data: undefined,
        modal: {
            title: '',
            content: '',
            isOpen: false,
            isError: true,
        },
    };

    async componentDidMount() {
        const { id } = this.props.params;

        try {
            const data = await getCreative(id);

            if (!data) {
                this.props.navigate('/creatives');

                return;
            }

            this.setState({ id, data });
        } catch (e) {
            this.setState({
                modal: {
                    title: 'Error',
                    content: 'unable to load creative',
                    isOpen: true,
                    isError: true,
                },
            });
        }
    }

    handleCloseModal = () => {
        this.setState((prevProps) => {
            const prevModal = prevProps.modal;
            prevModal.isOpen = false;

            return { modal: prevModal };
        });
    };

    handleOpenList = () => () => {
        this.props.navigate('/creatives');
    };

    saveCreative = async () => {
        try {
            await updateCreative(this.state.id, this.state.data);

            this.setState({
                modal: {
                    title: 'Success',
                    content: 'Creative has been successfully updated',
                    isOpen: true,
                    isError: false,
                },
            });
        } catch (e) {
            this.setState({
                modal: {
                    title: 'Error',
                    content: 'Unable to update creative',
                    isOpen: true,
                    isError: true,
                },
            });
        }
    };

    changeCreative = async (field, value) => {
        const { data } = this.state;
        data[field] = value;

        if (field === 'relevant_traits') {
            if (!data.relevant_traits_scores) {
                data.relevant_traits_scores = {};
            }

            value.forEach((traitID) => {
                if (!data.relevant_traits_scores[traitID]) {
                    data.relevant_traits_scores[traitID] = 'HIGH';
                }
            });

            // Delete unselected items
            Object.keys(data.relevant_traits_scores).forEach(function (traitID) {
                if (!value.includes(traitID)) {
                    delete data.relevant_traits_scores[traitID];
                }
            });
        }

        this.setState({
            data,
        });
    };

    flattenTree(prefix, node) {
        const list = [];
        node.forEach((item) => {
            list.push({ value: item.slug, text: prefix + item.name });
            if (item.children && item.children.length) {
                list.push(...this.flattenTree(`${prefix + item.name} | `, item.children));
            }
        });

        return list;
    }

    renderSummary() {
        const { data } = this.state;

        let assetElement = '';
        if (data.asset_type === 'url') {
            assetElement = <a href={data.asset_url}>{data.asset_url}</a>;
        } else if (data.asset.media_info && data.asset.media_info.type === 'image') {
            assetElement = <Image size="small" src={data.asset.thumbnail_url} />;
        } else if (data.asset.media_info && data.asset.media_info.type === 'video') {
            /* eslint-disable */
            assetElement = <video src={data.asset.url} controls width="512" />;
            /* eslint-enable */
        }

        let source = 'manual';
        if (
            data.ad_networks_info &&
            data.ad_networks_info.facebook &&
            data.ad_networks_info.facebook.length
        ) {
            source = `facebook ad: ${data.ad_networks_info.facebook[0].ad_creative_id}`;
        }
        if (
            data.ad_networks_info &&
            data.ad_networks_info.google &&
            data.ad_networks_info.google.length
        ) {
            source = `google ad: ${data.ad_networks_info.google[0].ad_creative_id}`;
        }
        if (
            data.ad_networks_info &&
            data.ad_networks_info.tiktok &&
            data.ad_networks_info.tiktok.length
        ) {
            source = `tiktok ad: ${data.ad_networks_info.tiktok[0].ad_creative_id}`;
        }
        if (
            data.ad_networks_info &&
            data.ad_networks_info.snapchat &&
            data.ad_networks_info.snapchat.length
        ) {
            source = `snapchat ad: ${data.ad_networks_info.snapchat[0].ad_creative_id}`;
        }

        return (
            <Form>
                <Form.Field width={6}>
                    <Header as="h5">Asset</Header>
                    {assetElement}
                </Form.Field>
                <Form.Field width={6}>
                    <Header as="h5">Uploaded at</Header>
                    {getDateOfRequest(data.uploaded_at)}
                    <Header as="h5">Uploaded by</Header>
                    {data.uploaded_by_details ? data.uploaded_by_details.name : '-'}
                    <Header as="h5">Company</Header>
                    {data.company_details ? data.company_details.name : '-'}
                    <Header as="h5">Status</Header>
                    {STATUS_MAP[data.status]}
                    <Header as="h5">Source</Header>
                    {source}
                </Form.Field>
                <Form.Field width={6}>
                    <Form.Input
                        defaultValue={data.nickname}
                        fluid
                        label="Nickname"
                        name="nickname"
                        onChange={(ev, option) => this.changeCreative('nickname', option.value)}
                    />
                </Form.Field>
                <Form.Field width={6}>
                    <Form.Select
                        defaultValue={data.relevant_traits}
                        label="Relevant Traits"
                        multiple
                        name="relevant_traits"
                        onChange={(ev, option) =>
                            this.changeCreative('relevant_traits', option.value)
                        }
                        options={[]}
                        search
                        selection
                    />
                </Form.Field>
                <List>
                    {Object.keys(data.relevant_traits_scores || {}).map((traitID) => {
                        return (
                            <List.Item key={traitID}>
                                <Checkbox
                                    defaultChecked={data.relevant_traits_scores[traitID] === 'HIGH'}
                                    label="HIGH"
                                    onChange={(ev, value) => {
                                        data.relevant_traits_scores[traitID] = value.checked
                                            ? 'HIGH'
                                            : 'LOW';
                                        this.setState({
                                            data,
                                        });
                                    }}
                                    toggle
                                />
                            </List.Item>
                        );
                    })}
                </List>
                <Button
                    size="mini"
                    content="SAVE"
                    color="teal"
                    labelPosition="right"
                    icon="save outline"
                    onClick={() => this.saveCreative()}
                />
            </Form>
        );
    }

    render() {
        const { data, modal } = this.state;

        if (modal.isOpen) {
            return (
                <Container>
                    <LinkNavbar title="Creative Details" />
                    {modal.isOpen && <Modal {...modal} handleCloseModal={this.handleCloseModal} />}
                </Container>
            );
        }

        if (data) {
            return (
                <Container>
                    <LinkNavbar title="Creative Details" />
                    <Button.Group>
                        <Button
                            size="mini"
                            content="Back to list"
                            color="teal"
                            labelPosition="right"
                            icon="arrow left"
                            onClick={this.handleOpenList()}
                            type="button"
                        />
                    </Button.Group>
                    <Header as="h1">Creative Details - {data._id}</Header>
                    {this.renderSummary()}
                    <br />
                    <br />
                </Container>
            );
        }

        return '';
    }
}

export default withRouter(CreativeDetails);
