export const WHITE = '#fff';
export const BLACK = '#000';

export const GRAY = '#C0C4BD';

export const BLUE_50 = '#223864';
export const BLUE_60 = '#1D3054';

export const PALETTE_10 = '#CBC2A2';
export const PALETTE_20 = '#D9A97C';
export const PALETTE_30 = '#5BBBCE';
export const PALETTE_40 = '#48AEB1';
export const PALETTE_50 = '#0CBC8F';
export const PALETTE_60 = '#E0963E';
export const PALETTE_70 = '#B0A374';

export const RED = '#FF005E';
export const ORANGE = '#FFA600';
export const GREEN = '#00FF66';

export const PALETTE = [
    PALETTE_10,
    PALETTE_20,
    PALETTE_30,
    PALETTE_40,
    PALETTE_50,
    PALETTE_60,
    PALETTE_70,
];

export const GRAPH_BACKGROUND = ['#FBFBF9', '#F3F3EA', '#EBEBDD'];
