import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { post } from '../admin';

import { createAdminPostEndpoint } from './index';

export const getJWT = () => {
    return window.localStorage.getItem('admin_ui_jwt');
};

export const setJWT = (jwt) => {
    window.localStorage.setItem('admin_ui_jwt', jwt);
};

export const getUserSession = () => {
    const jwt = getJWT();
    if (!jwt) {
        return undefined;
    }

    try {
        const data = jwtDecode(jwt);
        if (!data) {
            return undefined;
        }

        const now = moment().unix();
        if (data.exp && data.exp <= now) {
            setJWT('');

            return undefined;
        }

        return data;
    } catch (e) {
        setJWT('');
    }

    return undefined;
};

export const login = async (data) => {
    try {
        const res = await post('/v1/auth/login', { data });

        if (res.status === 200) {
            const metadata = res.data;
            const respData = metadata.data ? metadata.data : '';

            if (respData && respData.jwt) {
                setJWT(respData.jwt);
            }
        }

        return true;
    } catch (e) {
        setJWT('');
    }

    return false;
};

export const logout = async () => {
    setJWT('');
};

export const inviteUser = async (req) => {
    const res = await createAdminPostEndpoint(`users/admin`)(req);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};
