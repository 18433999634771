import {
    createAdminPostEndpoint,
    createAdminGetEndpoint,
    createAdminDeleteEndpoint,
    createAdminPutEndpoint,
} from './index';

export const createGame = createAdminPostEndpoint('games');

export const getAllGamesFiltered = async (filtersStr) => {
    const res = await createAdminGetEndpoint(`games?${filtersStr}`)();
    const metadata = res.data || {};

    return metadata.data || { games: [], total_count: 0 };
};

export const deleteGame = async (id) => {
    const res = await createAdminDeleteEndpoint(`games/${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const updateGame = async (id, req) => {
    const res = await createAdminPutEndpoint(`games/${id}`)(req);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const getGameStats = async (id) => {
    const res = await createAdminGetEndpoint(`game-stats?game_id=${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data.stats;
};
