import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import { Button, Table } from 'semantic-ui-react';
import {
    getAllAssessmentCreatives,
    deleteAssessmentCreativesConfig,
} from '../../api/survey-api/endpoints';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import AssessmentCreativesContainer from './creativesContainer';
import ModalConfirm from '../../components/ModalConfirm';

class AssessmentCreatives extends Component {
    state = {
        configs: [],
        isConfirmModalShown: false,
        temporaryDeletingConfig: null,
    };

    async componentDidMount() {
        const configs = (await getAllAssessmentCreatives()) || [];

        this.setState({ configs });
    }

    handleCloseModalConfirm = () => {
        this.setState({ isConfirmModalShown: false });
    };

    handleConfirmedDelete = async () => {
        await deleteAssessmentCreativesConfig(this.state.temporaryDeletingConfig);
        this.props.navigate('');
        this.handleCloseModalConfirm();
    };

    renderBody() {
        const { configs } = this.state;

        return configs.map((row) => {
            return (
                <Table.Row key={row.assessment_id}>
                    <Table.Cell key="1" singleLine>
                        <span>{row.assessment_id}</span>
                    </Table.Cell>
                    <Table.Cell key="2" singleLine>
                        <span>{row.bucket}</span>
                    </Table.Cell>
                    <Table.Cell key="3" singleLine>
                        <span>{row.tuples_prefix}</span>
                    </Table.Cell>
                    <Table.Cell key="4" singleLine>
                        <span>{row.limit}</span>
                    </Table.Cell>
                    <Table.Cell key="5" singleLine>
                        <span>{row.split_by_type ? 'Yes' : 'No'}</span>
                    </Table.Cell>
                    <Table.Cell key="6" singleLine>
                        <span>{row.types.join(', ')}</span>
                    </Table.Cell>
                    <Table.Cell key="7" singleLine>
                        <span>{row.add_last_copy_of_first ? 'Yes' : 'No'}</span>
                    </Table.Cell>
                    <Table.Cell key="8" singleLine>
                        <span>{row.last_copy_types.join(', ')}</span>
                    </Table.Cell>
                    <Table.Cell key="9" singleLine>
                        <Button
                            size="mini"
                            content="DELETE"
                            color="red"
                            labelPosition="right"
                            icon="trash alternate"
                            onClick={async () => {
                                this.setState({
                                    isConfirmModalShown: true,
                                    temporaryDeletingConfig: row?.assessment_id,
                                });
                            }}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    renderTable() {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Survey ID</Table.HeaderCell>
                        <Table.HeaderCell>Bucket</Table.HeaderCell>
                        <Table.HeaderCell>Tuples Prefix</Table.HeaderCell>
                        <Table.HeaderCell>Limit</Table.HeaderCell>
                        <Table.HeaderCell>Split By Type</Table.HeaderCell>
                        <Table.HeaderCell>Types</Table.HeaderCell>
                        <Table.HeaderCell>Last Copy</Table.HeaderCell>
                        <Table.HeaderCell>Last Copy Types</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderBody()}</Table.Body>
            </Table>
        );
    }

    render() {
        return (
            <Container>
                <LinkNavbar title="Config for Assessment Creatives" />
                <AssessmentCreativesContainer />
                {this.renderTable()}
                {this.state.isConfirmModalShown && (
                    <ModalConfirm
                        onCancel={this.handleCloseModalConfirm}
                        onDelete={this.handleConfirmedDelete}
                        onClose={this.handleCloseModalConfirm}
                    ></ModalConfirm>
                )}
            </Container>
        );
    }
}

AssessmentCreatives.propTypes = {};

export default withRouter(AssessmentCreatives);
