import { createAdminGetEndpoint } from './index';

const getAdminEndpointResponse = async (path) => {
    const res = await createAdminGetEndpoint(path)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const getUsersEngagement = async (filter) =>
    getAdminEndpointResponse(`users/engagement${filter}`);
export const getActionsObjectives = async (filter) =>
    getAdminEndpointResponse(`actions/analytics/objectives${filter}`);
export const getSkippedActions = async (filter) =>
    getAdminEndpointResponse(`actions/analytics/skipped${filter}`);
export const getActionsRatings = async (filter) =>
    getAdminEndpointResponse(`actions/analytics/ratings${filter}`);
export const getActionsTTM = async (filter) =>
    getAdminEndpointResponse(`actions/analytics/ttm${filter}`);
export const getActionsKPIs = async (filter) =>
    getAdminEndpointResponse(`actions/analytics/kpis${filter}`);
export const getGlobalStats = async () => getAdminEndpointResponse(`stats`);
export const getPaymentAnalytics = async (filter) =>
    getAdminEndpointResponse(`payments/analytics${filter}`);
