import { Component } from 'react';
import { Table, Header, Button } from 'semantic-ui-react';
import withRouter from '@/hocs/withRouter';
import { getFrequencyReviews, deleteFrequencyReview } from '../../api/freq-api/endpoints';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import { getDateOfRequest } from '../../utils/datetime';
import ModalConfirm from '../../components/ModalConfirm';

const STATUS_MAP = {
    0: 'Draft',
    1: 'Pending',
    2: 'In Progress',
    3: 'Cancelled',
    4: 'Completed',
};

class CreativeReviewV2 extends Component {
    state = {
        reviews: [],
        temporaryDeletingCreativeReview: null,
        isConfirmModalShown: false,
    };

    async componentDidMount() {
        const reviews = await getFrequencyReviews();

        this.setState({ reviews });
    }

    deleteFrequencyReviewFromTheList = async (id) => {
        await deleteFrequencyReview(id);

        const reviews = await getFrequencyReviews();

        this.setState({ reviews });
    };

    handleOpenCreativeReview = (id) => () => {
        this.props.navigate(`/frequency-reviews/${id}`);
    };

    handleCloseModalConfirm = () => {
        this.setState({ isConfirmModalShown: false });
    };
    handleConfirmedDelete = () => {
        this.deleteFrequencyReviewFromTheList(this.state.temporaryDeletingCreativeReview);
        this.handleCloseModalConfirm();
    };

    renderBody() {
        const { reviews } = this.state;

        return reviews.map((row) => {
            return (
                <Table.Row key={row.id} onClick={this.handleOpenCreativeReview(row.id)}>
                    <Table.Cell key="1">
                        <span>{row.id}</span>
                    </Table.Cell>
                    <Table.Cell key="2">
                        <span>{row.company_details.name}</span>
                    </Table.Cell>
                    <Table.Cell key="3">
                        <span>{getDateOfRequest(row.created_at)}</span>
                    </Table.Cell>
                    <Table.Cell key="4">
                        <span>{STATUS_MAP[row.status]}</span>
                    </Table.Cell>
                    <Table.Cell key="5">
                        <Button
                            size="mini"
                            content="DELETE"
                            color="red"
                            labelPosition="right"
                            icon="trash alternate"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({
                                    isConfirmModalShown: true,
                                    temporaryDeletingCreativeReview: row.id,
                                });
                            }}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    renderTable() {
        return (
            <Table celled selectable sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Studio Name</Table.HeaderCell>
                        <Table.HeaderCell>Date of Request</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderBody()}</Table.Body>
            </Table>
        );
    }

    render() {
        return (
            <Container>
                <LinkNavbar title="Frequency Reviews" />
                <Header as="h1">Frequency Reviews //</Header>
                {this.renderTable()}
                {this.state.isConfirmModalShown && (
                    <ModalConfirm
                        onCancel={this.handleCloseModalConfirm}
                        onDelete={this.handleConfirmedDelete}
                        onClose={this.handleCloseModalConfirm}
                    ></ModalConfirm>
                )}
            </Container>
        );
    }
}

export default withRouter(CreativeReviewV2);
