import React from 'react';
import T from 'prop-types';
import { pick } from 'lodash';
import { Button, Form, FormGroup, Grid } from 'semantic-ui-react';
import CompanySelector from './companySelector';

const rolesOptions = [
    {
        key: 'superadmin',
        text: 'Super Admin (access to all companies)',
        value: 'superadmin',
    },
    { key: 'owner', text: 'Owner', value: 'owner' },
    { key: 'admin', text: 'Admin', value: 'admin' },
    { key: 'user', text: 'Member', value: 'user' },
    { key: 'preview', text: 'Preview user', value: 'preview' },
];

const expirationOptions = [
    { key: 'never', text: 'No expiration', value: null },
    { key: 'week1', text: '1 Week', value: 7 },
    { key: 'week2', text: '2 Weeks', value: 14 },
    { key: 'month1', text: '1 Month', value: 30 },
    { key: 'month2', text: '2 Months', value: 60 },
    { key: 'month3', text: '3 Months', value: 90 },
];

class UserRegistrationForm extends React.Component {
    state = {
        first_name: '',
        last_name: '',
        email: '',
        days_to_expire_user: null,
        password: '',
        companies: [],
        isAdding: false,
    };

    constructor(props) {
        super(props);
        const currentUser = props.currentUser;
        if (currentUser !== undefined) {
            // assign dashboard permissions to companies
            const companies = currentUser.companies.map((company) => {
                if (
                    ['user', 'preview'].includes(company.role) &&
                    company.company_id &&
                    currentUser.permissions &&
                    currentUser.permissions.dashboard_permissions.length > 0
                ) {
                    const dashboards = currentUser.permissions.dashboard_permissions
                        .filter((d) => d.company_id === company.company_id)
                        .map((d) => d.dashboard_id);

                    company.games = dashboards;
                }

                return company;
            });

            this.state = { ...this.state, ...currentUser, companies };
        } else {
            this.state = {
                ...this.state,
                companies: [{ company_id: '', role: '', games: [] }],
            };
        }
    }

    handleChange = (e, { name, value }) => {
        if (name === 'role' && value === 'superadmin') {
            this.setState({ days_to_expire_user: 7 });
        }
        this.setState({ [name]: value });
    };

    render() {
        const { first_name, last_name, email, password, companies } = this.state;
        const daysToExpire = this.state.days_to_expire_user;
        const { onSubmit, dashboards } = this.props;
        const allCompanies = this.props.companies;

        return (
            <Grid>
                <Grid.Column>
                    <Form
                        onSubmit={async () => {
                            this.setState({ isAdding: true });

                            await onSubmit(
                                pick(this.state, [
                                    'first_name',
                                    'last_name',
                                    'email',
                                    'pass',
                                    'companies',
                                    'days_to_expire_user',
                                    ...(this.state.role === 'superadmin' ? ['password'] : []),
                                ])
                            );

                            this.setState({ isAdding: false });
                        }}
                    >
                        <FormGroup widths="equal">
                            <Form.Field>
                                <Form.Input
                                    name="email"
                                    onChange={this.handleChange}
                                    placeholder="Email"
                                    value={email}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input
                                    name="first_name"
                                    onChange={this.handleChange}
                                    placeholder="First Name"
                                    value={first_name}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input
                                    name="last_name"
                                    onChange={this.handleChange}
                                    placeholder="Last Name"
                                    value={last_name}
                                />
                            </Form.Field>
                        </FormGroup>
                        {this.state.role === 'superadmin' && (
                            <Form.Field>
                                <Form.Input
                                    name="password"
                                    onChange={this.handleChange}
                                    placeholder="password"
                                    value={password}
                                />
                            </Form.Field>
                        )}
                        {companies.map((company, index) => {
                            let companyDashboards = [];
                            if (['user', 'preview'].includes(company.role) && company.company_id) {
                                companyDashboards = dashboards.filter(
                                    (d) => d.company === company.company_id
                                );
                            }

                            return (
                                <FormGroup widths="equal" key={index}>
                                    <CompanySelector
                                        companies={allCompanies}
                                        value={company.company_id}
                                        onChangeCompany={(value) =>
                                            this.setState((prevState) => {
                                                const { companies } = prevState;
                                                companies[index]['company_id'] = value;
                                                companies[index]['role'] = '';
                                                companies[index]['games'] = [];

                                                return { companies };
                                            })
                                        }
                                    />
                                    <Form.Field>
                                        <Form.Select
                                            fluid
                                            label="Role *"
                                            name="role"
                                            onChange={(ev, data) =>
                                                this.setState((prevState) => {
                                                    const { companies } = prevState;
                                                    companies[index]['role'] = data.value;
                                                    companies[index]['games'] = [];

                                                    return { companies };
                                                })
                                            }
                                            options={rolesOptions}
                                            placeholder="Role"
                                            selection
                                            value={company.role}
                                        />
                                    </Form.Field>
                                    {companyDashboards.length > 0 && (
                                        <Form.Field>
                                            <Form.Select
                                                fluid
                                                label="Access to dashboards"
                                                multiple
                                                name="games"
                                                onChange={(ev, data) =>
                                                    this.setState((prevState) => {
                                                        const { companies } = prevState;
                                                        companies.splice(index, 1, {
                                                            ...companies[index],
                                                            ['games']: data.value,
                                                        });

                                                        return { companies };
                                                    })
                                                }
                                                options={companyDashboards.map((d) => ({
                                                    key: d.id,
                                                    text: d.name,
                                                    value: d.id,
                                                }))}
                                                value={company.games}
                                                selection
                                            />
                                        </Form.Field>
                                    )}
                                    <Form.Field>
                                        <Button
                                            size="mini"
                                            content="DEL"
                                            color="red"
                                            labelPosition="right"
                                            icon="trash alternate outline"
                                            type="button"
                                            style={{ marginTop: '26px' }}
                                            onClick={() =>
                                                this.setState((prevState) => ({
                                                    companies: prevState.companies.filter(
                                                        (_, i) => i !== index
                                                    ),
                                                }))
                                            }
                                        />
                                    </Form.Field>
                                </FormGroup>
                            );
                        })}
                        <Form.Field>
                            <Button
                                size="mini"
                                content="Add company"
                                color="olive"
                                labelPosition="right"
                                icon="plus square outline"
                                type="button"
                                onClick={() =>
                                    this.setState((prevState) => ({
                                        companies: [
                                            ...prevState.companies,
                                            {
                                                company_id: '',
                                                role: '',
                                                games: [],
                                            },
                                        ],
                                    }))
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Select
                                fluid
                                label="Expire (give temporary access)"
                                name="days_to_expire_user"
                                onChange={this.handleChange}
                                options={
                                    this.state.role === 'superadmin'
                                        ? [...expirationOptions].splice(1)
                                        : expirationOptions
                                }
                                placeholder="Expire (give temporary access)"
                                selection
                                value={daysToExpire}
                            />
                        </Form.Field>
                        <Button
                            content={this.state.isAdding ? 'Saving...' : 'Save'}
                            color="teal"
                            labelPosition="right"
                            icon="save outline"
                            disabled={this.state.isAdding}
                            type="submit"
                        />
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

UserRegistrationForm.propTypes = {
    companies: T.arrayOf(T.object),
    dashboards: T.arrayOf(T.object),
    onSubmit: T.func.isRequired,
    currentUser: T.object,
};

UserRegistrationForm.defaultProps = {
    companies: [],
    dashboards: [],
    currentUser: undefined,
};
export default UserRegistrationForm;
