import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import {
    Button,
    Header,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    Table,
} from 'semantic-ui-react';
import { getAllCompanies, deleteCompany, COMPANY_TYPES } from '../../api/admin/companies';
import { getAllAdminUsers } from '../../api/admin/users';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import CompanyRegistrationFormContainer from './companyRegistrationFormContainer';
import ModalConfirm from '../../components/ModalConfirm';

class Companies extends Component {
    state = {
        companies: [],
        currentCompany: undefined,
        adminUsers: [],
        isConfirmModalShown: false,
        idToDelete: null,
        modalOpen: false,
    };

    async componentDidMount() {
        const companies = await getAllCompanies();

        const adminUsers = await getAllAdminUsers();

        this.setState({ companies, adminUsers });
    }

    addCompany = (company) =>
        this.setState((prevProps) => {
            const previousCompanies = prevProps.companies || [];

            return {
                companies: [...previousCompanies, company],
                modalOpen: false,
                currentCompany: undefined,
            };
        });

    editCompany = (company) =>
        this.setState((prevProps) => {
            const previousCompanies = prevProps.companies || [];
            previousCompanies.forEach((c, index) => {
                if (c.id === company.id) {
                    previousCompanies[index] = {
                        ...previousCompanies[index],
                        ...company,
                    };
                }
            });

            return {
                companies: previousCompanies,
                modalOpen: false,
                currentCompany: undefined,
            };
        });

    deleteCompany = async (id) => {
        await deleteCompany(id);

        const companies = await getAllCompanies();

        this.setState({ companies });
    };

    handleCloseModalConfirm = () => {
        this.setState({ isConfirmModalShown: false });
    };

    handleConfirmedDelete = () => {
        this.deleteCompany(this.state.idToDelete);
        this.handleCloseModalConfirm();
    };

    renderBody() {
        const { companies } = this.state;

        const companiesList = companies || [];

        return companiesList.map((company) => {
            const companyLink = `https://${company.subdomain}.solsten.io`;

            return (
                <Table.Row key={company.id}>
                    <Table.Cell key="subdomain">
                        <Header as="h4">
                            <Header.Content>
                                <a href={companyLink}>{company.subdomain}.solsten.io</a>
                                <Header.Subheader>{company.id}</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Table.Cell>
                    <Table.Cell key="name">{company.name}</Table.Cell>
                    <Table.Cell key="type">{COMPANY_TYPES[company.company_type]}</Table.Cell>
                    <Table.Cell key="enable_navigator">
                        {company.enable_navigator ? 'Yes' : 'No'}
                    </Table.Cell>
                    <Table.Cell key="frequency_purchased">
                        {company.frequency_purchased ? 'Yes' : 'No'}
                    </Table.Cell>
                    <Table.Cell key="social_listening_enabled">
                        {company.social_listening_enabled ? 'Yes' : 'No'}
                    </Table.Cell>
                    <Table.Cell key="actions">
                        <Button
                            size="mini"
                            content="EDIT"
                            color="olive"
                            labelPosition="right"
                            icon="edit outline"
                            onClick={() =>
                                this.setState({
                                    currentCompany: company,
                                    modalOpen: true,
                                })
                            }
                        />{' '}
                        <Button
                            size="mini"
                            content="DELETE"
                            color="red"
                            labelPosition="right"
                            icon="trash alternate outline"
                            onClick={() =>
                                this.setState({
                                    isConfirmModalShown: true,
                                    idToDelete: company.id,
                                })
                            }
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    renderTable() {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Subdomain</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Navigator</Table.HeaderCell>
                        <Table.HeaderCell>Frequency</Table.HeaderCell>
                        <Table.HeaderCell>Social Listening</Table.HeaderCell>
                        <Table.HeaderCell>Impact Indicator</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderBody()}</Table.Body>
            </Table>
        );
    }

    render() {
        return (
            <Container>
                <LinkNavbar title="Companies" />
                <Modal
                    open={this.state.modalOpen}
                    closeIcon
                    onClose={() =>
                        this.setState({
                            modalOpen: false,
                        })
                    }
                >
                    <ModalHeader>
                        {this.state.currentCompany ? 'Edit Company' : 'Add Company'}
                    </ModalHeader>
                    <ModalContent>
                        <CompanyRegistrationFormContainer
                            addCompany={this.addCompany}
                            editCompany={this.editCompany}
                            adminUsers={this.state.adminUsers}
                            currentCompany={this.state.currentCompany}
                        />
                    </ModalContent>
                    <ModalActions>
                        <Button
                            color="black"
                            onClick={() =>
                                this.setState({
                                    modalOpen: false,
                                    currentCompany: undefined,
                                })
                            }
                        >
                            CANCEL
                        </Button>
                    </ModalActions>
                </Modal>
                <div>
                    <Button
                        color="teal"
                        content="Add Company"
                        labelPosition="right"
                        icon="plus square outline"
                        onClick={() =>
                            this.setState({
                                modalOpen: true,
                            })
                        }
                    />
                </div>
                {this.renderTable()}
                {this.state.isConfirmModalShown && (
                    <ModalConfirm
                        onCancel={this.handleCloseModalConfirm}
                        onDelete={this.handleConfirmedDelete}
                        onClose={this.handleCloseModalConfirm}
                    ></ModalConfirm>
                )}
            </Container>
        );
    }
}

Companies.propTypes = {};

export default withRouter(Companies);
