import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

import * as Colors from '../../attrs/colors';

export const Container = styled.div`
    background: ${Colors.WHITE};
    margin-bottom: 10px;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    border-bottom: 1px solid ${Colors.GRAY};
`;

export const Inner = styled.div`
    height: 80px;
    max-width: 1400px;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    position: relative;
`;

export const BrandLink = styled(Link)`
    position: absolute;
    top: 20px;
    left: 20px;
`;

export const NavbarLinks = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
`;

export const NavbarLink = styled(NavLink)`
    font-size: 2rem;
    padding: 0.25rem 0.5rem;
    color: ${Colors.BLUE_50};
    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
        color: ${Colors.BLUE_50};
        opacity: 0.6;
    }

    &.active {
        color: ${Colors.BLUE_50};
        opacity: 1;
    }

    .icon {
        width: auto;
    }
`;

export const NavbarIcon = styled.span`
    font-size: 2rem;
    padding: 0.25rem 0.5rem;
    color: ${Colors.BLUE_50};
    transition: opacity 0.2s;

    .icon {
        width: auto;
        opacity: 0.5;
    }
`;

export const NavbarTitle = styled.span`
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0.7rem 0.5rem;
    display: inline-block;
`;
