import React from 'react';
import T from 'prop-types';
import { createCompany, updateCompany } from '../../api/admin/companies';
import CompanyRegistrationForm from './companyRegistrationForm';

class CompanyRegistrationFormContainer extends React.Component {
    state = {
        title: '',
        content: '',
    };

    onSubmit = async (data) => {
        const req = data;
        req.max_allowed_frequency_reviews = parseInt(req.max_allowed_frequency_reviews, 10);

        try {
            if (req.id) {
                await updateCompany(req.id, req);
                this.props.editCompany(req);
            } else {
                req.id = undefined;
                const res = await createCompany(req);
                const metadata = res.data || {};
                req.id = metadata.data.company.id;
                this.props.addCompany(req);
            }

            return true;
        } catch (e) {
            return false;
        }
    };

    render() {
        return (
            <CompanyRegistrationForm
                {...this.state}
                currentCompany={this.props.currentCompany}
                adminUsers={this.props.adminUsers}
                handleCloseModal={this.handleCloseModal}
                onSubmit={this.onSubmit}
            />
        );
    }
}

CompanyRegistrationFormContainer.propTypes = {
    addCompany: T.func.isRequired,
    currentCompany: T.object,
    adminUsers: T.array,
    editCompany: T.any,
};

CompanyRegistrationFormContainer.defaultProps = {
    currentCompany: undefined,
    adminUsers: undefined,
    editCompany: undefined,
};

export default CompanyRegistrationFormContainer;
