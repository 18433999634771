import { Table, Dropdown, Image, Header, Button } from 'semantic-ui-react';
import T from 'prop-types';

export const GameRow = ({
    game,
    onTaggingChange,
    onDashboardChange,
    onGameNameChange,
    genresFlatMap,
    genresOptions,
    dashboards,
}) => {
    const { cover_url: coverURL, frequency, genres, name, slug } = game;

    return (
        <Table.Row verticalAlign="top">
            <Table.Cell>{coverURL ? <Image avatar size="mini" src={coverURL} /> : ''}</Table.Cell>
            <Table.Cell>
                <Header as="h4">
                    <Header.Content>
                        {name}
                        <Header.Subheader>Players: {frequency}</Header.Subheader>
                    </Header.Content>
                </Header>
                <Dropdown
                    defaultValue={game.dashboard_ids}
                    fluid
                    multiple
                    onChange={(_, option) => {
                        onDashboardChange(game.slug, game.name, coverURL, option.value);
                    }}
                    options={dashboards.map((dashboard) => {
                        return {
                            id: dashboard.id,
                            text: dashboard.name,
                            value: dashboard.id,
                        };
                    })}
                    search
                    selection
                />
            </Table.Cell>
            <Table.Cell>
                <Dropdown
                    fluid
                    multiple
                    onChange={(_, option) => {
                        onTaggingChange(genresFlatMap, option, slug, 'genres', 'genres_ids');
                    }}
                    options={genresOptions}
                    search
                    selection
                    value={genres.map(({ id }) => id)}
                />
            </Table.Cell>
            <Table.Cell>
                <Button
                    size="mini"
                    content="EDIT"
                    color="olive"
                    labelPosition="right"
                    icon="edit"
                    onClick={() => {
                        onGameNameChange(game.id, game.name, coverURL);
                    }}
                />
            </Table.Cell>
        </Table.Row>
    );
};

GameRow.propTypes = {
    dashboards: T.any,
    game: T.any,
    onTaggingChange: T.func,
    onDashboardChange: T.func,
    onGameNameChange: T.func,
    genresFlatMap: T.any,
    genresOptions: T.any,
};

GameRow.defaultProps = {
    dashboards: undefined,
    game: undefined,
    onTaggingChange: undefined,
    onDashboardChange: undefined,
    onGameNameChange: undefined,
    genresFlatMap: undefined,
    genresOptions: undefined,
};
