import { createAdminGetEndpoint, createAdminPutEndpoint, createAdminDeleteEndpoint } from './index';

export const getDeeplinks = async () => {
    const res = await createAdminGetEndpoint('/v1/deeplinks')();
    const metadata = res.data || {};
    const data = metadata.data || [];

    return data;
};

export const deleteDeeplink = async (id) => {
    const res = await createAdminDeleteEndpoint(`/v1/deeplinks/${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || [];

    return data;
};

export const registerDeeplink = createAdminPutEndpoint('/v1/deeplink');
