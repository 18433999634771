import React from 'react';
import T from 'prop-types';
import { createUser, editUser } from '../../api/admin/users';
import UserRegistrationForm from './userRegistrationForm';

class UserRegistrationFormContainer extends React.Component {
    onSubmit = async (data) => {
        data.companies = data.companies.filter((company) => !!company.company_id && !!company.role);

        try {
            if (this.props.currentUser) {
                await editUser(this.props.currentUser.id, data);
            } else {
                await createUser(data);
            }

            this.props.reload();
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    render() {
        return (
            <UserRegistrationForm
                currentUser={this.props.currentUser}
                companies={this.props.companies}
                dashboards={this.props.dashboards}
                onSubmit={this.onSubmit}
            />
        );
    }
}

UserRegistrationFormContainer.propTypes = {
    companies: T.arrayOf(T.object),
    dashboards: T.arrayOf(T.object),
    currentUser: T.object,
    reload: T.func,
};

UserRegistrationFormContainer.defaultProps = {
    companies: [],
    dashboards: [],
    currentUser: undefined,
};

export default UserRegistrationFormContainer;
