import React, { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import { Accordion, Grid, Button, Card, Form, Icon, Select, Table } from 'semantic-ui-react';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import {
    getAssessmentRequests,
    updateAssessmentRequest,
    deleteAssessmentRequest,
    getAssessmentRequestChangelog,
    downloadAssessmentData,
} from '../../api/survey-api/endpoints';
import { getAllAdminUsers, getAllUsers } from '../../api/admin/users';
import { getDateTime } from '../../utils/datetime';
import Modal from '../users/modal';
import ModalConfirm from '../../components/ModalConfirm';

const STATUS_MAP = {
    0: 'Draft',
    1: 'In Review',
    2: 'Live',
    3: 'Processing',
    4: 'Ready for QA',
    5: 'Processed',
    6: 'Completed',
};

const STATUS_ICON_MAP = {
    0: <Icon name="pencil" />,
    1: <Icon name="certificate" />,
    2: <Icon name="clock" />,
    3: <Icon name="spinner" />,
    4: <Icon name="spinner" />,
    5: <Icon name="check" />,
    6: <Icon name="flag checkered" />,
};

const STATUS_DESCRIPTION_MAP = {
    0: 'Assessment request not completed.',
    1: 'User can test the assessment and then add the live links to the game.',
    2: 'The number of participants who completed the assessment updates every 24 hours.',
    3: 'Our AI is clustering your personas. We will reach out once the dashboard passes our QA checks.',
    4: 'Ready for internal QA.',
    5: 'The audience awaits. User to request a kickoff.',
    6: 'There are no other actions available for this audience.',
};

interface Props {
    history: any;
    navigate: any;
    location: any;
    params: any;
}
class AssessmentRequestDetails extends Component<Props> {
    state = {
        data: undefined,
        changelog: [],
        statusActiveIndex: -1,
        modal: {
            title: '',
            content: '',
            isOpen: false,
            isError: true,
        },
        isConfirmModalShown: false,
        adminUsers: [],
        companyUsers: [],
    };

    async componentDidMount() {
        const { id } = this.props.params;

        try {
            const adminUsers = await getAllAdminUsers();
            const requests = await getAssessmentRequests(`id=${id}`);
            if (requests.length !== 1) {
                this.setState({
                    modal: {
                        title: 'Error',
                        content: 'request not found',
                        isOpen: true,
                        isError: true,
                    },
                });
            } else {
                const changelog = await getAssessmentRequestChangelog(id);

                const companyUsers = await getAllUsers(`company_id=${requests[0].company_id}`);

                this.setState({
                    data: requests[0],
                    changelog,
                    adminUsers,
                    companyUsers: companyUsers.users,
                });
            }
        } catch (e) {
            this.setState({
                modal: {
                    title: 'Error',
                    content: 'unable to load assessment requests',
                    isOpen: true,
                    isError: true,
                },
            });
        }
    }

    getAssessmentType = (assessmentType, contentType) => {
        if (contentType) {
            if (contentType === 'product') {
                contentType = 'Industry Agnostic';
            }

            contentType = contentType.charAt(0).toUpperCase() + contentType.slice(1);
        }

        if (assessmentType === '360') {
            assessmentType = '12traits 360';
        }
        if (assessmentType === 'preference') {
            assessmentType = 'Preference Pulse';
        }

        return `${contentType} ${assessmentType}`;
    };

    handleAccordionClick = (e, titleProps) => {
        const { index } = titleProps;
        const { statusActiveIndex } = this.state;
        const newIndex = statusActiveIndex === index ? -1 : index;

        this.setState({ statusActiveIndex: newIndex });
    };

    handleCloseModal = () => {
        this.setState((prevState) => {
            const prevModal = prevState.modal;
            prevModal.isOpen = false;

            return { modal: prevModal };
        });
    };

    handleOpenList = () => () => {
        this.props.navigate('/assessment/requests');
    };

    handleCloseModalConfirm = () => {
        this.setState({ isConfirmModalShown: false });
    };

    handleConfirmedDelete = () => {
        this.delete();
        this.handleCloseModalConfirm();
    };

    update = async () => {
        try {
            await updateAssessmentRequest(this.state.data.id, this.state.data);
            this.setState({
                modal: {
                    title: 'Success',
                    content: 'Successfully saved',
                    isOpen: true,
                    isError: false,
                },
            });
        } catch (e) {
            this.setState({
                modal: {
                    title: 'Error',
                    content: 'Unable to update assessment request',
                    isOpen: true,
                    isError: true,
                },
            });
        }
    };

    download = async () => {
        try {
            await downloadAssessmentData(this.state.data.id);
            this.setState({
                modal: {
                    title: 'Success',
                    content: 'Data will be downloaded from Alchemer to GCS.',
                    isOpen: true,
                    isError: false,
                },
            });
        } catch (e) {
            this.setState({
                modal: {
                    title: 'Error',
                    content: 'Unable to initiate data download.',
                    isOpen: true,
                    isError: true,
                },
            });
        }
    };

    delete = async () => {
        try {
            await deleteAssessmentRequest(this.state.data.id);

            this.props.navigate('/assessment/requests');
        } catch (e) {
            this.setState({
                modal: {
                    title: 'Error',
                    content: 'Unable to delete assessment request',
                    isOpen: true,
                    isError: true,
                },
            });
        }
    };

    renderRequestDetails() {
        const { data } = this.state;

        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>Request Details</Card.Header>
                    <Card.Description>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column width={3}>Project Name:</Grid.Column>
                                <Grid.Column>{data.project_name}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Audience Name:</Grid.Column>
                                <Grid.Column>{data.audience_name}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Assessment Type:</Grid.Column>
                                <Grid.Column>
                                    {this.getAssessmentType(
                                        data.assessment_type,
                                        data.content_type
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Requested at:</Grid.Column>
                                <Grid.Column>
                                    {data.requested_at ? getDateTime(data.requested_at) : ''}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Requested by:</Grid.Column>
                                <Grid.Column>
                                    {data.requested_by_details
                                        ? data.requested_by_details.email
                                        : ''}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <Button
                                        size="mini"
                                        content="DEL"
                                        color="red"
                                        labelPosition="right"
                                        icon="trash alternate outline"
                                        onClick={async () => {
                                            this.setState({ isConfirmModalShown: true });
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column />
                            </Grid.Row>
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }

    renderAssessmentStatus() {
        const { data, statusActiveIndex, adminUsers } = this.state;

        const statuses = Object.keys(STATUS_MAP).map((statusID) => {
            return { value: parseInt(statusID, 10), text: STATUS_MAP[statusID], key: statusID };
        });

        let csmEmail = '';
        let csmCalendarLink = '';
        if (data.company_details.csm_admin_user_id) {
            const csm = adminUsers.find((user) => {
                return user.id === data.company_details.csm_admin_user_id;
            });

            if (csm) {
                csmEmail = csm.email;
                if (data.company_details.csm_admin_user_calendar_link_id) {
                    const link = csm.calendar_links.find((l) => {
                        return l.id === data.company_details.csm_admin_user_calendar_link_id;
                    });

                    if (link) {
                        csmCalendarLink = link.calendar_link;
                    }
                }
            }
        }

        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>Assessment Status</Card.Header>
                    <Card.Description>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column width={3}>Status:</Grid.Column>
                                <Grid.Column>
                                    <Form.Field
                                        control={Select}
                                        onChange={(ev, option) => {
                                            this.setState((prevState) => {
                                                const newData = prevState.data;
                                                newData.status = option.value;

                                                return { data: newData };
                                            });
                                        }}
                                        disabled={STATUS_MAP[data.status] === 'Draft'}
                                        options={statuses}
                                        search
                                        value={data.status}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3} />
                                <Grid.Column>
                                    <Accordion fluid styled>
                                        <Accordion.Title
                                            active={statusActiveIndex === 0}
                                            index={0}
                                            onClick={this.handleAccordionClick}
                                        >
                                            <Icon name="dropdown" />
                                            Status Information
                                        </Accordion.Title>
                                        <Accordion.Content active={statusActiveIndex === 0}>
                                            <ul>
                                                {Object.keys(STATUS_DESCRIPTION_MAP).map(
                                                    (statusID) => {
                                                        return (
                                                            <li key={statusID}>
                                                                {STATUS_ICON_MAP[statusID]}{' '}
                                                                {STATUS_MAP[statusID]} -{' '}
                                                                {STATUS_DESCRIPTION_MAP[statusID]}
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </Accordion.Content>
                                        <Accordion.Title
                                            active={statusActiveIndex === 1}
                                            index={1}
                                            onClick={this.handleAccordionClick}
                                        >
                                            <Icon name="dropdown" />
                                            Participation
                                        </Accordion.Title>
                                        <Accordion.Content active={statusActiveIndex === 1}>
                                            <b>
                                                Total:{' '}
                                                {data.daily_responses
                                                    ? data.daily_responses.total
                                                    : 0}
                                            </b>
                                            <Table compact>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>
                                                            Date (in UTC)
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            Responses
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>Change</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {data.daily_responses &&
                                                        Object.keys(data.daily_responses)
                                                            .sort()
                                                            .filter((key) => {
                                                                return key !== 'total';
                                                            })
                                                            .map((key, i, keys) => {
                                                                let change = 0;
                                                                let symb = '';
                                                                if (i > 0) {
                                                                    const prev =
                                                                        data.daily_responses[
                                                                            keys[i - 1]
                                                                        ];
                                                                    if (prev > 0) {
                                                                        const diff =
                                                                            data.daily_responses[
                                                                                key
                                                                            ] - prev;
                                                                        change =
                                                                            (diff / prev) * 100;
                                                                    }
                                                                }
                                                                if (change > 0) {
                                                                    symb = '+';
                                                                }

                                                                return (
                                                                    <Table.Row key={key}>
                                                                        <Table.Cell>
                                                                            {key}
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {
                                                                                data
                                                                                    .daily_responses[
                                                                                    key
                                                                                ]
                                                                            }
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {symb}
                                                                            {Math.round(change)}%
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            })}
                                                </Table.Body>
                                            </Table>
                                        </Accordion.Content>
                                        <Accordion.Title
                                            active={statusActiveIndex === 2}
                                            index={2}
                                            onClick={this.handleAccordionClick}
                                        >
                                            <Icon name="dropdown" />
                                            CSM Information
                                        </Accordion.Title>
                                        <Accordion.Content active={statusActiveIndex === 2}>
                                            <Grid columns={2}>
                                                <Grid.Row>
                                                    <Grid.Column>CSM Assigned:</Grid.Column>
                                                    <Grid.Column>{csmEmail}</Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column>CSM Calendar Link:</Grid.Column>
                                                    <Grid.Column>{csmCalendarLink}</Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Accordion.Content>
                                    </Accordion>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button
                                        size="mini"
                                        content="Save Status"
                                        color="teal"
                                        labelPosition="right"
                                        icon="save outline"
                                        onClick={async () => {
                                            await this.update();
                                        }}
                                    />
                                    {'  '}
                                    <Button
                                        size="mini"
                                        content="Download Raw Data"
                                        color="olive"
                                        labelPosition="right"
                                        icon="cloud download"
                                        onClick={async () => {
                                            await this.download();
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column />
                            </Grid.Row>
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }

    renderChangelog() {
        let { changelog } = this.state;
        const { adminUsers, companyUsers } = this.state;
        if (!changelog) {
            changelog = [];
        }

        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>Changelog</Card.Header>
                    <Card.Description>
                        <Table compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Timestamp</Table.HeaderCell>
                                    <Table.HeaderCell>User</Table.HeaderCell>
                                    <Table.HeaderCell>Assessment Status</Table.HeaderCell>
                                    <Table.HeaderCell>New Values</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {changelog.map((log) => {
                                    let userEmail = log.submitted_by;
                                    if (log.is_admin) {
                                        const adminUser = adminUsers.find((user) => {
                                            return user.id === log.submitted_by;
                                        });
                                        if (adminUser) {
                                            userEmail = adminUser.email;
                                        }
                                    } else {
                                        const companyUser = companyUsers.find((user) => {
                                            return user.id === log.submitted_by;
                                        });
                                        if (companyUser) {
                                            userEmail = companyUser.email;
                                        }
                                    }

                                    return (
                                        <Table.Row key={log.id}>
                                            <Table.Cell>{getDateTime(log.submitted_at)}</Table.Cell>
                                            <Table.Cell>{userEmail}</Table.Cell>
                                            <Table.Cell>{log.assessment_status}</Table.Cell>
                                            <Table.Cell>
                                                {log.step &&
                                                    log.step.values &&
                                                    Object.keys(log.step.values).map((k) => {
                                                        let value = log.step.values[k];
                                                        if (k === 'logo_url') {
                                                            value = (
                                                                <a href={value}>click to view</a>
                                                            );
                                                        }
                                                        return (
                                                            <div key={`value_${k}`}>
                                                                <b>{k}</b>: {value}
                                                            </div>
                                                        );
                                                    })}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }

    renderAssessmentDetails() {
        const { data } = this.state;

        const mechanismKey = 'mechanism';
        const descriptionKey = 'description';
        const surveyLinks = data.configs || [];

        const isDeeplink = data.reward && data.reward[mechanismKey] === 'deeplink';

        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>Assessment Details</Card.Header>
                    <Card.Description>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column width={3}>Reward Delivery:</Grid.Column>
                                <Grid.Column>
                                    {data.reward ? data.reward[mechanismKey] : 'manual'}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Reward:</Grid.Column>
                                <Grid.Column>
                                    {data.reward ? data.reward[descriptionKey] : ''}
                                </Grid.Column>
                            </Grid.Row>
                            {isDeeplink ? (
                                <Grid.Row>
                                    <Grid.Column width={3}>Deeplink URL:</Grid.Column>
                                    <Grid.Column>{data.reward ? data.reward.link : ''}</Grid.Column>
                                </Grid.Row>
                            ) : (
                                ''
                            )}
                            {surveyLinks.map((surveyLink) => {
                                return (
                                    <React.Fragment key={surveyLink.env}>
                                        {surveyLink.deeplink && (
                                            <Grid.Row key={`aes_key_${surveyLink.env}`}>
                                                <Grid.Column width={3}>
                                                    Deeplink AES Key ({surveyLink.env}):
                                                </Grid.Column>
                                                <Grid.Column>
                                                    {surveyLink.deeplink.aes_secret_key}
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        <Grid.Row key={`api_key_${surveyLink.env}`}>
                                            <Grid.Column width={3}>
                                                API Key ({surveyLink.env}):
                                            </Grid.Column>
                                            <Grid.Column>
                                                {surveyLink.ingestion_api_key}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row key={`survey_url_${surveyLink.env}`}>
                                            <Grid.Column width={3}>
                                                Survey URL ({surveyLink.env}):
                                            </Grid.Column>
                                            <Grid.Column>
                                                <a
                                                    href={surveyLink.link}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    {surveyLink.link}
                                                </a>{' '}
                                                (Alchemer ID: {surveyLink.survey_id})
                                            </Grid.Column>
                                        </Grid.Row>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }

    render() {
        const { data, modal } = this.state;

        if (modal.isOpen) {
            return (
                <Container>
                    <LinkNavbar title="Assessment Request Details" />
                    {modal.isOpen && <Modal {...modal} handleCloseModal={this.handleCloseModal} />}
                </Container>
            );
        }

        if (data) {
            return (
                <Container>
                    <LinkNavbar title="Assessment Request Details" />
                    <Button.Group>
                        <Button
                            size="mini"
                            content="Back to List"
                            color="olive"
                            labelPosition="right"
                            icon="arrow left"
                            onClick={this.handleOpenList()}
                        />
                    </Button.Group>
                    {this.renderRequestDetails()}
                    {this.renderAssessmentStatus()}
                    {this.renderAssessmentDetails()}
                    {this.renderChangelog()}
                    <br />
                    <br />
                    {this.state.isConfirmModalShown && (
                        <ModalConfirm
                            onCancel={this.handleCloseModalConfirm}
                            onDelete={this.handleConfirmedDelete}
                            onClose={this.handleCloseModalConfirm}
                        ></ModalConfirm>
                    )}
                </Container>
            );
        }

        return '';
    }
}

export default withRouter(AssessmentRequestDetails);
