import React from 'react';
import T from 'prop-types';
import { Button, Form, Grid, Segment } from 'semantic-ui-react';
import { Container } from '../style';
class AssessmentCreativesForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            assessment_id: '',
            bucket: 'assessment-creatives',
            tuples_prefix: '',
            limit: 2,
            split_by_type: true,
            types: ['image', 'video'],
            add_last_copy_of_first: false,
            last_copy_types: [],
        };
    }

    render() {
        const { onSubmit } = this.props;

        return (
            <Container>
                <Grid centered columns={2} padded>
                    <Grid.Column>
                        <Segment>
                            <Form onSubmit={() => onSubmit(this.state)}>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Assessment ID or Survey ID"
                                        name="assessment_id"
                                        onChange={(ev, data) =>
                                            this.setState({ assessment_id: data.value })
                                        }
                                        value={this.state.assessment_id}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="GCS Bucket"
                                        name="bucket"
                                        onChange={(ev, data) =>
                                            this.setState({ bucket: data.value })
                                        }
                                        value={this.state.bucket}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Tuples Prefix"
                                        name="tuples_prefix"
                                        onChange={(ev, data) =>
                                            this.setState({ tuples_prefix: data.value })
                                        }
                                        value={this.state.tuples_prefix}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Limit"
                                        name="limit"
                                        onChange={(ev, data) => {
                                            let parsed = parseInt(data.value, 10);
                                            if (Number.isNaN(parsed)) {
                                                parsed = 0;
                                            }
                                            this.setState({ limit: parsed });
                                        }}
                                        value={this.state.limit}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Select
                                        fluid
                                        label="Split by Type?"
                                        name="split_by_type"
                                        onChange={(ev, data) =>
                                            this.setState({ split_by_type: data.value })
                                        }
                                        options={[
                                            { value: true, text: 'Yes' },
                                            { value: false, text: 'No' },
                                        ]}
                                        selection
                                        value={this.state.split_by_type}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Select
                                        fluid
                                        label="Types"
                                        multiple
                                        name="types"
                                        onChange={(ev, data) =>
                                            this.setState({ types: data.value })
                                        }
                                        options={[
                                            { value: 'image', text: 'image' },
                                            { value: 'video', text: 'video' },
                                        ]}
                                        selection
                                        value={this.state.types}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Select
                                        fluid
                                        label="Add last copy of first?"
                                        name="add_last_copy_of_first"
                                        onChange={(ev, data) =>
                                            this.setState({ add_last_copy_of_first: data.value })
                                        }
                                        options={[
                                            { value: true, text: 'Yes' },
                                            { value: false, text: 'No' },
                                        ]}
                                        selection
                                        value={this.state.add_last_copy_of_first}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Select
                                        fluid
                                        label="Last copy types"
                                        multiple
                                        name="last_copy_types"
                                        onChange={(ev, data) =>
                                            this.setState({ last_copy_types: data.value })
                                        }
                                        options={[
                                            { value: 'image', text: 'image' },
                                            { value: 'video', text: 'video' },
                                        ]}
                                        selection
                                        value={this.state.last_copy_types}
                                    />
                                </Form.Field>
                                <Button
                                    size="mini"
                                    content="SAVE"
                                    color="teal"
                                    labelPosition="right"
                                    icon="save outline"
                                    type="submit"
                                />
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}

AssessmentCreativesForm.propTypes = {
    onSubmit: T.func.isRequired,
};

AssessmentCreativesForm.defaultProps = {};

export default AssessmentCreativesForm;
