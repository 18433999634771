import { createAdminGetEndpoint, createAdminPostEndpoint } from './index';

export const getConfigNestedOptions = async () => {
    const res = await createAdminGetEndpoint('config/settings/nested-options')();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const getDashboardConfigFlags = async (assessmentType) => {
    const res = await createAdminGetEndpoint(
        assessmentType
            ? `config/dashboard-flags?assessment_type=${assessmentType}`
            : 'config/dashboard-flags'
    )(assessmentType);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const getGamesTagging = async (query, limit, offset) => {
    const url = `config/games/tagging?query=${query}&limit=${limit}&offset=${offset}`;

    const res = await createAdminGetEndpoint(url)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const updateGameTagging = async (req) => {
    const res = await createAdminPostEndpoint(`config/games/tagging`)(req);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const updateGenreTagging = async (req) => {
    const res = await createAdminPostEndpoint(`config/genres/tagging`)(req);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const upsertGame = async (req) => {
    const res = await createAdminPostEndpoint(`config/games`)(req);
    const metadata = res.data || {};

    return metadata;
};
