import { isEmpty } from 'lodash';
import { adminAPIInstance } from './config';

export const buildRequestOptions = (method, url, { headers = {}, data = {}, params = {} }) => {
    const options = {
        method,
        url,
        headers: {},
    };

    if (!isEmpty(data) || data instanceof FormData) {
        options.data = data;
    }

    if (!isEmpty(params)) {
        options.params = params;
    }

    if (!isEmpty(headers)) {
        Object.keys(headers).forEach((key) => {
            options.headers[key] = headers[key];
        });
    }

    const jwt = window.localStorage.getItem('admin_ui_jwt');
    if (jwt) {
        options.headers.Authorization = `Bearer ${jwt}`;
    }

    return options;
};

export const get = async (path, { params = {} }, instance = adminAPIInstance) => {
    const options = buildRequestOptions('GET', path, { params });

    return instance.request(options);
};

export const post = async (path, { data = {} }, instance = adminAPIInstance) => {
    const options = buildRequestOptions('POST', path, { data });

    return instance.request(options);
};

export const del = async (path, { data = {} }, instance = adminAPIInstance) => {
    const options = buildRequestOptions('DELETE', path, { data });

    return instance.request(options);
};

export const put = async (path, { data = {} }, instance = adminAPIInstance) => {
    const options = buildRequestOptions('PUT', path, { data });

    return instance.request(options);
};

export const patch = async (path, { data = {} }, instance = adminAPIInstance) => {
    const options = buildRequestOptions('PATCH', path, { data });

    return instance.request(options);
};
