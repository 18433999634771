import { Component } from 'react';
import { Header, Form, Select, Table, Button } from 'semantic-ui-react';
import { getAllGamesFiltered } from '../../api/admin/games';
import {
    getAllCustomActions,
    assignCustomActions,
    getUnlockRequests,
    unlockAction,
} from '../../api/admin/actions';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import Modal from '../users/modal';
import withRouter from '@/hocs/withRouter';

class Actions extends Component {
    state = {
        unlockRequests: [],
        customActions: [],
        games: [],
        gameId: '',
        clusters: [],
        actions: [],
        modal: {
            title: '',
            content: '',
            isOpen: false,
            isError: true,
        },
    };

    async componentDidMount() {
        const games = await getAllGamesFiltered('');
        const customActions = await getAllCustomActions();
        const unlockRequests = await getUnlockRequests();

        this.setState({ games: games.games, customActions, unlockRequests });
    }

    handleFormChange = async (field, value) => {
        if (field === 'game_id') {
            this.setState({ gameId: value, clusters: [] });
        } else {
            this.setState({ [field]: value });
        }
    };

    handleCloseModal = () => {
        this.setState((prevProps) => {
            const prevModal = prevProps.modal;
            prevModal.isOpen = false;

            return { modal: prevModal };
        });
    };

    assignActions = async () => {
        const { gameId, clusters, actions } = this.state;

        if (!gameId || !clusters.length || !clusters.length) {
            this.setState({
                modal: {
                    title: 'Error',
                    content: 'Game / Personas / Actions are required fields',
                    isOpen: true,
                    isError: true,
                },
            });

            return;
        }

        const request = {
            game_id: gameId,
            actions: [],
        };

        actions.forEach((a) => {
            request.actions.push({
                id: a,
                clusters,
            });
        });

        try {
            const res = await assignCustomActions(request);

            if (res.errors) {
                this.setState({
                    modal: {
                        title: 'Error',
                        content: res.errors,
                        isOpen: true,
                        isError: true,
                    },
                });
            } else {
                this.setState({
                    gameId: '',
                    clusters: [],
                    actions: [],
                    modal: {
                        title: 'Success',
                        content: `${res.nb_inserted} custom actions have been successfully assigned`,
                        isOpen: true,
                        isError: false,
                    },
                });
            }
        } catch (e) {
            this.setState({
                modal: {
                    title: 'Error',
                    content: 'Unable to assign actions',
                    isOpen: true,
                    isError: true,
                },
            });
        }
    };

    unlockAction = async (action) => {
        try {
            await unlockAction(action.id, action.game_id);

            this.setState({
                gameId: '',
                clusters: [],
                actions: [],
                modal: {
                    title: 'Success',
                    content: `Action has been successfully unlocked!`,
                    isOpen: true,
                    isError: false,
                },
            });

            const unlockRequests = await getUnlockRequests();

            this.setState({ unlockRequests });
        } catch (e) {
            this.setState({
                modal: {
                    title: 'Error',
                    content: 'Unable to unlock action',
                    isOpen: true,
                    isError: true,
                },
            });
        }
    };

    renderForm() {
        const { games, customActions, gameId } = this.state;

        if (!games || !customActions) {
            return '';
        }

        const gameOptions = games.map((g) => {
            return { value: g.id, text: g.name };
        });

        const filteredGames = games.filter((g) => g.id === gameId);
        const clusters = [];
        if (filteredGames.length) {
            for (let i = 0; i < filteredGames[0].clusters; i += 1) {
                clusters.push({ value: i, text: `Persona ${i + 1}` });
            }
        }

        const actions = customActions.map((a) => {
            return { value: a.id, text: a.summary };
        });

        return (
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        control={Select}
                        placeholder="Select Dashboard"
                        onChange={(ev, option) => this.handleFormChange('game_id', option.value)}
                        options={gameOptions}
                        search
                    />
                    <Form.Field
                        control={Select}
                        placeholder="Select Personas"
                        multiple
                        onChange={(ev, option) => this.handleFormChange('clusters', option.value)}
                        options={clusters}
                    />
                    <Form.Field
                        control={Select}
                        placeholder="Assign Custom Actions"
                        multiple
                        onChange={(ev, option) => this.handleFormChange('actions', option.value)}
                        options={actions}
                        search
                    />
                    <Button
                        content="Assign"
                        color="teal"
                        labelPosition="right"
                        icon="save outline"
                        type="submit"
                    />
                </Form.Group>
            </Form>
        );
    }

    renderUnlockRequestsList() {
        const { unlockRequests, games } = this.state;

        if (!unlockRequests) {
            return '';
        }

        const rows = unlockRequests.map((row) => {
            const game = games.find((g) => g.id == row.game_id);
            return (
                <Table.Row key={row.id}>
                    <Table.Cell key="1">{row.summary}</Table.Cell>
                    <Table.Cell key="2">{row.category}</Table.Cell>
                    <Table.Cell key="3">{game ? game.name : ''}</Table.Cell>
                    <Table.Cell key="4">{row.unlock_request_by.email}</Table.Cell>
                    <Table.Cell key="5">
                        <Button
                            size="mini"
                            content="Unlock"
                            color="teal"
                            labelPosition="right"
                            icon="unlock"
                            onClick={() => {
                                this.unlockAction(row);
                            }}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Summary</Table.HeaderCell>
                        <Table.HeaderCell>Category</Table.HeaderCell>
                        <Table.HeaderCell>Game Name</Table.HeaderCell>
                        <Table.HeaderCell>Requested By</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{rows}</Table.Body>
            </Table>
        );
    }

    render() {
        const { modal } = this.state;

        if (modal.isOpen) {
            return (
                <Container>
                    <LinkNavbar title="Actions" />
                    {modal.isOpen && <Modal {...modal} handleCloseModal={this.handleCloseModal} />}
                </Container>
            );
        }

        return (
            <Container>
                <LinkNavbar title="Actions" />
                <Header as="h1">Assign Custom Actions to a Game //</Header>
                {this.renderForm()}
                <Header as="h1">Unlock Requests //</Header>
                {this.renderUnlockRequestsList()}
            </Container>
        );
    }
}

Actions.propTypes = {};

export default withRouter(Actions);
