import React from 'react';
import T from 'prop-types';
import { createGame, updateGame } from '../../api/admin/games';
import GameRegistrationForm from './gameRegistrationForm';

class GameRegistrationFormContainer extends React.Component {
    state = {};

    constructor(props) {
        super(props);
    }

    onSubmit = async (data) => {
        try {
            const surveyId = Number(data.survey_id);

            let genres = [];
            if (data.genre_id) {
                const genreFiltered = this.props.genresOptions.filter(
                    (g) => g.id === data.genre_id
                );
                if (genreFiltered.length) {
                    genres = genreFiltered;
                }
            }

            let res = {};
            if (data.id === undefined) {
                res = await createGame({
                    ...data,
                    survey_id: surveyId,
                    genres,
                });
                const metadata = res.data || {};

                this.props.addGame({
                    ...data,
                    id: metadata.data.game.id,
                    company: data.company,
                });
            } else {
                await updateGame(data.id, {
                    ...data,
                    survey_id: surveyId,
                    genres,
                });

                this.props.editGame({
                    ...data,
                    company: data.company,
                    genres,
                });
            }

            return true;
        } catch (e) {
            return false;
        }
    };

    render() {
        return <GameRegistrationForm {...this.state} {...this.props} onSubmit={this.onSubmit} />;
    }
}

GameRegistrationFormContainer.propTypes = {
    addGame: T.func,
    editGame: T.func,
    genresOptions: T.arrayOf(
        T.shape({
            id: T.number,
            name: T.string,
            slug: T.string,
        })
    ),
};

GameRegistrationFormContainer.defaultProps = {
    addGame: undefined,
    editGame: undefined,
    genresOptions: undefined,
};

export default GameRegistrationFormContainer;
