import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import { Table, Header, Image, Form, Pagination, Button } from 'semantic-ui-react';
import {
    getAllCreatives,
    deleteCreative,
    reTriggerCreativeProcessing,
    fetchCreativeMediaInfo,
} from '../../api/freq-api/endpoints';
import { getAllCompanies } from '../../api/admin/companies';
import { Container, ClickableRow } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import { getDateOfRequest } from '../../utils/datetime';
import ModalConfirm from '@/components/ModalConfirm';

const STATUS_MAP = {
    0: 'Uploading',
    1: 'Processing',
    2: 'Ready',
};

class Creatives extends Component {
    state = {
        creatives: [],
        companies: [],
        sortField: 'uploaded_at',
        sortDirection: 'desc',
        limit: 50,
        offset: 0,
        companyID: '',
        creativeType: '',
        query: '',
        activePage: 1,
        totalPages: 1,
        isConfirmModalShown: false,
        temporaryDeletingCreative: null,
    };

    async componentDidMount() {
        const creatives = await getAllCreatives(this.filterStr());
        const companies = await getAllCompanies();

        this.setState((prevState) => ({
            creatives: creatives.creatives,
            totalPages: Math.ceil(creatives.total_count / prevState.limit),
            companies,
        }));
    }

    filterStr = () => {
        const params = [];
        params.push(`sort_field=${this.state.sortField}`);
        params.push(`sort_direction=${this.state.sortDirection}`);
        params.push(`limit=${this.state.limit}`);
        params.push(`offset=${this.state.offset}`);
        params.push(`company_id=${this.state.companyID}`);
        params.push(`creative_type=${this.state.creativeType}`);
        params.push(`query=${this.state.query}`);

        return params.join('&');
    };

    handlePaginationChange = (e, { activePage }) => {
        this.setState(
            (prevState) => ({
                activePage,
                offset: (activePage - 1) * prevState.limit,
            }),
            async () => {
                const creatives = await getAllCreatives(this.filterStr());
                this.setState((prevState) => ({
                    creatives: creatives.creatives,
                    totalPages: Math.ceil(creatives.total_count / prevState.limit),
                }));
            }
        );
    };

    handleFilterChange = async (field, value) => {
        this.setState({ [field]: value });
    };

    filterCreatives = async () => {
        this.setState(
            {
                offset: 0,
                activePage: 1,
            },
            async () => {
                const creatives = await getAllCreatives(this.filterStr());
                this.setState((prevState) => ({
                    creatives: creatives.creatives,
                    totalPages: Math.ceil(creatives.total_count / prevState.limit),
                }));
            }
        );
    };

    deleteCreative = async (id) => {
        await deleteCreative(id);

        const creatives = await getAllCreatives(this.filterStr());

        this.setState((prevState) => ({
            creatives: creatives.creatives,
            totalPages: Math.ceil(creatives.total_count / prevState.limit),
        }));
    };

    reTriggerCreativeProcessing = async (id) => {
        await reTriggerCreativeProcessing(id);
    };

    fetchCreativeMediaInfo = async (id) => {
        await fetchCreativeMediaInfo(id);
    };

    handleSort = (clickedColumn) => () => {
        const { sortField, sortDirection } = this.state;

        let newDirection = 'asc';
        if (sortField === clickedColumn) {
            newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        }

        this.setState(
            {
                offset: 0,
                activePage: 1,
                sortField: clickedColumn,
                sortDirection: newDirection,
            },
            async () => {
                const creatives = await getAllCreatives(this.filterStr());
                this.setState((prevState) => ({
                    creatives: creatives.creatives,
                    totalPages: Math.ceil(creatives.total_count / prevState.limit),
                }));
            }
        );
    };

    handleOpenCreative = (id) => () => {
        this.props.navigate(`/creatives/${id}`);
    };

    handleCloseModalConfirm = () => {
        this.setState({ isConfirmModalShown: false });
    };

    handleConfirmedDelete = async () => {
        this.deleteCreative(this.state.temporaryDeletingCreative);
        this.handleCloseModalConfirm();
    };

    renderBody() {
        const { creatives } = this.state;

        return creatives.map((row) => {
            return (
                <ClickableRow key={row.id} onClick={this.handleOpenCreative(row.id)}>
                    <Table.Cell key="_id">
                        <Header as="h4">
                            <Header.Content>
                                {row._id}
                                <Header.Subheader>
                                    Hidden: {row.hidden ? 'Yes' : 'No'}
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Table.Cell>
                    <Table.Cell key="1">
                        <Image size="mini" src={row.asset ? row.asset.thumbnail_url : ''} />
                    </Table.Cell>
                    <Table.Cell key="3">
                        <span>{row.company_details.name}</span>
                    </Table.Cell>
                    <Table.Cell key="4">
                        <span>{getDateOfRequest(row.uploaded_at)}</span>
                    </Table.Cell>
                    <Table.Cell key="5">
                        <span>{row.uploaded_by_details.email}</span>
                    </Table.Cell>
                    <Table.Cell key="6">
                        <span>{STATUS_MAP[row.status]}</span>
                    </Table.Cell>
                    <Table.Cell key="7">
                        <Button
                            size="mini"
                            content="Sync"
                            color="olive"
                            labelPosition="right"
                            icon="sync"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.reTriggerCreativeProcessing(row.id);
                            }}
                        />{' '}
                        <Button
                            size="mini"
                            content="Fetch"
                            color="olive"
                            labelPosition="right"
                            icon="picture"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.fetchCreativeMediaInfo(row.id);
                            }}
                        />{' '}
                        <Button
                            size="mini"
                            content="Delete"
                            color="red"
                            labelPosition="right"
                            icon="trash alternate"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({
                                    isConfirmModalShown: true,
                                    temporaryDeletingCreative: row.id,
                                });
                            }}
                        />
                    </Table.Cell>
                </ClickableRow>
            );
        });
    }

    renderTable() {
        const { sortField, sortDirection } = this.state;

        const DIRECTION_MAP = {
            asc: 'ascending',
            desc: 'descending',
        };

        const direction = sortDirection ? DIRECTION_MAP[sortDirection] : '';

        return (
            <Table celled selectable sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Thumbnail</Table.HeaderCell>
                        <Table.HeaderCell>Studio Name</Table.HeaderCell>
                        <Table.HeaderCell
                            onClick={this.handleSort('uploaded_at')}
                            sorted={sortField === 'uploaded_at' ? direction : null}
                        >
                            Uploaded At
                        </Table.HeaderCell>
                        <Table.HeaderCell>Uploaded By</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderBody()}</Table.Body>
            </Table>
        );
    }

    render() {
        const { companies, companyID, creativeType, query, totalPages, activePage } = this.state;
        const companiesOptions = companies.map((c) => ({ value: c.id, text: c.name }));
        companiesOptions.unshift({ value: '', text: 'All Companies' });

        return (
            <Container>
                <LinkNavbar title="Creatives" />
                <Header as="h1">Creatives //</Header>

                <Form>
                    <Form.Group widths="equal">
                        <Form.Select
                            fluid
                            placeholder="Filter by Company"
                            name="company_id"
                            onChange={(ev, option) =>
                                this.handleFilterChange('companyID', option.value)
                            }
                            options={companiesOptions}
                            search
                            selection
                            value={companyID}
                        />
                        <Form.Select
                            fluid
                            placeholder="Creative type"
                            name="creative_type"
                            onChange={(ev, option) =>
                                this.handleFilterChange('creativeType', option.value)
                            }
                            options={[
                                { value: '', text: 'Any' },
                                { value: 'image', text: 'Image' },
                                { value: 'video', text: 'Video' },
                            ]}
                            search
                            selection
                            value={creativeType}
                        />
                        <Form.Input
                            fluid
                            placeholder="Search..."
                            name="query"
                            onChange={(ev, option) =>
                                this.handleFilterChange('query', option.value)
                            }
                            value={query}
                        />
                        <Button
                            content="Filter"
                            color="teal"
                            labelPosition="right"
                            icon="search"
                            onClick={this.filterCreatives}
                            type="button"
                        />
                    </Form.Group>
                </Form>

                {this.renderTable()}

                <Pagination
                    activePage={activePage}
                    boundaryRange={1}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    nextItem={null}
                    onPageChange={this.handlePaginationChange}
                    prevItem={null}
                    siblingRange={1}
                    size="mini"
                    totalPages={totalPages}
                />
                {this.state.isConfirmModalShown && (
                    <ModalConfirm
                        onCancel={this.handleCloseModalConfirm}
                        onDelete={this.handleConfirmedDelete}
                        onClose={this.handleCloseModalConfirm}
                    ></ModalConfirm>
                )}
            </Container>
        );
    }
}

export default withRouter(Creatives);
