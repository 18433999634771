import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import {
    Table,
    Loader,
    Dimmer,
    Pagination,
    Input,
    Grid,
    Form,
    Segment,
    Message,
    Button,
} from 'semantic-ui-react';
import {
    getGamesTagging,
    getConfigNestedOptions,
    updateGameTagging,
    upsertGame,
} from '../../api/admin/config';
import { getAllGamesFiltered } from '../../api/admin/games';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import Modal from '../users/modal';
import { GameRow } from './Game';

class GamesTagging extends Component {
    state = {
        dashboards: [],
        formErrMsg: '',
        gameFormOpen: false,
        gameName: '',
        editGameID: null,
        games: [],
        gameURL: '',
        genresOptions: [],
        genresFlatMap: {},
        loading: true,
        query: '',
        limit: 50,
        activePage: 1,
        modal: {
            title: '',
            content: '',
            isOpen: false,
            isError: true,
        },
    };

    async componentDidMount() {
        let genresFlatOptions = [];

        const games = (await getAllGamesFiltered('basic_info_only=true')) || [];
        const dashboards = games.games;

        const config = await getConfigNestedOptions();
        const genres = config.genres_taxonomy || [];

        genresFlatOptions = this.getFlatOptions('', genres);

        this.setState({
            dashboards,
            genresOptions: genresFlatOptions[0] || [],
            genresFlatMap: genresFlatOptions[1] || [],
        });

        this.loadGames(1);
    }

    getFlatOptions(prefix, taxonomy) {
        const list = [];
        const map = {};

        taxonomy.forEach((row) => {
            list.push({ key: row.id, value: row.id, text: prefix + row.name });
            map[row.id] = row;

            if (row.children && row.children.length) {
                const childrenOptions = this.getFlatOptions(
                    `${prefix + row.name} | `,
                    row.children
                );
                list.push(...childrenOptions[0]);
                Object.assign(map, childrenOptions[1]);
            }
        });

        return [list, map];
    }

    loadGames = (page) => {
        this.setState(
            {
                games: [],
                loading: true,
            },
            async () => {
                const { query, limit } = this.state;

                try {
                    const games = await getGamesTagging(query, limit, limit * (page - 1));

                    this.setState({
                        games,
                        loading: false,
                    });
                } catch (e) {
                    this.setState({
                        loading: false,
                    });
                }
            }
        );
    };

    handlePaginationChange = (e, { activePage }) => {
        this.setState({
            activePage,
        });
        this.loadGames(activePage);
    };

    handleCloseModal = () => {
        const { modal } = this.state;
        this.setState({ modal: { ...modal, isOpen: false } });
    };

    updateGameTagging = async (gameSlug, ids, dataKey) => {
        const data = {
            game_slug: gameSlug,
            [dataKey]: ids,
        };

        try {
            await updateGameTagging(data);
        } catch (e) {
            let toString = e.toString();

            if (e.response && e.response.data) {
                toString = e.response.data.message;
            }

            this.setState({
                modal: {
                    title: 'Unable to update game tagging',
                    content: toString,
                    isOpen: true,
                    isError: true,
                },
            });
        }
    };

    onTaggingChange = async (flatMap, option, slug, collectionKey, dataKey) => {
        const { games } = this.state;
        const updatedItems = [...option.value];

        const updatedGames = games.map((game) => {
            if (game.slug === slug) {
                const updatedGame = {
                    ...game,
                    [collectionKey]: updatedItems.map((item) => ({ id: item })),
                };

                return updatedGame;
            }

            return game;
        });

        this.setState({ games: updatedGames });
        this.updateGameTagging(slug, updatedItems, dataKey);
    };

    onDashboardChange = async (slug, name, coverURL, dashboardIDs) => {
        const req = {
            slug,
            name,
            cover_url: coverURL,
            dashboard_ids: dashboardIDs,
        };

        await upsertGame(req);

        const { games } = this.state;

        const updatedGames = games.map((game) => {
            if (game.slug === slug) {
                const updatedGame = game;
                updatedGame.dashboard_ids = dashboardIDs;

                return updatedGame;
            }

            return game;
        });

        this.setState({ games: updatedGames });
    };

    onGameNameChange = async (id, name, coverURL) => {
        this.setState({ editGameID: id, gameName: name, gameURL: coverURL, gameFormOpen: true });
    };

    renderList() {
        const {
            dashboards,
            gameFormOpen,
            gameName,
            gameURL,
            games,
            loading,
            activePage,
            genresOptions,
            genresFlatMap,
        } = this.state;

        if (loading) {
            return (
                <Container>
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                </Container>
            );
        }

        const items = games.map((g) => {
            return (
                <GameRow
                    key={g.slug + g.name}
                    dashboards={dashboards}
                    game={g}
                    genresFlatMap={genresFlatMap}
                    genresOptions={genresOptions}
                    onDashboardChange={this.onDashboardChange}
                    onGameNameChange={this.onGameNameChange}
                    onTaggingChange={this.onTaggingChange}
                />
            );
        });

        let form = '';
        if (gameFormOpen) {
            let idMsg = '';
            if (this.state.editGameID) {
                idMsg = <Message content={`Editing game ID: ${this.state.editGameID}`} info />;
            }

            let errMsg = '';
            if (this.state.formErrMsg) {
                errMsg = <Message content={this.state.formErrMsg} negative />;
            }
            form = (
                <Segment inverted>
                    <Message
                        content="If game already exists with a given name - it will be updated."
                        warning
                    />
                    {idMsg}
                    <Form inverted>
                        <Form.Input
                            defaultValue={gameName}
                            fluid
                            label="Name"
                            onChange={(e, v) => this.setState({ gameName: v.value })}
                            placeholder="Name"
                        />
                        <Form.Input
                            defaultValue={gameURL}
                            fluid
                            label="Thumbnail URL"
                            onChange={(e, v) => this.setState({ gameURL: v.value })}
                            placeholder="Public image URL"
                        />
                        <Button
                            size="mini"
                            content="SAVE"
                            color="teal"
                            labelPosition="right"
                            icon="save outline"
                            onClick={async () => {
                                const req = {
                                    id: this.state.editGameID,
                                    name: this.state.gameName,
                                    cover_url: this.state.gameURL,
                                };

                                try {
                                    await upsertGame(req);

                                    this.setState({
                                        editGameID: null,
                                        gameName: '',
                                        gameURL: '',
                                        gameFormOpen: false,
                                        formErrMsg: '',
                                    });
                                } catch (e) {
                                    let toString = e.toString();

                                    if (e.response && e.response.data) {
                                        toString = e.response.data.message;
                                    }

                                    this.setState({ formErrMsg: toString });
                                }
                            }}
                        />{' '}
                        <Button
                            size="mini"
                            content="CANCEL"
                            color="black"
                            labelPosition="right"
                            icon="cancel"
                            onClick={() => {
                                this.setState({ gameFormOpen: false, formErrMsg: '' });
                            }}
                        />
                        {errMsg}
                    </Form>
                </Segment>
            );
        }

        return (
            <Container>
                <Grid centered columns={2} padded>
                    <Grid.Column>{form}</Grid.Column>
                </Grid>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Button
                                size="mini"
                                content="Add/Edit Game"
                                color="teal"
                                labelPosition="right"
                                icon="plus square outline"
                                onClick={() => this.setState({ gameFormOpen: true })}
                                type="button"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Input
                                action={{
                                    content: 'Search',
                                    onClick: () => {
                                        this.setState({ activePage: 1 }, function () {
                                            this.loadGames(1);
                                        });
                                    },
                                }}
                                defaultValue={this.state.query}
                                fluid
                                onChange={(_, value) => {
                                    this.setState({ query: value.value });
                                }}
                                placeholder="Search by game name..."
                                size="mini"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Genres</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{items}</Table.Body>
                </Table>
                <Pagination
                    activePage={activePage}
                    boundaryRange={1}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    nextItem={null}
                    onPageChange={this.handlePaginationChange}
                    prevItem={null}
                    siblingRange={1}
                    size="mini"
                    totalPages={50}
                />
                <br />
                <br />
            </Container>
        );
    }

    render() {
        const { modal } = this.state;

        if (modal.isOpen) {
            return (
                <Container>
                    <LinkNavbar title="Games Tagging" />
                    {modal.isOpen && <Modal {...modal} handleCloseModal={this.handleCloseModal} />}
                </Container>
            );
        }

        return (
            <Container>
                <LinkNavbar title="Games Tagging" />
                {this.renderList()}
            </Container>
        );
    }
}

GamesTagging.propTypes = {};

export default withRouter(GamesTagging);
