import React from 'react';
import T from 'prop-types';
import { createAssessmentCreativesConfig } from '../../api/survey-api/endpoints';
import AssessmentCreativesForm from './creativesForm';
import withRouter from '@/hocs/withRouter';

class AssessmentCreativesContainer extends React.Component {
    onSubmit = async (data) => {
        await createAssessmentCreativesConfig(data);

        this.props.navigate('');
    };

    render() {
        return <AssessmentCreativesForm deeplink={this.props.deeplink} onSubmit={this.onSubmit} />;
    }
}

AssessmentCreativesContainer.propTypes = {
    deeplink: T.object,
};

AssessmentCreativesContainer.defaultProps = {
    deeplink: undefined,
};

export default withRouter(AssessmentCreativesContainer);
