import styled from 'styled-components';

const Container = styled.div`
    display: block;
    max-width: 95%;
    margin: 100px auto 0;
`;

const Text = styled.p`
    margin: 0;
    padding: 0;
`;

const ClickableRow = styled.tr`
    &:hover {
        cursor: pointer;
    }
`;

const BtnGroup = styled.div`
    margin-top: 30px;
    display: flex;
    padding: 20px;
    > button:first-child {
        margin-right: 10px;
    }
`;

export { Container, Text, ClickableRow, BtnGroup };
