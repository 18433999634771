import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import { Button, Table } from 'semantic-ui-react';
import { getDeeplinks, deleteDeeplink } from '../../api/identity/deeplinks';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import DeeplinkContainer from './deeplinkContainer';
import ModalConfirm from '../../components/ModalConfirm';

class Deeplinks extends Component {
    state = {
        deeplinks: [],
        currentDeeplink: undefined,
        isConfirmModalShown: false,
        temporaryDeletingDeepLink: null,
    };

    async componentDidMount() {
        const deeplinks = (await getDeeplinks()) || [];

        this.setState({ deeplinks });
    }

    handleCloseModalConfirm = () => {
        this.setState({ isConfirmModalShown: false });
    };

    handleConfirmedDelete = async () => {
        await deleteDeeplink(this.state.temporaryDeletingDeepLink);
        this.handleCloseModalConfirm();
        window.location.reload();
    };

    renderBody() {
        const { deeplinks, currentDeeplink } = this.state;

        return deeplinks.map((row) => {
            if (currentDeeplink && row.survey_id === currentDeeplink.survey_id) {
                return (
                    <Table.Row key={row.survey_id}>
                        <Table.Cell colSpan={Object.keys(row).length + 1}>
                            <DeeplinkContainer deeplink={currentDeeplink} />;
                        </Table.Cell>
                    </Table.Row>
                );
            }

            return (
                <Table.Row key={row.survey_id}>
                    <Table.Cell key="1" singleLine>
                        <span>{row.survey_id}</span>
                    </Table.Cell>
                    <Table.Cell key="2" singleLine>
                        <span>{row.game_name}</span>
                    </Table.Cell>
                    <Table.Cell key="3" singleLine>
                        <span>{row.active ? 'yes' : 'no'}</span>
                    </Table.Cell>
                    <Table.Cell key="4" singleLine>
                        <span>{row.is_static ? 'yes' : 'no'}</span>
                    </Table.Cell>
                    <Table.Cell key="5" singleLine>
                        <span>{row.aes_secret_key}</span>
                    </Table.Cell>
                    <Table.Cell key="7" singleLine>
                        <span>{row.custom_template ? 'yes' : 'no'}</span>
                    </Table.Cell>
                    <Table.Cell key="8" singleLine>
                        <Button
                            size="mini"
                            content="EDIT"
                            color="teal"
                            labelPosition="right"
                            icon="edit outline"
                            onClick={() => {
                                this.setState({ currentDeeplink: row });
                            }}
                        />{' '}
                        <Button
                            size="mini"
                            content="DEL"
                            color="red"
                            labelPosition="right"
                            icon="trash alternate"
                            onClick={async () => {
                                this.setState({
                                    isConfirmModalShown: true,
                                    temporaryDeletingDeepLink: row.survey_id,
                                });
                            }}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    renderTable() {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Survey ID</Table.HeaderCell>
                        <Table.HeaderCell>Game Name</Table.HeaderCell>
                        <Table.HeaderCell>Is Active</Table.HeaderCell>
                        <Table.HeaderCell>Is Static</Table.HeaderCell>
                        <Table.HeaderCell>AES Key</Table.HeaderCell>
                        <Table.HeaderCell>Is Custom Template</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderBody()}</Table.Body>
            </Table>
        );
    }

    render() {
        return (
            <Container>
                <LinkNavbar title="Deeplinks" />
                <DeeplinkContainer />
                {this.renderTable()}
                {this.state.isConfirmModalShown && (
                    <ModalConfirm
                        onCancel={this.handleCloseModalConfirm}
                        onDelete={this.handleConfirmedDelete}
                        onClose={this.handleCloseModalConfirm}
                    ></ModalConfirm>
                )}
            </Container>
        );
    }
}

Deeplinks.propTypes = {};

export default withRouter(Deeplinks);
