import { isEmpty } from 'lodash';
import { identityInstance } from './config';

export const buildRequestOptions = (method, url, { data = {} }) => {
    const options = {
        method,
        url,
        headers: {},
    };

    if (!isEmpty(data)) {
        options.data = data;
    }

    const jwt = window.localStorage.getItem('admin_ui_jwt');
    options.headers.Authorization = `Bearer ${jwt}`;

    return options;
};

export const get = async (path, { params = {} }, instance = identityInstance) => {
    const options = buildRequestOptions('GET', path, { params });

    return instance.request(options);
};

export const del = async (path, { params = {} }, instance = identityInstance) => {
    const options = buildRequestOptions('DELETE', path, { params });

    return instance.request(options);
};

export const post = async (path, { data = {} }, instance = identityInstance) => {
    const options = buildRequestOptions('POST', path, { data });

    return instance.request(options);
};

export const put = async (path, { data = {} }, instance = identityInstance) => {
    const options = buildRequestOptions('PUT', path, { data });

    return instance.request(options);
};
