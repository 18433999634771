import {
    createGetEndpoint,
    createPutEndpoint,
    createDelEndpoint,
    createPatchEndpoint,
    createPostEndpoint,
} from './index';
import { buildRequestOptions } from '../freq-api';
import { freqAPIInstance } from '../config';

export const getFrequencyReviews = async () => {
    const res = await createGetEndpoint('/admin/frequency-reviews')();
    const metadata = res.data || {};
    const data = metadata.data || { requests: [] };

    return data.requests || [];
};

export const getFrequencyReview = async (id) => {
    const res = await createGetEndpoint(`/admin/frequency-reviews?id=${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || { requests: [] };

    const requests = data.requests || [];

    return requests.length ? requests[0] : null;
};

export const updateFrequencyReview = async (id, req) => {
    const res = await createPutEndpoint(`/admin/frequency-reviews/${id}`)(req);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const deleteFrequencyReview = async (id) => {
    const res = await createDelEndpoint(`/admin/frequency-reviews/${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const getAllCreatives = async (filtersStr) => {
    const res = await createGetEndpoint(`/admin/creatives?${filtersStr}`)();
    const metadata = res.data || {};

    return metadata.data || { creatives: [], total_count: 0 };
};

export const getCreative = async (id) => {
    const res = await createGetEndpoint(`/admin/creatives/${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || { creative: undefined };

    return data.creative;
};

export const updateCreative = async (id, req) => {
    const res = await createPatchEndpoint(`/admin/creatives/${id}`)(req);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const deleteCreative = async (id) => {
    const res = await createDelEndpoint(`/admin/creatives/${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const reTriggerCreativeProcessing = async (id) => {
    const res = await createPostEndpoint(`/admin/creatives/${id}/triggerProcessing`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const fetchCreativeMediaInfo = async (id) => {
    const res = await createPostEndpoint(`/admin/creatives/${id}/fetchMediaInfo`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const uploadThumbnail = async (id, creativeID, file) => {
    const formData = new FormData();
    formData.append('file', file);

    const fileHeader = { 'Content-Type': 'multipart/form-data' };

    const options = buildRequestOptions(
        'POST',
        `/admin/creative-reviews/${id}/upload/${creativeID}`,
        {
            data: formData,
            headers: fileHeader,
        }
    );

    try {
        const res = await freqAPIInstance.request(options);
        const metadata = res.data || {};
        const data = metadata.data || {};

        return [data, undefined];
    } catch (e) {
        return [undefined, e.response];
    }
};

export const getAdElements = async () => {
    const res = await createGetEndpoint('/admin/taxonomy')();
    const metadata = res.data || {};
    const data = metadata.data || { taxonomy: [] };

    return data.taxonomy || [];
};
