import React from 'react';
import withRouter from '@/hocs/withRouter';
import { Button, Form, Grid, Segment, Message } from 'semantic-ui-react';
import { Container } from '../style';
import { getAllAdminUsers, updateAdminUser } from '../../api/admin/users';
import { getUserSession } from '../../api/admin/auth';
import LinkNavbar from '../../components/linkNavbar';

class AccountSettings extends React.Component {
    state = {
        formData: undefined,
        calendarLinks: [],
        errorMsg: '',
        success: false,
    };

    async componentDidMount() {
        const adminUsers = await getAllAdminUsers();
        const currentSession = await getUserSession();
        if (!currentSession) {
            this.setState({ errorMsg: 'User not found' });
            return;
        }

        const currentUser = adminUsers.find((u) => u.email === currentSession.email);
        if (!currentUser) {
            this.setState({ errorMsg: 'User not found' });
            return;
        }

        this.setState({ formData: currentUser, calendarLinks: currentUser.calendar_links || [] });
    }

    render() {
        const { errorMsg, success, formData, calendarLinks } = this.state;
        if (!formData) return null;

        let errorBlock = '';
        if (errorMsg) {
            errorBlock = <Message negative>{errorMsg}</Message>;
        }

        let successBlock = '';
        if (success) {
            successBlock = <Message>User information has been updated!</Message>;
        }

        return (
            <Container>
                <LinkNavbar title="Account Settings" />
                <Grid centered columns={2} padded>
                    <Grid.Column>
                        <Segment>
                            {successBlock}
                            <Form
                                onSubmit={async () => {
                                    try {
                                        formData.calendar_links = calendarLinks;

                                        await updateAdminUser(formData.id, formData);
                                        this.setState({
                                            errorMsg: '',
                                            success: true,
                                        });
                                    } catch (err) {
                                        console.log(err);
                                        this.setState({
                                            errorMsg: err.response.data.message,
                                            success: false,
                                        });
                                    }
                                }}
                            >
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Email"
                                        name="email"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.email = data.value;

                                                return { formData };
                                            })
                                        }
                                        value={formData.email}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="First Name"
                                        name="first_name"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.first_name = data.value;

                                                return { formData };
                                            })
                                        }
                                        value={formData.first_name}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Last Name"
                                        name="last_name"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.last_name = data.value;

                                                return { formData };
                                            })
                                        }
                                        value={formData.last_name}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Password"
                                        name="password"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.password = data.value;

                                                return { formData };
                                            })
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Slack Handle"
                                        name="slack_handle"
                                        onChange={(ev, data) =>
                                            this.setState((prevState) => {
                                                const { formData } = prevState;
                                                formData.slack_handle = data.value;

                                                return { formData };
                                            })
                                        }
                                        value={formData.slack_handle}
                                    />
                                </Form.Field>
                                <Segment>
                                    {(calendarLinks || []).map((link, index) => {
                                        return (
                                            <Form.Field key={index}>
                                                <Form.Input
                                                    fluid
                                                    label={`Calendar Link #${index + 1}`}
                                                    name={`calendar_link_${index + 1}`}
                                                    onChange={(ev, data) => {
                                                        const { calendarLinks } = this.state;
                                                        calendarLinks[index].calendar_link =
                                                            data.value;

                                                        this.setState({ calendarLinks });
                                                    }}
                                                    value={link.calendar_link}
                                                />
                                                <Button
                                                    size="mini"
                                                    content="Remove Calendar Link"
                                                    color="red"
                                                    labelPosition="right"
                                                    icon="trash alternate outline"
                                                    type="button"
                                                    onClick={(e) => {
                                                        const { calendarLinks } = this.state;
                                                        calendarLinks.splice(index, 1);
                                                        this.setState({ calendarLinks });
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </Form.Field>
                                        );
                                    })}
                                    <Button
                                        size="mini"
                                        content="Add Calendar Link"
                                        color="olive"
                                        labelPosition="right"
                                        icon="plus square outline"
                                        type="button"
                                        onClick={(e) => {
                                            const { calendarLinks } = this.state;
                                            calendarLinks.push({ calendar_link: '' });
                                            this.setState({ calendarLinks });
                                            e.stopPropagation();
                                        }}
                                    />
                                </Segment>
                                <Button
                                    size="mini"
                                    content="SAVE"
                                    color="teal"
                                    labelPosition="right"
                                    icon="save outline"
                                    type="submit"
                                />
                            </Form>
                            {errorBlock}
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}

AccountSettings.propTypes = {};

export default withRouter(AccountSettings);
