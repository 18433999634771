import { ModalHeader } from './style';
import { Button, Modal, ModalActions, ModalContent } from 'semantic-ui-react';

const ModalConfirm = ({ onClose, onCancel, onDelete, message }) => {
    return (
        <Modal onClose={onClose} open={true}>
            <ModalHeader>Confirm Deletion</ModalHeader>
            <ModalContent>{message}</ModalContent>
            <ModalActions>
                <Button
                    size="mini"
                    content="CANCEL"
                    color="black"
                    labelPosition="right"
                    icon="check"
                    type="button"
                    onClick={onCancel}
                />
                <Button
                    size="mini"
                    content="DELETE"
                    color="red"
                    labelPosition="right"
                    icon="trash alternate"
                    type="button"
                    onClick={onDelete}
                />
            </ModalActions>
        </Modal>
    );
};
export default ModalConfirm;
