import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import { List, Breadcrumb, Card, Statistic } from 'semantic-ui-react';
import { getConfigNestedOptions, updateGenreTagging } from '../../api/admin/config';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import Modal from '../users/modal';

class Genres extends Component {
    state = {
        genresPath: [],

        modal: {
            title: '',
            content: '',
            isOpen: false,
            isError: true,
        },
        loading: false,
    };

    async componentDidMount() {
        const config = await getConfigNestedOptions();
        const genres = config.genres_taxonomy || [];

        this.setState({
            genresPath: [
                {
                    key: 'categories',
                    content: 'Genre Categories',
                    link: false,
                    active: true,
                    genres,
                    genre: undefined,
                },
            ],
        });
    }

    getFlatOptions(prefix, taxonomy) {
        const list = [];
        const map = {};

        taxonomy.forEach((row) => {
            list.push({ key: row.id, value: row.id, text: prefix + row.name });
            map[row.id] = row;

            if (row.children && row.children.length) {
                const childrenOptions = this.getFlatOptions(
                    `${prefix + row.name} | `,
                    row.children
                );
                list.push(...childrenOptions[0]);
                Object.assign(map, childrenOptions[1]);
            }
        });

        return [list, map];
    }

    updateGenre = async () => {
        const config = await getConfigNestedOptions();
        const genres = config.genres_taxonomy || [];
        const { genresPath } = this.state;

        const currentGenreId = this.state.genresPath.at(-1).genre.id as unknown as number;

        const findById = (data: any[], id: number): any => {
            let result;
            function iter(a: any) {
                if (a.id === id) {
                    result = a;
                    return true;
                }
                return Array.isArray(a.children) && a.children.some(iter);
            }

            data.some(iter);
            return result;
        };

        const currentGenre = findById(genres, currentGenreId);

        const newGenres = [...genresPath]
            .reduceRight((prev: any) => {
                if (prev.length === 0) {
                    prev.push({
                        key: currentGenreId,
                        content: currentGenre.name,
                        link: false,
                        active: true,
                        genres: currentGenre.children,
                        genre: currentGenre,
                    });
                }

                return prev;
            }, [])
            .reverse();

        this.setState({
            genresPath: newGenres,
            loading: false,
        });
    };

    updateGenreTagging = async (genreID) => {
        const data = {
            genre_id: genreID,
        };

        try {
            await updateGenreTagging(data);
            await this.updateGenre();
        } catch (e) {
            let toString = e.toString();

            if (e.response && e.response.data) {
                toString = e.response.data.message;
            }

            this.setState({
                modal: {
                    title: 'Unable to update genre tagging',
                    content: toString,
                    isOpen: true,
                    isError: true,
                },
                loading: false,
            });
        }
    };

    onTaggingChange = async (option, genreID) => {
        if (this.state.loading) return;
        const updatedItems = [...option.value];

        this.setState({ loading: true });
        this.updateGenreTagging(genreID, updatedItems);
    };

    handleCloseModal = () => {
        this.setState((prevProps) => {
            const prevModal = prevProps.modal;
            prevModal.isOpen = false;

            return { modal: prevModal };
        });
    };

    historyJump = (level) => {
        this.setState((prevProps) => {
            let prevPath = prevProps.genresPath;
            prevPath = prevPath.slice(0, level);
            prevPath[prevPath.length - 1].active = true;
            prevPath[prevPath.length - 1].link = false;
            delete prevPath[prevPath.length - 1].onClick;

            return { genresPath: prevPath };
        });
    };

    openGenre = (genre) => {
        this.setState((prevProps) => {
            const prevPath = prevProps.genresPath;
            prevPath.forEach((g, i) => {
                prevPath[i].active = false;
                prevPath[i].link = true;
                prevPath[i].onClick = () => {
                    this.historyJump(i + 1);
                };
            });

            prevPath.push({
                key: genre.id,
                content: genre.name,
                link: false,
                active: true,
                genres: genre.children,
                genre,
            });

            return { genresPath: prevPath };
        });
    };

    renderList() {
        const { genresPath } = this.state;
        if (!genresPath.length) {
            return '';
        }

        const genre = genresPath[genresPath.length - 1];

        let genreInfo = '';
        if (genre.genre !== undefined) {
            genreInfo = (
                <Card fluid>
                    <Card.Content extra>
                        <Statistic.Group>
                            <Statistic>
                                <Statistic.Value>{genre.genre.games_count}</Statistic.Value>
                                <Statistic.Label>Games</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>{genre.genre.profiles_count}</Statistic.Value>
                                <Statistic.Label>Profiles</Statistic.Label>
                            </Statistic>
                        </Statistic.Group>
                    </Card.Content>
                </Card>
            );
        }

        const items = genre.genres.map((g) => {
            let color = 'red';
            if (g.profiles_count >= 200 && g.games_count >= 3) {
                color = 'green';
            }
            return (
                <List.Item key={g.id} onClick={() => this.openGenre(g)}>
                    <List.Icon color={color} name="gamepad" size="large" verticalAlign="middle" />
                    <List.Content>
                        <List.Header as="a">{g.name}</List.Header>
                    </List.Content>
                </List.Item>
            );
        });

        return (
            <Container>
                {genreInfo}
                <List divided relaxed>
                    {items}
                </List>
            </Container>
        );
    }

    render() {
        const { modal, genresPath } = this.state;

        if (modal.isOpen) {
            return (
                <Container>
                    <LinkNavbar title="Genres" />
                    {modal.isOpen && <Modal {...modal} handleCloseModal={this.handleCloseModal} />}
                </Container>
            );
        }

        return (
            <Container>
                <LinkNavbar title="Genres" />
                <Breadcrumb icon="right angle" sections={genresPath} size="large" />
                {this.renderList()}
            </Container>
        );
    }
}

Genres.propTypes = {};

export default withRouter(Genres);
