import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import {
    Table,
    Form,
    Pagination,
    Header,
    Button,
    Modal,
    ModalHeader,
    ModalContent,
    ModalActions,
} from 'semantic-ui-react';
import { getAllUsers, deleteUser } from '../../api/admin/users';
import { getAllCompanies } from '../../api/admin/companies';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import UserRegistrationFormContainer from './userRegistrationFormContainer';
import ModalConfirm from '../../components/ModalConfirm';
import { getAllGamesFiltered } from '@/api/admin/games';

const rolesLabels = {
    superadmin: 'Superadmin',
    owner: 'Owner',
    admin: 'Admin',
    user: 'Member',
    preview: 'Preview user',
};

class Users extends Component {
    state = {
        users: [],
        companies: [],
        dashboards: [],
        limit: 50,
        offset: 0,
        companyID: '',
        query: '',
        modalOpen: false,
        currentUser: undefined,
        activePage: 1,
        totalPages: 1,
        idToDelete: null,
        isConfirmModalShown: false,
        dataReady: false,
    };

    async componentDidMount() {
        const users = await getAllUsers(this.filterStr());

        const companies = await getAllCompanies();
        const dashboards = await getAllGamesFiltered('');

        this.setState((prevState) => ({
            users: users.users,
            dashboards: dashboards.games,
            totalPages: Math.ceil(users.total_count / prevState.limit),
            companies,
            dataReady: true,
        }));
    }

    filterStr = () => {
        const params = [];
        params.push(`limit=${this.state.limit}`);
        params.push(`offset=${this.state.offset}`);
        params.push(`company_id=${this.state.companyID}`);
        params.push(`query=${this.state.query}`);

        return params.join('&');
    };

    handlePaginationChange = (e, { activePage }) => {
        this.setState(
            (prevState) => ({
                activePage,
                offset: (activePage - 1) * prevState.limit,
            }),
            async () => {
                const users = await getAllUsers(this.filterStr());
                this.setState((prevState) => ({
                    users: users.users,
                    totalPages: Math.ceil(users.total_count / prevState.limit),
                }));
            }
        );
    };

    handleFilterChange = async (field, value) => {
        this.setState({ [field]: value });
    };

    filterUsers = async () => {
        this.setState(
            {
                offset: 0,
                activePage: 1,
            },
            async () => {
                const users = await getAllUsers(this.filterStr());
                this.setState((prevState) => ({
                    users: users.users,
                    totalPages: Math.ceil(users.total_count / prevState.limit),
                }));
            }
        );
    };

    reloadUsers = async () => {
        this.setState({
            modalOpen: false,
            dataReady: false,
        });

        const users = await getAllUsers(this.filterStr());

        this.setState({
            users: users.users,
            dataReady: true,
        });
    };

    deleteUser = async (id) => {
        try {
            await deleteUser(id);

            this.deleteUserFromTheList(id);
        } catch (e) {
            console.log(e);
        }
    };

    deleteUserFromTheList = (id) =>
        this.setState((prevProps) => {
            const users = prevProps.users.filter((user) => user.id !== id);

            return { users };
        });

    handleCloseConfirmModal = () => {
        this.setState({ isConfirmModalShown: false });
    };

    handleConfirmedDelete = () => {
        this.deleteUser(this.state.idToDelete);
        this.handleCloseConfirmModal();
    };

    renderHeader() {
        return (
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Companies</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
        );
    }

    renderBody() {
        const { companies } = this.state;

        return this.state.users.map((user) => {
            return (
                <Table.Row key={user.email}>
                    <Table.Cell key="email" singleLine>
                        <Header as="h4">
                            <Header.Content>
                                {user.email}
                                <Header.Subheader>
                                    {user.first_name} {user.last_name}
                                </Header.Subheader>
                                <Header.Subheader>{user.id}</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Table.Cell>
                    <Table.Cell key="company" singleLine>
                        <Header as="h4">
                            <Header.Content>
                                {user.companies.map((userCompany) => {
                                    const companyDetails = companies.find(
                                        (company) => company.id === userCompany.company_id
                                    );
                                    if (companyDetails === undefined) {
                                        return '';
                                    }

                                    const subdomain = `${companyDetails.subdomain}.solsten.io`;
                                    const subdomainLink = `https://${subdomain}`;
                                    return (
                                        <Header.Subheader key={companyDetails.id}>
                                            <a
                                                href={subdomainLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {subdomain}
                                            </a>{' '}
                                            ({rolesLabels[userCompany.role]})
                                        </Header.Subheader>
                                    );
                                })}
                            </Header.Content>
                        </Header>
                    </Table.Cell>
                    <Table.Cell key="actions" singleLine>
                        <Button
                            size="mini"
                            content="EDIT"
                            color="olive"
                            labelPosition="right"
                            icon="edit outline"
                            onClick={() =>
                                this.setState({
                                    modalOpen: true,
                                    currentUser: user,
                                })
                            }
                        />{' '}
                        <Button
                            size="mini"
                            content="DELETE"
                            color="red"
                            labelPosition="right"
                            icon="trash alternate outline"
                            onClick={() =>
                                this.setState({
                                    isConfirmModalShown: true,
                                    idToDelete: user.id,
                                })
                            }
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    renderTable() {
        return (
            <Table celled selectable>
                <Table.Header>{this.renderHeader()}</Table.Header>
                <Table.Body>{this.renderBody()}</Table.Body>
            </Table>
        );
    }

    render() {
        const { companies, companyID, query, totalPages, activePage } = this.state;
        const companiesOptions = companies.map((c) => ({ value: c.id, text: c.name }));
        companiesOptions.unshift({ value: '', text: 'All Companies' });

        return (
            <Container>
                <LinkNavbar title="Users" />
                <Modal
                    open={this.state.modalOpen}
                    closeIcon
                    onClose={() =>
                        this.setState({
                            modalOpen: false,
                        })
                    }
                >
                    <ModalHeader>{this.state.currentUser ? 'Edit User' : 'Add User'}</ModalHeader>
                    <ModalContent>
                        <UserRegistrationFormContainer
                            currentUser={this.state.currentUser}
                            companies={this.state.companies}
                            dashboards={this.state.dashboards}
                            reload={this.reloadUsers}
                        />
                    </ModalContent>
                    <ModalActions>
                        <Button
                            color="black"
                            onClick={() =>
                                this.setState({
                                    modalOpen: false,
                                    currentCompany: undefined,
                                })
                            }
                        >
                            CANCEL
                        </Button>
                    </ModalActions>
                </Modal>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <Button
                                color="teal"
                                content="Add User"
                                labelPosition="right"
                                icon="plus square outline"
                                disabled={!this.state.dataReady}
                                onClick={() =>
                                    this.setState({
                                        modalOpen: true,
                                    })
                                }
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Select
                            fluid
                            placeholder="Filter by company"
                            name="company_id"
                            onChange={(ev, option) =>
                                this.handleFilterChange('companyID', option.value)
                            }
                            options={companiesOptions}
                            search
                            selection
                            value={companyID}
                        />
                        <Form.Input
                            fluid
                            placeholder="Type user email/name to search"
                            name="query"
                            onChange={(ev, option) =>
                                this.handleFilterChange('query', option.value)
                            }
                            value={query}
                        />
                        <Form.Field>
                            <Button
                                color="olive"
                                content="Filter"
                                labelPosition="right"
                                icon="search"
                                onClick={this.filterUsers}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                {this.renderTable()}
                <Pagination
                    activePage={activePage}
                    boundaryRange={1}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    nextItem={null}
                    onPageChange={this.handlePaginationChange}
                    prevItem={null}
                    siblingRange={1}
                    size="mini"
                    totalPages={totalPages}
                />
                {this.state.isConfirmModalShown && (
                    <ModalConfirm
                        onDelete={this.handleConfirmedDelete}
                        onCancel={this.handleCloseConfirmModal}
                        onClose={this.handleCloseConfirmModal}
                    ></ModalConfirm>
                )}
            </Container>
        );
    }
}

export default withRouter(Users);
