import React from 'react';
import T from 'prop-types';
import { pick } from 'lodash';
import { Button, Form, FormGroup, Grid, Message } from 'semantic-ui-react';
import CompanySelector from '../users/companySelector';
class GameRegistrationForm extends React.Component {
    state = {
        id: undefined,
        name: '',
        status: 0,
        survey_id: undefined,
        storage_bucket_name: '',
        company: '',
        genre_id: '',
        dashboard_config: {},
        not_original_data: false,
        superadmin_only: true,
        isAdding: false,
        errMessage: '',
    };

    constructor(props) {
        super(props);
        if (props.game !== undefined) {
            this.state = { ...this.state, ...props.game };
        }
        if (props.game === undefined || props.game.id === undefined) {
            this.state.dashboard_config = props.defaultConfigFlags;
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    };

    render() {
        const {
            companies,
            genresOptions,
            configFlags,
            defaultConfigFlags,
            onSubmit,
            handleDashboardConfigChange,
        } = this.props;

        const formValues = [
            'id',
            'name',
            'status',
            'company',
            'genre_id',
            'storage_bucket_name',
            'survey_id',
            'dashboard_config',
            'not_original_data',
            'superadmin_only',
        ];

        const genreDropdownOptions = genresOptions.map((g) => ({
            key: g.id,
            value: g.id,
            text: g.text,
        }));
        genreDropdownOptions.unshift({ key: '', value: '', text: 'Not Specified' });

        const configFields = configFlags.map((flag) => {
            let options = [];
            let value = defaultConfigFlags[flag.key];

            if (flag.type === 'bool') {
                options = [
                    { value: true, text: 'Yes' },
                    { value: false, text: 'No' },
                ];
            }
            if (flag.type === 'string') {
                options = flag.values.map((v) => {
                    return { value: v, text: v };
                });
            }

            if (this.state.dashboard_config[flag.key] !== undefined) {
                value = this.state.dashboard_config[flag.key];
            }
            return (
                <Form.Field key={flag.key}>
                    <Form.Select
                        value={value}
                        fluid
                        label={flag.title}
                        name={flag.key}
                        onChange={(e, { name, value }) => {
                            handleDashboardConfigChange(e, { name, value });
                            this.setState((prevState) => {
                                const newState = prevState;
                                newState.dashboard_config[name] = value;
                                if (name === 'namespace' && !value.startsWith('trait')) {
                                    newState.dashboard_config.empty_player_ids_file = true;
                                    newState.dashboard_config.overview_only_personas = true;
                                    newState.dashboard_config.enable_high_value_blade = false;
                                    newState.dashboard_config.enable_performance_metrics = false;
                                    newState.dashboard_config.enable_lookalike = false;
                                    newState.dashboard_config.kpis_enabled = false;
                                    newState.not_original_data = true;
                                }
                                if (name === 'namespace' && value.startsWith('trait')) {
                                    newState.dashboard_config.empty_player_ids_file = false;
                                    newState.dashboard_config.overview_only_personas = false;
                                    newState.dashboard_config.enable_high_value_blade = true;
                                    newState.dashboard_config.enable_performance_metrics = true;
                                    newState.dashboard_config.enable_lookalike = true;
                                    newState.dashboard_config.kpis_enabled = true;
                                    newState.not_original_data = false;
                                }

                                return newState;
                            });
                        }}
                        options={options}
                        selection
                    />
                </Form.Field>
            );
        });

        const configGroups = [];
        for (let i = 0; i < configFields.length; i += 2) {
            configGroups.push(configFields.slice(i, i + 2));
        }

        const configFieldGroups = configGroups.map((group, index) => (
            <FormGroup key={index} widths="equal">
                {group}
            </FormGroup>
        ));

        return (
            <Grid centered>
                <Grid.Column>
                    <Form
                        onSubmit={async () => {
                            this.setState({
                                isAdding: true,
                            });
                            const res = await onSubmit(pick(this.state, formValues));

                            if (res) {
                                this.setState({
                                    isAdding: false,
                                    errMessage: '',
                                });
                            } else {
                                this.setState({
                                    isAdding: false,
                                    errMessage: 'Error saving dashboard',
                                });
                            }
                        }}
                    >
                        <FormGroup widths="equal">
                            <CompanySelector
                                companies={companies}
                                onChangeCompany={(value) => this.setState({ company: value })}
                                value={this.state.company}
                            />
                            <Form.Field>
                                <Form.Input
                                    label="Name *"
                                    name="name"
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                />
                            </Form.Field>
                        </FormGroup>
                        <FormGroup widths="equal">
                            <Form.Field>
                                <Form.Input
                                    label="GCS Folder *"
                                    name="storage_bucket_name"
                                    onChange={this.handleChange}
                                    value={this.state.storage_bucket_name}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input
                                    label="Survey ID"
                                    name="survey_id"
                                    onChange={this.handleChange}
                                    value={this.state.survey_id}
                                />
                            </Form.Field>
                        </FormGroup>
                        <FormGroup widths="equal">
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="Original Data *"
                                    name="not_original_data"
                                    onChange={this.handleChange}
                                    options={[
                                        { value: false, text: 'Yes' },
                                        { value: true, text: 'No' },
                                    ]}
                                    selection
                                    value={this.state.not_original_data}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="Status *"
                                    name="status"
                                    onChange={this.handleChange}
                                    options={[
                                        { value: 0, text: 'Assessment Completed' },
                                        { value: 1, text: 'Under Assessment' },
                                        { value: 2, text: 'License Expired' },
                                    ]}
                                    selection
                                    value={this.state.status}
                                />
                            </Form.Field>
                        </FormGroup>
                        <FormGroup widths="equal">
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="Superadmin Only *"
                                    name="superadmin_only"
                                    onChange={this.handleChange}
                                    options={[
                                        { value: false, text: 'No' },
                                        { value: true, text: 'Yes' },
                                    ]}
                                    selection
                                    value={this.state.superadmin_only}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="Genre"
                                    name="genre_id"
                                    onChange={(ev, data) => this.setState({ genre_id: data.value })}
                                    options={genreDropdownOptions}
                                    selection
                                    value={this.state.genre_id}
                                />
                            </Form.Field>
                        </FormGroup>
                        {configFieldGroups}
                        <Button
                            content={this.state.isAdding ? 'Saving...' : 'Save'}
                            color="teal"
                            labelPosition="right"
                            icon="save outline"
                            disabled={this.state.isAdding}
                            type="submit"
                        />
                    </Form>
                    {this.state.errMessage && <Message negative>{this.state.errMessage}</Message>}
                </Grid.Column>
            </Grid>
        );
    }
}

GameRegistrationForm.propTypes = {
    companies: T.arrayOf(
        T.shape({
            id: T.string,
            subdomain: T.string,
        })
    ),
    genres: T.arrayOf(
        T.shape({
            id: T.number,
            igdb_id: T.number,
            name: T.string,
        })
    ),
    onSubmit: T.func.isRequired,
    game: T.object,
    defaultConfigFlags: T.any,
    genresOptions: T.any,
    configFlags: T.any,
    type: T.string,
};

GameRegistrationForm.defaultProps = {
    companies: [],
    genres: [],
    configFlags: [],
    defaultConfigFlags: undefined,
    game: {
        id: undefined,
        name: '',
        genre_id: undefined,
        survey_id: undefined,
        storage_bucket_name: '',
        company: '',
        dashboard_config: {},
    },
    genresOptions: undefined,
    type: '',
};

export default GameRegistrationForm;
