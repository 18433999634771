import React from 'react';
withRouter;
import { Form, Grid, Segment, Message, Button } from 'semantic-ui-react';
import { Container } from '../style';
import { login, setJWT } from '../../api/admin/auth';
import withRouter from '@/hocs/withRouter';
import moment from 'moment';
import queryString from 'query-string';

interface Props {
    history: any;
    navigate: any;
    location: any;
    params: any;
}

class SignIn extends React.Component<Props> {
    state = {
        email: '',
        pass: '',
        errorMsg: '',
    };

    async componentDidMount() {
        const params = queryString.parse(this.props.location.search);

        if (params && params.jwt) {
            setJWT(params.jwt);
            window.location.href = '/';
        }
    }

    render() {
        const { errorMsg } = this.state;

        let errorBlock = '';
        if (errorMsg) {
            errorBlock = <Message negative>{errorMsg}</Message>;
        }

        return (
            <Container>
                <Grid centered columns={2} padded>
                    <Grid.Column>
                        <Segment>
                            <div style={{ textAlign: 'center' }}>
                                <img
                                    alt="penguins"
                                    width="200"
                                    height="200"
                                    src="/images/penguins.png"
                                />
                            </div>
                            <Form
                                onSubmit={async () => {
                                    const res = await login(this.state);
                                    if (!res) {
                                        this.setState({ errorMsg: 'Invalid email or pass' });
                                    } else {
                                        this.setState({ errorMsg: '' });
                                        this.props.navigate('/');
                                    }
                                }}
                            >
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Email"
                                        name="email"
                                        onChange={(ev, data) =>
                                            this.setState({ email: data.value })
                                        }
                                        value={this.state.email}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Password"
                                        name="pass"
                                        onChange={(ev, data) => this.setState({ pass: data.value })}
                                        type="password"
                                        value={this.state.pass}
                                    />
                                </Form.Field>
                                <div>
                                    <Button
                                        size="mini"
                                        content="Sign In"
                                        color="teal"
                                        labelPosition="right"
                                        icon="key"
                                        type="submit"
                                    />
                                    <Button
                                        size="mini"
                                        content="Sign in with Google"
                                        color="olive"
                                        labelPosition="right"
                                        icon="google"
                                        type="button"
                                        onClick={() => {
                                            const state = moment().unix();
                                            const redirectUrl = window.location.origin + '/sign-in';
                                            const url = `${process.env.ADMIN_API_URL}/v1/auth/google/redirect?state=${state}&success_url=${redirectUrl}&error_url=${redirectUrl}`;
                                            window.location.href = url;
                                        }}
                                    />
                                </div>
                            </Form>
                            {errorBlock}
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}

SignIn.propTypes = {};

export default withRouter(SignIn);
