import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import { Table, Header, Icon, Form, Button } from 'semantic-ui-react';
import { getAssessmentRequests, deleteAssessmentRequest } from '../../api/survey-api/endpoints';
import { getAllCompanies } from '../../api/admin/companies';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import { getDateTime } from '../../utils/datetime';
import ModalConfirm from '../../components/ModalConfirm';

const STATUS_MAP = {
    0: 'Draft',
    1: 'In Review',
    2: 'Live',
    3: 'Processing',
    4: 'Ready for QA',
    5: 'Processed',
    6: 'Completed',
};

const STATUS_ICON_MAP = {
    0: <Icon name="pencil" />,
    1: <Icon name="certificate" />,
    2: <Icon name="clock" />,
    3: <Icon name="spinner" />,
    4: <Icon name="eye" />,
    5: <Icon name="check" />,
    6: <Icon name="flag checkered" />,
};

class AssessmentRequests extends Component {
    state = {
        requests: [],
        companies: [],
        companyID: '',
        isConfirmModalShown: false,
        temporaryDeletingRequest: null,
    };

    async componentDidMount() {
        const requests = await getAssessmentRequests(this.filterStr());
        const companies = await getAllCompanies();

        this.setState({ requests, companies });
    }

    getAssessmentType = (assessmentType, contentType) => {
        if (contentType) {
            if (contentType === 'product') {
                contentType = 'Industry Agnostic';
            }

            contentType = contentType.charAt(0).toUpperCase() + contentType.slice(1);
        }

        if (assessmentType === '360') {
            assessmentType = '12traits 360';
        }
        if (assessmentType === 'preference') {
            assessmentType = 'Preference Pulse';
        }

        return `${contentType} ${assessmentType}`;
    };

    filterStr = () => {
        const params = [];
        params.push(`company_id=${this.state.companyID}`);

        return params.join('&');
    };

    filterAssessmentRequests = async () => {
        this.setState({}, async () => {
            const requests = await getAssessmentRequests(this.filterStr());
            this.setState({
                requests,
            });
        });
    };

    delete = async (id) => {
        await deleteAssessmentRequest(id);

        const requests = await getAssessmentRequests(this.filterStr());

        this.setState({ requests });
    };

    handleOpenAssessmentRequest = (id) => () => {
        this.props.navigate(`/assessment/requests/${id}`);
    };

    handleCloseModalConfirm = () => {
        this.setState({ isConfirmModalShown: false });
    };

    handleConfirmedDelete = () => {
        this.delete(this.state.temporaryDeletingRequest);
        this.handleCloseModalConfirm();
    };

    renderBody() {
        const { requests, companies } = this.state;

        return requests.map((row) => {
            const company = companies.find((c) => c.id === row.company_id);

            return (
                <Table.Row key={row.id} onClick={this.handleOpenAssessmentRequest(row.id)}>
                    <Table.Cell key="2">
                        <span>{company ? company.name : ''}</span>
                    </Table.Cell>
                    <Table.Cell key="3">
                        <span>{row.audience_name}</span>
                    </Table.Cell>
                    <Table.Cell key="project_name">
                        <span>{row.project_name}</span>
                    </Table.Cell>
                    <Table.Cell key="4">
                        <span>{this.getAssessmentType(row.assessment_type, row.content_type)}</span>
                    </Table.Cell>
                    <Table.Cell key="5">
                        <span>{row.requested_at ? getDateTime(row.requested_at) : ''}</span>
                    </Table.Cell>
                    <Table.Cell key="6">
                        <span>{row.daily_responses ? row.daily_responses.total : 0}</span>
                    </Table.Cell>
                    <Table.Cell key="7">
                        <span>
                            {STATUS_ICON_MAP[row.status]} {STATUS_MAP[row.status]}
                        </span>
                    </Table.Cell>
                    <Table.Cell key="8">
                        <Button
                            size="mini"
                            content="DEL"
                            color="red"
                            labelPosition="right"
                            icon="trash alternate"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({
                                    isConfirmModalShown: true,
                                    temporaryDeletingRequest: row.id,
                                });
                            }}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    renderTable() {
        return (
            <Table celled selectable sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Studio</Table.HeaderCell>
                        <Table.HeaderCell>Audience Name</Table.HeaderCell>
                        <Table.HeaderCell>Project Name</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Requested at</Table.HeaderCell>
                        <Table.HeaderCell>Total Participants</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderBody()}</Table.Body>
            </Table>
        );
    }

    render() {
        const { companies, companyID } = this.state;
        const companiesOptions = companies.map((c) => ({ value: c.id, text: c.name }));
        companiesOptions.unshift({ value: '', text: 'All Companies' });

        return (
            <Container>
                <LinkNavbar title="Assessment Requests" />
                <Header as="h1">Assessment Requests //</Header>

                <Form>
                    <Form.Group widths="equal">
                        <Form.Select
                            fluid
                            placeholder="Filter by Company"
                            name="company_id"
                            onChange={(ev, option) => {
                                this.setState({ companyID: option.value });
                            }}
                            options={companiesOptions}
                            search
                            selection
                            value={companyID}
                        />
                        <Form.Field>
                            <Button
                                color="olive"
                                content="Filter"
                                labelPosition="right"
                                icon="search"
                                onClick={this.filterAssessmentRequests}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                {this.renderTable()}
                {this.state.isConfirmModalShown && (
                    <ModalConfirm
                        onCancel={this.handleCloseModalConfirm}
                        onDelete={this.handleConfirmedDelete}
                        onClose={this.handleCloseModalConfirm}
                    ></ModalConfirm>
                )}
            </Container>
        );
    }
}

export default withRouter(AssessmentRequests);
