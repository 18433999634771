import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import { Button, Table, Form, Segment, Message, Popup } from 'semantic-ui-react';
import {
    upsertTeamAssessment,
    getTeamAssessments,
    deleteTeamAssessment,
} from '../../api/survey-api/endpoints';
import { getAllCompanies } from '../../api/admin/companies';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import CompanySelector from '../users/companySelector';
import ModalConfirm from '../../components/ModalConfirm';

const statusError = 4;
const statusReady = 3;

const statusMap = {
    0: 'New',
    1: 'Downloading data',
    2: 'Processing data',
    3: 'Ready',
    4: 'Unable to process data',
};

const formDataDefault = {
    id: '',
    company_id: '',
    survey_id: '',
    gcs_folder: '',
};

interface Props {
    history: any;
    navigate: any;
    location: any;
    params: any;
}
class TeamAssessments extends Component<Props> {
    state = {
        assessments: [],
        companies: [],
        formData: formDataDefault,
        isConfirmModalShown: false,
        isFormOpen: false,
        idToDelete: undefined,
        errMessage: '',
    };

    async componentDidMount() {
        const assessments = await getTeamAssessments();
        const companies = await getAllCompanies();

        this.setState({ assessments, companies });
    }

    handleCloseModalConfirm = () => {
        this.setState({ isConfirmModalShown: false });
    };

    handleConfirmedDelete = async () => {
        const { idToDelete } = this.state;
        if (!idToDelete) {
            return;
        }

        await deleteTeamAssessment(idToDelete);

        this.handleCloseModalConfirm();
        window.location.reload();
    };

    renderBody() {
        const { assessments, companies } = this.state;

        return assessments.map((row) => {
            const company = companies.find((c) => c.id === row.company_id);
            const reportURL = `https://${company.subdomain}.solsten.io/team-assessment/${row.id}`;

            return (
                <Table.Row key={row.id}>
                    <Table.Cell key="1" singleLine>
                        <span>{company ? company.name : ''}</span>
                    </Table.Cell>
                    <Table.Cell key="2" singleLine>
                        <span>{row.survey_id}</span>
                    </Table.Cell>
                    <Table.Cell key="status" singleLine>
                        <span>{statusMap[row.status]}</span>
                        {row.status == statusError && (
                            <span>
                                &nbsp;
                                <Popup
                                    content={row.error_message}
                                    trigger={<Button icon="warning sign" />}
                                />
                            </span>
                        )}
                    </Table.Cell>
                    <Table.Cell key="3" singleLine>
                        <span>
                            {row.status === statusReady ? (
                                <a href={reportURL} target="_blank" rel="noreferrer">
                                    Report Link
                                </a>
                            ) : (
                                ''
                            )}
                        </span>
                    </Table.Cell>
                    <Table.Cell key="gcs" singleLine>
                        <span>{row.gcs_folder}</span>
                    </Table.Cell>
                    <Table.Cell key="8" singleLine>
                        <Button
                            size="mini"
                            content="EDIT"
                            color="olive"
                            labelPosition="right"
                            icon="edit"
                            onClick={() => {
                                this.setState({ formData: row, isFormOpen: true });
                            }}
                        />{' '}
                        <Button
                            size="mini"
                            content="DEL"
                            color="red"
                            labelPosition="right"
                            icon="trash alternate outline"
                            onClick={async () => {
                                this.setState({
                                    isConfirmModalShown: true,
                                    idToDelete: row.id,
                                });
                            }}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    renderTable() {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Company</Table.HeaderCell>
                        <Table.HeaderCell>Alchemer Survey ID</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Report</Table.HeaderCell>
                        <Table.HeaderCell>GCS folder</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderBody()}</Table.Body>
            </Table>
        );
    }

    renderForm() {
        const { isFormOpen, formData, companies, errMessage } = this.state;

        if (!isFormOpen) {
            return '';
        }

        return (
            <Segment>
                <Form>
                    <CompanySelector
                        companies={companies}
                        onChangeCompany={(value) =>
                            this.setState((ps) => {
                                const { formData } = ps;
                                formData.company_id = value;

                                return { formData };
                            })
                        }
                        value={formData.company_id}
                    />
                    <Form.Field>
                        <Form.Input
                            fluid
                            label="Alchemer Survey ID"
                            name="survey_id"
                            onChange={(ev, data) =>
                                this.setState((ps) => {
                                    const { formData } = ps;
                                    formData.survey_id = data.value;

                                    return { formData };
                                })
                            }
                            value={formData.survey_id}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            fluid
                            label="GCS Folder"
                            name="gcs_folder"
                            onChange={(ev, data) =>
                                this.setState((ps) => {
                                    const { formData } = ps;
                                    formData.gcs_folder = data.value;

                                    return { formData };
                                })
                            }
                            value={formData.gcs_folder}
                        />
                    </Form.Field>
                    <Button
                        size="mini"
                        content="SAVE"
                        color="teal"
                        labelPosition="right"
                        icon="save outline"
                        onClick={async () => {
                            try {
                                formData.survey_id = parseInt(formData.survey_id, 10);

                                await upsertTeamAssessment(formData);
                                window.location.reload();
                            } catch (e) {
                                this.setState({
                                    errMessage: e.response.data.message ?? 'Unknown error',
                                });
                            }
                        }}
                    />{' '}
                    <Button
                        size="mini"
                        content="CANCEL"
                        color="black"
                        labelPosition="right"
                        icon="cancel"
                        onClick={() => {
                            this.setState({ isFormOpen: false, formData: formDataDefault });
                        }}
                    />
                </Form>
                {errMessage && <Message negative>{errMessage}</Message>}
            </Segment>
        );
    }

    render() {
        return (
            <Container>
                <LinkNavbar title="Team Assessments" />
                <Button
                    size="mini"
                    content="New Team Assessment"
                    color="teal"
                    labelPosition="right"
                    icon="plus square outline"
                    onClick={() => this.setState({ isFormOpen: true, formData: formDataDefault })}
                />
                {this.renderForm()}
                {this.renderTable()}
                {this.state.isConfirmModalShown && (
                    <ModalConfirm
                        onCancel={this.handleCloseModalConfirm}
                        onDelete={this.handleConfirmedDelete}
                        onClose={this.handleCloseModalConfirm}
                    ></ModalConfirm>
                )}
            </Container>
        );
    }
}

TeamAssessments.propTypes = {};

export default withRouter(TeamAssessments);
