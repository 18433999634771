import { Component } from 'react';
import withRouter from '@/hocs/withRouter';
import { Grid, Button, Header, Card, Image, Label, Form, Message, Select } from 'semantic-ui-react';
import { pick } from 'lodash';
import { Container } from '../style';
import LinkNavbar from '../../components/linkNavbar';
import { getFrequencyReview, updateFrequencyReview } from '../../api/freq-api/endpoints';
import { getAllGamesFiltered } from '../../api/admin/games';
import { getDateOfRequest, getDateTime } from '../../utils/datetime';
import Modal from '../users/modal';

interface Props {
    history: any;
    navigate: any;
    location: any;
    params: any;
}

class FrequencyReviewPage extends Component<Props> {
    state = {
        data: undefined,
        dashboards: [],
        modal: {
            title: '',
            content: '',
            isOpen: false,
            isError: true,
        },
    };

    async componentDidMount() {
        const { id } = this.props.params;

        try {
            const data = await getFrequencyReview(id);
            const dashboards = await getAllGamesFiltered('basic_info_only=true');

            if (!data) {
                this.props.navigate('/frequency-reviews');
            } else {
                this.setState({ data, dashboards: dashboards.games });
            }
        } catch (e) {
            this.setState({
                modal: {
                    title: 'Error',
                    content: 'unable to load frequency review',
                    isOpen: true,
                    isError: true,
                },
            });
        }
    }

    handleCloseModal = () => {
        this.setState((prevState) => {
            const prevModal = prevState.modal;
            prevModal.isOpen = false;

            return { modal: prevModal };
        });
    };

    handleOpenList = () => () => {
        this.props.navigate('/frequency-reviews');
    };

    changeStatus = async (status) => {
        await updateFrequencyReview(this.state.data.id, {
            status,
        });

        this.setState((prevState) => {
            const { data } = prevState;
            data.status = status;

            return { data };
        });
    };

    flattenTree(prefix, node) {
        const list = [];
        node.forEach((item) => {
            list.push({ value: item.slug, text: prefix + item.name });
            if (item.children && item.children.length) {
                list.push(...this.flattenTree(`${prefix + item.name} | `, item.children));
            }
        });

        return list;
    }

    renderOverview() {
        const { data } = this.state;

        let button = '';
        if (data.status <= 1) {
            button = (
                <Button
                    size="mini"
                    content="Start Progress"
                    color="teal"
                    labelPosition="right"
                    icon="save outline"
                    onClick={() => this.changeStatus(2)}
                />
            );
        } else if (data.status === 2) {
            button = (
                <Button
                    size="mini"
                    content="Publish"
                    color="teal"
                    labelPosition="right"
                    icon="save outline"
                    onClick={() => this.changeStatus(4)}
                />
            );
        } else if (data.status === 4) {
            button = (
                <Button
                    size="mini"
                    content="Publish"
                    color="teal"
                    labelPosition="right"
                    icon="save outline"
                    disabled
                />
            );
        } else if (data.status === 3) {
            button = (
                <Button
                    size="mini"
                    content="Canceled"
                    color="teal"
                    labelPosition="right"
                    icon="save outline"
                    disabled
                />
            );
        }

        return (
            <Card fluid>
                <Card.Content>
                    {button}
                    <Card.Header>Request Details</Card.Header>
                    <Card.Description>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column width={3}>Review #:</Grid.Column>
                                <Grid.Column>#{data.id}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Requested by:</Grid.Column>
                                <Grid.Column>{data.review.requested_by.email}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Date requested:</Grid.Column>
                                <Grid.Column>{getDateOfRequest(data.created_at)}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Started at:</Grid.Column>
                                <Grid.Column>
                                    {data.review.started_at > 0 &&
                                        getDateTime(data.review.started_at)}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Published at:</Grid.Column>
                                <Grid.Column>
                                    {data.review.published_at > 0 &&
                                        getDateTime(data.review.published_at)}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Objective:</Grid.Column>
                                <Grid.Column>{data.details.objective}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Deadline:</Grid.Column>
                                <Grid.Column>
                                    {data.review.deadline_at > 0 &&
                                        getDateTime(data.review.deadline_at)}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Additional Comments:</Grid.Column>
                                <Grid.Column>{data.details.additional_comments}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }

    renderCreativeDetails() {
        const { data } = this.state;

        const creative = data.creative_details;
        if (!creative) {
            return (
                <Card fluid>
                    <Card.Content>
                        <Card.Header>Creative Details</Card.Header>
                        <Card.Description>
                            <Message color="red">
                                Creative details not found. Probably the creative was removed from
                                the library.
                            </Message>
                        </Card.Description>
                    </Card.Content>
                </Card>
            );
        }

        const company = data.company_details;

        const tags = creative.tags.map((tag) => {
            return <Label key={tag.name}>#{tag.name}</Label>;
        });

        const colors = creative.colors.map((color) => {
            return (
                <Label key={color} style={{ backgroundColor: color }}>
                    {color}
                </Label>
            );
        });

        let asset = '';
        if (creative.asset && creative.asset.media_info.type === 'image') {
            asset = <Image size="medium" src={creative.asset.url} />;
        }
        if (creative.asset && creative.asset.media_info.type === 'video') {
            asset = (
                <video controls height="240" width="320">
                    <source src={creative.asset.url} type="video/mp4" />
                </video>
            );
        }

        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>Creative Details</Card.Header>
                    <Card.Description>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <Button
                                        size="mini"
                                        content="OPEN"
                                        color="olive"
                                        labelPosition="right"
                                        icon="share"
                                        onClick={() => {
                                            window.open(
                                                `https://${company.subdomain}.solsten.io/frequency/creatives/${creative.id}`,
                                                '_blank'
                                            );
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column />
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Creative #:</Grid.Column>
                                <Grid.Column>#{creative.id}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Nickname:</Grid.Column>
                                <Grid.Column>{creative.nickname}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Tags:</Grid.Column>
                                <Grid.Column>{tags}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Colors:</Grid.Column>
                                <Grid.Column>{colors}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Asset Type:</Grid.Column>
                                <Grid.Column>
                                    {creative.asset ? creative.asset.media_info.type : ''}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>Asset:</Grid.Column>
                                <Grid.Column>
                                    <a href={creative.asset.url}>Download raw asset file</a>
                                    <br />
                                    {asset}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }

    renderTargetedAudience() {
        const { data } = this.state;

        const { details } = data;
        const audience = details.targeted_audience;

        let audienceHeader = 'Custom Audience';
        let audienceDescription = details.custom_targeted_audience;
        if (details.targeted_audience_type === 'solsten' && audience) {
            audienceHeader = 'Solsten Audience';
            audienceDescription = `${audience.dashboard_name} // ${audience.segment_name} // ${audience.cluster_name}`;
        }

        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>Targeted Audience (specified by client)</Card.Header>
                    <Card.Description>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column width={3}>Audience Type:</Grid.Column>
                                <Grid.Column>{details.targeted_audience_type}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={3}>{audienceHeader}</Grid.Column>
                                <Grid.Column>{audienceDescription}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }

    renderBestTargetedAudience() {
        const { data, dashboards } = this.state;

        const { review } = data;

        let dashboardsOptions = dashboards
            .filter((d) => d.company === data.company_id)
            .map((g) => {
                return { value: g.id, text: g.name };
            });
        dashboardsOptions = [{ value: null, text: '' }, ...dashboardsOptions];

        const filteredDashboard = dashboards.filter(
            (d) => review.best_audience && d.id === review.best_audience.dashboard_id
        );

        let segmentsOptions = [];
        let personasOptions = [];
        if (filteredDashboard.length) {
            segmentsOptions = filteredDashboard[0].segments.map((s) => {
                return { value: s.id, text: s.title };
            });

            const filteredSegments = filteredDashboard[0].segments.filter(
                (s) =>
                    review.best_audience &&
                    (s.id === review.best_audience.segment_id ||
                        (!review.best_audience.segment_id && s.is_default))
            );

            if (filteredSegments.length) {
                personasOptions = filteredSegments[0].clusters.map((s) => {
                    return { value: s.value, text: s.name };
                });
            }
        }

        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>Best Targeted Audience</Card.Header>
                    <Card.Description>
                        <Form>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column width={3}>Dashboard:</Grid.Column>
                                    <Grid.Column>
                                        <Form.Field
                                            control={Select}
                                            onChange={(ev, option) => {
                                                this.setState((prevState) => {
                                                    const prevStateData = prevState.data;

                                                    if (!prevStateData.review.best_audience) {
                                                        prevStateData.review.best_audience = {};
                                                    }
                                                    prevStateData.review.best_audience.dashboard_id =
                                                        option.value;
                                                    prevStateData.review.best_audience.segment_id =
                                                        null;
                                                    prevStateData.review.best_audience.cluster =
                                                        null;

                                                    return { data: prevStateData };
                                                });
                                            }}
                                            options={dashboardsOptions}
                                            search
                                            value={
                                                review.best_audience
                                                    ? review.best_audience.dashboard_id
                                                    : null
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3}>Segment:</Grid.Column>
                                    <Grid.Column>
                                        <Form.Field
                                            control={Select}
                                            onChange={(ev, option) => {
                                                this.setState((prevState) => {
                                                    const prevStateData = prevState.data;

                                                    if (!prevStateData.review.best_audience) {
                                                        prevStateData.review.best_audience = {};
                                                    }
                                                    prevStateData.review.best_audience.segment_id =
                                                        option.value;
                                                    prevStateData.review.best_audience.cluster =
                                                        null;

                                                    return { data: prevStateData };
                                                });
                                            }}
                                            options={segmentsOptions}
                                            search
                                            value={
                                                review.best_audience &&
                                                data.review.best_audience.segment_id
                                                    ? review.best_audience.segment_id
                                                    : '000000000000000000000000'
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3}>Persona:</Grid.Column>
                                    <Grid.Column>
                                        <Form.Field
                                            control={Select}
                                            onChange={(ev, option) => {
                                                this.setState((prevState) => {
                                                    const prevStateData = prevState.data;

                                                    if (!prevStateData.review.best_audience) {
                                                        prevStateData.review.best_audience = {};
                                                    }
                                                    prevStateData.review.best_audience.cluster =
                                                        option.value;

                                                    return { data: prevStateData };
                                                });
                                            }}
                                            options={personasOptions}
                                            search
                                            value={
                                                review.best_audience &&
                                                review.best_audience.cluster !== null &&
                                                review.best_audience.cluster !== undefined
                                                    ? `${review.best_audience.cluster}`
                                                    : 'all'
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3}>
                                        <Button
                                            size="mini"
                                            content="SAVE"
                                            color="teal"
                                            labelPosition="right"
                                            icon="save outline"
                                            onClick={async () => {
                                                const bestAudience =
                                                    this.state.data.review.best_audience;
                                                if (
                                                    bestAudience.segment_id ===
                                                    '000000000000000000000000'
                                                ) {
                                                    bestAudience.segment_id = null;
                                                }
                                                if (bestAudience.cluster === 'all') {
                                                    bestAudience.cluster = null;
                                                } else if (bestAudience.cluster >= 0) {
                                                    bestAudience.cluster = parseInt(
                                                        bestAudience.cluster,
                                                        10
                                                    );
                                                }

                                                try {
                                                    await updateFrequencyReview(
                                                        this.state.data.id,
                                                        {
                                                            best_audience: bestAudience,
                                                        }
                                                    );

                                                    this.setState({
                                                        modal: {
                                                            title: 'Success',
                                                            content:
                                                                'Targeted Audience has been successfully saved',
                                                            isOpen: true,
                                                            isError: false,
                                                        },
                                                    });
                                                } catch (e) {
                                                    this.setState({
                                                        modal: {
                                                            title: 'Error',
                                                            content:
                                                                'Unable to update Targeted Audience',
                                                            isOpen: true,
                                                            isError: true,
                                                        },
                                                    });
                                                }
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column />
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }

    renderRecommendations() {
        const { data } = this.state;

        const { review } = data;
        if (!review.next_steps) {
            review.next_steps = ['', '', ''];
        }

        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>Review & Recommendations</Card.Header>
                    <Card.Description>
                        <Form>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column width={3} />
                                    <Grid.Column>
                                        <Message color="teal">
                                            All text fields below support{' '}
                                            <a href="https://www.markdownguide.org/cheat-sheet/">
                                                Makrdown formatting
                                            </a>
                                            .
                                        </Message>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3}>
                                        Appears as Medium-like or article-like headaline. Think
                                        about ways to catch the user's attention while communicating
                                        the gist of your recommendation.
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Headline (55 chars max)</label>
                                            <Form.Input
                                                maxLength="55"
                                                name="headline"
                                                onChange={(e, value) => {
                                                    this.setState((prevState) => {
                                                        const prevStateData = prevState.data;
                                                        prevStateData.review.headline = value.value;

                                                        return { data: prevStateData };
                                                    });
                                                }}
                                                value={review.headline || ''}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3}>
                                        Appears as Medium-like or article-like sub-header. Think
                                        about it as a TV episode summary, giving users a taste of
                                        what we found and recommended. This appears on the overview
                                        page and is the first thing a users sees when seeing a
                                        creative review.
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Review Summary (30 words max)</label>
                                            <Form.TextArea
                                                name="summary"
                                                onChange={(e, value) => {
                                                    this.setState((prevState) => {
                                                        const prevStateData = prevState.data;
                                                        prevStateData.review.summary = value.value;

                                                        return { data: prevStateData };
                                                    });
                                                }}
                                                value={review.summary || ''}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3}>
                                        Consider these like to-do list items that are highly
                                        specific and actionable recommendations for users to improve
                                        their creative. Be sure to keep the target audience aand
                                        objective in mind.
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Next Steps (first line)</label>

                                            <Form.TextArea
                                                name="next_steps_0"
                                                onChange={(e, value) => {
                                                    this.setState((prevState) => {
                                                        const prevStateData = prevState.data;
                                                        if (
                                                            !prevStateData.review.next_steps ||
                                                            prevStateData.review.next_steps
                                                                .length === 0
                                                        ) {
                                                            prevStateData.review.next_steps = [
                                                                '',
                                                                '',
                                                                '',
                                                            ];
                                                        }

                                                        prevStateData.review.next_steps[0] =
                                                            value.value;

                                                        return { data: prevStateData };
                                                    });
                                                }}
                                                value={review.next_steps[0] || ''}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} />
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Next Steps (second line)</label>

                                            <Form.TextArea
                                                name="next_steps_1"
                                                onChange={(e, value) => {
                                                    this.setState((prevState) => {
                                                        const prevStateData = prevState.data;
                                                        if (
                                                            !prevStateData.review.next_steps ||
                                                            prevStateData.review.next_steps
                                                                .length === 0
                                                        ) {
                                                            prevStateData.review.next_steps = [
                                                                '',
                                                                '',
                                                                '',
                                                            ];
                                                        }

                                                        prevStateData.review.next_steps[1] =
                                                            value.value;

                                                        return { data: prevStateData };
                                                    });
                                                }}
                                                value={review.next_steps[1] || ''}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} />
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Next Steps (third line)</label>

                                            <Form.TextArea
                                                name="next_steps_2"
                                                onChange={(e, value) => {
                                                    this.setState((prevState) => {
                                                        const prevStateData = prevState.data;
                                                        if (
                                                            !prevStateData.review.next_steps ||
                                                            prevStateData.review.next_steps
                                                                .length === 0
                                                        ) {
                                                            prevStateData.review.next_steps = [
                                                                '',
                                                                '',
                                                                '',
                                                            ];
                                                        }

                                                        prevStateData.review.next_steps[2] =
                                                            value.value;

                                                        return { data: prevStateData };
                                                    });
                                                }}
                                                value={review.next_steps[2] || ''}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3}>
                                        It's best to first describe what you're seeing in the
                                        creative and help users understand why their creative is
                                        performing the way that they listed. Then focus on providing
                                        recommendations tailored around the target persona, with
                                        strong hints based in their persona's psychological trait
                                        scores. Try your best to never tell users exactly what they
                                        should do.
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Deep Dive</label>

                                            <Form.TextArea
                                                name="deep_dive"
                                                onChange={(e, value) => {
                                                    this.setState((prevState) => {
                                                        const prevStateData = prevState.data;
                                                        prevStateData.review.deep_dive =
                                                            value.value;

                                                        return { data: prevStateData };
                                                    });
                                                }}
                                                value={review.deep_dive || ''}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3}>
                                        <Button
                                            size="mini"
                                            content="SAVE"
                                            color="teal"
                                            labelPosition="right"
                                            icon="save outline"
                                            onClick={async () => {
                                                try {
                                                    await updateFrequencyReview(
                                                        this.state.data.id,
                                                        pick(this.state.data.review, [
                                                            'headline',
                                                            'summary',
                                                            'next_steps',
                                                            'deep_dive',
                                                        ])
                                                    );

                                                    this.setState({
                                                        modal: {
                                                            title: 'Success',
                                                            content:
                                                                'Recommendations have been successfully saved',
                                                            isOpen: true,
                                                            isError: false,
                                                        },
                                                    });
                                                } catch (e) {
                                                    this.setState({
                                                        modal: {
                                                            title: 'Error',
                                                            content:
                                                                'Unable to update recommendations',
                                                            isOpen: true,
                                                            isError: true,
                                                        },
                                                    });
                                                }
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column />
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }

    render() {
        const { data, modal } = this.state;

        if (modal.isOpen) {
            return (
                <Container>
                    <LinkNavbar title="Frequency Review" />
                    {modal.isOpen && <Modal {...modal} handleCloseModal={this.handleCloseModal} />}
                </Container>
            );
        }

        if (data) {
            return (
                <Container>
                    <LinkNavbar title="Frequency Review" />
                    <Button.Group>
                        <Button
                            size="mini"
                            content="Back to List"
                            color="olive"
                            labelPosition="right"
                            icon="arrow left"
                            onClick={this.handleOpenList()}
                        />
                    </Button.Group>
                    <Header as="h1">
                        Frequency Review #{data.id} from {data.company_details.name}
                    </Header>
                    {this.renderOverview()}
                    {this.renderCreativeDetails()}
                    {this.renderTargetedAudience()}
                    {this.renderBestTargetedAudience()}
                    {this.renderRecommendations()}
                    <br />
                    <br />
                </Container>
            );
        }

        return '';
    }
}

export default withRouter(FrequencyReviewPage);
