import {
    createAdminGetEndpoint,
    createAdminPostEndpoint,
    createAdminPutEndpoint,
    createAdminDeleteEndpoint,
} from './index';

export const COMPANY_TYPES = {
    0: 'Gaming',
    1: 'Non-Gaming',
    2: 'Healthcare',
};

export const getAllCompanies = async () => {
    const res = await createAdminGetEndpoint('companies')();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data.companies;
};

export const createCompany = createAdminPostEndpoint('companies');

export const updateCompany = async (id, req) => {
    const res = await createAdminPutEndpoint(`companies/${id}`)(req);
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};

export const deleteCompany = async (id) => {
    const res = await createAdminDeleteEndpoint(`companies/${id}`)();
    const metadata = res.data || {};
    const data = metadata.data || {};

    return data;
};
