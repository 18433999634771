import moment from 'moment';

export const getDateOfRequest = (createdAt) => {
    if (!createdAt) {
        return '';
    }

    const createdAtTime = moment(createdAt * 1000);

    return `${createdAtTime.format('MMM D, YYYY')}`;
};

export const getDateTime = (timestamp) => {
    const datetime = moment(timestamp * 1000);

    return datetime.format('MMM D, YYYY HH:mm');
};

export const getCurrentTimestamp = () => {
    return moment().unix();
};

export default {
    getDateOfRequest,
    getDateTime,
    getCurrentTimestamp,
};
