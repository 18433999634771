import T from 'prop-types';

import { Container, Inner, BrandLink } from './style';

export const Navbar = ({ children }) => (
    <Container>
        <Inner>
            <BrandLink to="/">
                <img alt="solsten" height="40" src="/images/logo.png" />
            </BrandLink>
            {children}
        </Inner>
    </Container>
);

Navbar.propTypes = {
    children: T.node,
};

Navbar.defaultProps = {
    children: null,
};

export default Navbar;
