import React from 'react';
import T from 'prop-types';
import { registerDeeplink } from '../../api/identity/deeplinks';
import DeeplinkForm from './deeplinkForm';
import withRouter from '@/hocs/withRouter';

class DeeplinkContainer extends React.Component {
    onSubmit = async (data) => {
        await registerDeeplink(data);

        this.props.navigate('');
    };

    render() {
        return <DeeplinkForm deeplink={this.props.deeplink} onSubmit={this.onSubmit} />;
    }
}

DeeplinkContainer.propTypes = {
    deeplink: T.object,
};

DeeplinkContainer.defaultProps = {
    deeplink: undefined,
};

export default withRouter(DeeplinkContainer);
