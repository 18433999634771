import {
    createGetEndpoint,
    createDelEndpoint,
    createPatchEndpoint,
    createPostEndpoint,
} from './index';

export const getResponses = async (gameID) => {
    const res = await createGetEndpoint(`/v1/games/${gameID}/responses`)();
    const metadata = res.data || {};

    return metadata;
};

export const getSurveyDetails = async (gameID) => {
    const res = await createGetEndpoint(`/v1/games/${gameID}/survey`)();
    const metadata = res.data || {};

    return metadata;
};

export const checkFiles = async (gameID) => {
    const res = await createGetEndpoint(`/v1/games/${gameID}/check-files`)();
    const metadata = res.data || {};

    return metadata;
};

export const deleteResponses = async (gameID) => {
    const res = await createDelEndpoint(`/v1/games/${gameID}/responses`)();
    const metadata = res.data || {};

    return metadata;
};

export const getAllAssessmentCreatives = async () => {
    const res = await createGetEndpoint(`/v2/admin/surveys/creatives/configs`)();
    const metadata = res.data || {};

    return metadata.data || [];
};

export const createAssessmentCreativesConfig = async (data) => {
    const res = await createPostEndpoint(`/v2/admin/surveys/creatives/configs`)(data);
    const metadata = res.data || {};

    return metadata.data || {};
};

export const deleteAssessmentCreativesConfig = async (assessmentID) => {
    const res = await createDelEndpoint(`/v2/admin/surveys/creatives/configs/${assessmentID}`)();

    return res.data || {};
};

export const getAssessmentRequests = async (filterStr) => {
    const res = await createGetEndpoint(`/v2/admin/surveys?${filterStr}`)();
    const metadata = res.data || {};

    return metadata.data || [];
};

export const deleteAssessmentRequest = async (id) => {
    const res = await createDelEndpoint(`/v3/admin/assessments/${id}`)();

    return res.data || {};
};

export const updateAssessmentRequest = async (id, data) => {
    const res = await createPatchEndpoint(`/v3/admin/assessments/${id}`)({ status: data.status });
    const metadata = res.data || {};

    return metadata.data || {};
};

export const downloadAssessmentData = async (id) => {
    const res = await createPostEndpoint(`/v3/admin/assessments/${id}/downloadData`)({});
    const metadata = res.data || {};

    return metadata.data || {};
};

export const getAssessmentRequestChangelog = async (id) => {
    const res = await createGetEndpoint(`/v3/admin/assessments/${id}/changelog`)();
    const metadata = res.data || {};

    return metadata.data.changelog || [];
};

export const upsertTeamAssessment = createPostEndpoint('/v2/admin/teamAssessments');

export const getTeamAssessments = async () => {
    const res = await createGetEndpoint('/v2/admin/teamAssessments')();
    if (!res.data || !res.data.data) {
        return [];
    }

    return res.data.data;
};

export const deleteTeamAssessment = async (id) => {
    await createDelEndpoint(`/v2/admin/teamAssessments/${id}`)();
};
