import T from 'prop-types';
import { Icon, Dropdown, Popup } from 'semantic-ui-react';
import Navbar, { NavbarTitle, NavbarLinks, NavbarLink, NavbarIcon } from '../navbar';
import { getUserSession, logout } from '../../api/admin/auth';
import { useNavigate } from 'react-router-dom';

const LinkNavbar = ({ title }) => {
    const navigate = useNavigate();
    const handleClickNavigate = (path) => () => {
        navigate(path);
    };

    const res = getUserSession();
    const onlySystemUsers = res && (res.role === 'system' || res.role === 'admin');
    const onlyAdminUsers = res && res.role === 'admin';

    return (
        <Navbar>
            <NavbarTitle>Solsten Admin - {title}</NavbarTitle>
            <NavbarLinks>
                <NavbarLink hidden={!onlySystemUsers} to="/users">
                    <Popup
                        content="Users"
                        position="bottom center"
                        trigger={<Icon name="users" />}
                    />
                </NavbarLink>
                <NavbarLink hidden={!onlySystemUsers} to="/companies">
                    <Popup
                        content="Companies"
                        position="bottom center"
                        trigger={<Icon name="address card" />}
                    />
                </NavbarLink>
                <NavbarIcon>
                    <Dropdown icon="gamepad">
                        <Dropdown.Menu direction="left">
                            <Dropdown.Item
                                onClick={handleClickNavigate('/games')}
                                disabled={!onlySystemUsers}
                            >
                                Dashboards
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={handleClickNavigate('/assessment/requests')}
                                disabled={!onlySystemUsers}
                            >
                                Assessment Requests
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={handleClickNavigate('/teamAssessments')}
                                disabled={!onlySystemUsers}
                            >
                                Team Assessments
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </NavbarIcon>
                <NavbarIcon>
                    <Dropdown icon="crosshairs">
                        <Dropdown.Menu direction="left">
                            <Dropdown.Item
                                onClick={handleClickNavigate('/frequency-reviews')}
                                disabled={!onlySystemUsers}
                            >
                                Frequency Reviews
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={handleClickNavigate('/creatives')}
                                disabled={!onlySystemUsers}
                            >
                                Frequency Creatives
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={handleClickNavigate('/assessment-creatives')}
                                disabled={!onlySystemUsers}
                            >
                                Assessment Creatives
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </NavbarIcon>
                <NavbarLink hidden={!onlySystemUsers} to="/actions">
                    <Popup
                        content="Actions"
                        position="bottom center"
                        trigger={<Icon name="list alternate" />}
                    />
                </NavbarLink>
                <NavbarLink hidden={!onlySystemUsers} to="/deeplinks">
                    <Popup
                        content="Survey Deeplinks"
                        position="bottom center"
                        trigger={<Icon name="linkify" />}
                    />
                </NavbarLink>
                <NavbarIcon>
                    <Dropdown icon="cogs">
                        <Dropdown.Menu direction="left">
                            <Dropdown.Item onClick={handleClickNavigate('/games/tagging')}>
                                Games Tagging
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleClickNavigate('/genres')}>
                                Genres
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleClickNavigate('/taxonomy')}>
                                Taxonomy
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </NavbarIcon>
                {res && (
                    <NavbarIcon>
                        <Dropdown icon="user circle">
                            <Dropdown.Menu direction="left">
                                <Dropdown.Item>
                                    Hi <b>{res.email}</b>!
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleClickNavigate('/account-settings')}>
                                    Account Settings
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={handleClickNavigate('/invite-users')}
                                    disabled={!onlyAdminUsers}
                                >
                                    Manage Users
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        logout();
                                        navigate('/sign-in');
                                    }}
                                >
                                    Sign Out
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </NavbarIcon>
                )}
            </NavbarLinks>
        </Navbar>
    );
};

LinkNavbar.propTypes = {
    title: T.string,
};

LinkNavbar.defaultProps = {
    title: '',
};

export default LinkNavbar;
