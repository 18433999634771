import styled from 'styled-components';

export const ModalHeader = styled.div`
    padding: 20px;
    font-weight: bold;
    font-size: 24px;
`;

export const BtnGroup = styled.div`
    margin-top: 30px;
    display: flex;
    padding: 20px;
    > button:first-child {
        margin-right: 10px;
    }
`;
