import React from 'react';
import T from 'prop-types';
import { pick } from 'lodash';
import { Form, Grid, Input, Button, Message, FormGroup } from 'semantic-ui-react';
import { COMPANY_TYPES } from '../../api/admin/companies';

class CompanyRegistrationForm extends React.Component {
    state = {
        id: '',
        name: '',
        subdomain: '',
        company_type: 0,
        csm_admin_user_id: null,
        csm_admin_user_calendar_link_id: null,
        enable_navigator: true,
        frequency_purchased: false,
        enable_resonance_score: false,
        frequency_permissions: {
            read_only: false,
            super_admin_only: false,
            tag_builder_superadmin_only: false,
            reviews_superadmin_only: false,
        },
        max_allowed_frequency_reviews: 2,
        is_demo: false,
        resonance_model_name: 'RESONANCE_V1_GENERAL',
        social_listening_enabled: false,
        social_listening_superadmin_only: false,
        whitelisted_domains: '',
        isAdding: false,
        errMessage: '',
    };

    constructor(props) {
        super(props);
        if (props.currentCompany !== undefined) {
            this.state = { ...this.state, ...props.currentCompany };
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    };

    render() {
        const { onSubmit, adminUsers } = this.props;
        if (!adminUsers) {
            return '';
        }
        const adminUsersOptions = [
            { value: null, text: 'Not specified' },
            ...adminUsers.map((user) => ({
                value: user.id,
                text: user.email,
            })),
        ];
        let calendarLinksOptions = [];
        if (this.state.csm_admin_user_id) {
            const adminUser = adminUsers.find((user) => user.id === this.state.csm_admin_user_id);
            let calendarLinks = [];
            if (adminUser) calendarLinks = adminUser.calendar_links || [];

            calendarLinksOptions = [
                { value: null, text: 'Not specified' },
                ...calendarLinks.map((calendarLink) => ({
                    value: calendarLink.id,
                    text: calendarLink.calendar_link,
                })),
            ];
        }

        const isValidForm = () => {
            const { name, subdomain, whitelisted_domains } = this.state;

            return name && subdomain && whitelisted_domains;
        };

        return (
            <Grid centered>
                <Grid.Column>
                    <Form
                        onSubmit={async () => {
                            this.setState({
                                isAdding: true,
                            });
                            const res = await onSubmit(
                                pick(this.state, [
                                    'id',
                                    'name',
                                    'subdomain',
                                    'company_type',
                                    'csm_admin_user_id',
                                    'csm_admin_user_calendar_link_id',
                                    'enable_navigator',
                                    'frequency_purchased',
                                    'enable_resonance_score',
                                    'frequency_permissions',
                                    'is_demo',
                                    'resonance_model_name',
                                    'max_allowed_frequency_reviews',
                                    'social_listening_enabled',
                                    'social_listening_superadmin_only',
                                    'whitelisted_domains',
                                ])
                            );

                            if (res) {
                                this.setState({
                                    isAdding: false,
                                    errMessage: '',
                                });
                            } else {
                                this.setState({
                                    isAdding: false,
                                    errMessage: 'Error saving company',
                                });
                            }
                        }}
                    >
                        <FormGroup widths="equal">
                            <Form.Field>
                                <label>Name *</label>
                                <Form.Input
                                    name="name"
                                    onChange={this.handleChange}
                                    value={this.state.name || ''}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="Company Type *"
                                    name="company_type"
                                    onChange={(ev, data) =>
                                        this.setState({ company_type: data.value })
                                    }
                                    options={Object.keys(COMPANY_TYPES).map((key) => {
                                        return { value: Number(key), text: COMPANY_TYPES[key] };
                                    })}
                                    selection
                                    value={this.state.company_type}
                                />
                            </Form.Field>
                        </FormGroup>
                        <FormGroup widths="equal">
                            <Form.Field>
                                <label>Subdomain (created automatically) *</label>
                                <Input
                                    label=".solsten.io"
                                    labelPosition="right"
                                    name="subdomain"
                                    onChange={this.handleChange}
                                    value={this.state.subdomain || ''}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Whitelisted Email Domains (comma-separated) *</label>
                                <Form.Input
                                    name="whitelisted_domains"
                                    onChange={this.handleChange}
                                    value={this.state.whitelisted_domains || ''}
                                />
                            </Form.Field>
                        </FormGroup>
                        <FormGroup widths="equal">
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="CSM User"
                                    name="csm_admin_user_id"
                                    onChange={(ev, data) =>
                                        this.setState({
                                            csm_admin_user_id: data.value,
                                            csm_admin_user_calendar_link_id: null,
                                        })
                                    }
                                    options={adminUsersOptions}
                                    selection
                                    value={this.state.csm_admin_user_id}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="CSM Calendar Link"
                                    name="csm_admin_user_calendar_link_id"
                                    onChange={(ev, data) =>
                                        this.setState({
                                            csm_admin_user_calendar_link_id: data.value,
                                        })
                                    }
                                    options={calendarLinksOptions}
                                    selection
                                    value={this.state.csm_admin_user_calendar_link_id}
                                />
                            </Form.Field>
                        </FormGroup>
                        <FormGroup widths="equal">
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="Demo Company (offline payment)"
                                    name="is_demo"
                                    onChange={(ev, data) => this.setState({ is_demo: data.value })}
                                    options={[
                                        { value: false, text: 'No' },
                                        { value: true, text: 'Yes' },
                                    ]}
                                    selection
                                    value={this.state.is_demo}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="Enable Navigator"
                                    name="enable_navigator"
                                    onChange={(ev, data) =>
                                        this.setState({ enable_navigator: data.value })
                                    }
                                    options={[
                                        { value: false, text: 'No' },
                                        { value: true, text: 'Yes' },
                                    ]}
                                    selection
                                    value={this.state.enable_navigator}
                                />
                            </Form.Field>
                        </FormGroup>

                        <Form.Field>
                            <Form.Select
                                fluid
                                label="Frequency Purchased"
                                name="frequency_purchased"
                                onChange={(ev, data) =>
                                    this.setState({ frequency_purchased: data.value })
                                }
                                options={[
                                    { value: false, text: 'No' },
                                    { value: true, text: 'Yes' },
                                ]}
                                selection
                                value={this.state.frequency_purchased}
                            />
                        </Form.Field>
                        {this.state.frequency_purchased && (
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="Enable Resonance Score"
                                    name="enable_resonance_score"
                                    onChange={(ev, data) =>
                                        this.setState({
                                            enable_resonance_score: data.value,
                                        })
                                    }
                                    options={[
                                        { value: false, text: 'No' },
                                        { value: true, text: 'Yes' },
                                    ]}
                                    selection
                                    value={this.state.enable_resonance_score}
                                />
                            </Form.Field>
                        )}
                        {this.state.frequency_purchased && (
                            <Form.Field>
                                <label>Custom Resonance Score model name</label>
                                <Input
                                    fluid
                                    name="resonance_model_name"
                                    onChange={this.handleChange}
                                    placeholder="Resonance Model Name"
                                    value={this.state.resonance_model_name || ''}
                                />
                            </Form.Field>
                        )}
                        {this.state.frequency_purchased && (
                            <Form.Field>
                                <Form.Select
                                    defaultValue={this.state.frequency_permissions.read_only}
                                    fluid
                                    label="Frequency Read Only"
                                    name="frequency_permissions.read_only"
                                    onChange={(ev, data) =>
                                        this.setState((prevState) => {
                                            const prevPerm = prevState.frequency_permissions;
                                            prevPerm.read_only = data.value;

                                            return { frequency_permissions: prevPerm };
                                        })
                                    }
                                    options={[
                                        { value: false, text: 'No' },
                                        { value: true, text: 'Yes' },
                                    ]}
                                    selection
                                />
                            </Form.Field>
                        )}
                        {this.state.frequency_purchased && (
                            <Form.Field>
                                <Form.Select
                                    defaultValue={this.state.frequency_permissions.super_admin_only}
                                    fluid
                                    label="Frequency Super Admin Only"
                                    name="frequency_permissions.super_admin_only"
                                    onChange={(ev, data) =>
                                        this.setState((prevState) => {
                                            const prevPerm = prevState.frequency_permissions;
                                            prevPerm.super_admin_only = data.value;

                                            return { frequency_permissions: prevPerm };
                                        })
                                    }
                                    options={[
                                        { value: false, text: 'No' },
                                        { value: true, text: 'Yes' },
                                    ]}
                                    selection
                                />
                            </Form.Field>
                        )}
                        {this.state.frequency_purchased && (
                            <Form.Field>
                                <Form.Select
                                    defaultValue={
                                        this.state.frequency_permissions.tag_builder_superadmin_only
                                    }
                                    fluid
                                    label="Frequency Tag Builder Superadmin Only"
                                    name="frequency_permissions.tag_builder_superadmin_only"
                                    onChange={(ev, data) =>
                                        this.setState((prevState) => {
                                            const prevPerm = prevState.frequency_permissions;
                                            prevPerm.tag_builder_superadmin_only = data.value;

                                            return { frequency_permissions: prevPerm };
                                        })
                                    }
                                    options={[
                                        { value: false, text: 'No' },
                                        { value: true, text: 'Yes' },
                                    ]}
                                    selection
                                />
                            </Form.Field>
                        )}
                        {this.state.frequency_purchased && (
                            <Form.Field>
                                <Form.Select
                                    defaultValue={
                                        this.state.frequency_permissions.reviews_superadmin_only
                                    }
                                    fluid
                                    label="Frequency Reviews Superadmin Only"
                                    name="frequency_permissions.reviews_superadmin_only"
                                    onChange={(ev, data) =>
                                        this.setState((prevState) => {
                                            const prevPerm = prevState.frequency_permissions;
                                            prevPerm.reviews_superadmin_only = data.value;

                                            return { frequency_permissions: prevPerm };
                                        })
                                    }
                                    options={[
                                        { value: false, text: 'No' },
                                        { value: true, text: 'Yes' },
                                    ]}
                                    selection
                                />
                            </Form.Field>
                        )}
                        {this.state.frequency_purchased && (
                            <Form.Field>
                                <label>Frequency: Max Reviews</label>
                                <Input
                                    fluid
                                    name="max_allowed_frequency_reviews"
                                    onChange={this.handleChange}
                                    value={this.state.max_allowed_frequency_reviews || ''}
                                />
                            </Form.Field>
                        )}
                        <FormGroup widths="equal">
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="Enable Social Listening"
                                    name="social_listening_enabled"
                                    onChange={(ev, data) =>
                                        this.setState({ social_listening_enabled: data.value })
                                    }
                                    options={[
                                        { value: false, text: 'No' },
                                        { value: true, text: 'Yes' },
                                    ]}
                                    selection
                                    value={this.state.social_listening_enabled}
                                />
                            </Form.Field>
                            {this.state.social_listening_enabled && (
                                <Form.Field>
                                    <Form.Select
                                        fluid
                                        label="Social Listening Superadmin Only"
                                        name="social_listening_superadmin_only"
                                        onChange={(ev, data) =>
                                            this.setState({
                                                social_listening_superadmin_only: data.value,
                                            })
                                        }
                                        options={[
                                            { value: false, text: 'No' },
                                            { value: true, text: 'Yes' },
                                        ]}
                                        selection
                                        value={this.state.social_listening_superadmin_only}
                                    />
                                </Form.Field>
                            )}
                        </FormGroup>
                        <Button
                            content={this.state.isAdding ? 'Saving...' : 'Save'}
                            color="teal"
                            labelPosition="right"
                            icon="save outline"
                            disabled={this.state.isAdding || !isValidForm()}
                            type="submit"
                        />
                    </Form>
                    {this.state.errMessage && <Message negative>{this.state.errMessage}</Message>}
                </Grid.Column>
            </Grid>
        );
    }
}

CompanyRegistrationForm.propTypes = {
    onSubmit: T.func.isRequired,
    currentCompany: T.object,
    adminUsers: T.array,
};

CompanyRegistrationForm.defaultProps = {
    currentCompany: undefined,
    adminUsers: undefined,
};

export default CompanyRegistrationForm;
