import T from 'prop-types';
import { Form } from 'semantic-ui-react';

const getOptionsFrom = (companies) =>
    companies.map((c) => ({ value: c.id, text: `${c.subdomain}.solsten.io` }));

const CompanySelector = ({ companies, onChangeCompany, value }) => (
    <Form.Field>
        <Form.Select
            fluid
            label="Company *"
            name="company_id"
            onChange={(ev, data) => onChangeCompany(data.value)}
            options={getOptionsFrom(companies)}
            search
            selection
            value={value}
        />
    </Form.Field>
);

CompanySelector.propTypes = {
    companies: T.arrayOf(
        T.shape({
            id: T.string,
            subdomain: T.string,
        })
    ),
    onChangeCompany: T.func.isRequired,
    value: T.string,
};

CompanySelector.defaultProps = {
    companies: [],
    value: undefined,
};

export default CompanySelector;
