import React from 'react';
import T from 'prop-types';
import { Button, Form, Grid, Segment } from 'semantic-ui-react';
import { Container } from '../style';

class DeeplinkForm extends React.Component {
    constructor(props) {
        super(props);
        if (props.deeplink) {
            this.state = props.deeplink;
        } else {
            this.state = {
                game_name: '',
                survey_id: 0,
                active: true,
                is_static: false,
                link: '',
                custom_template: '',
            };
        }
    }

    render() {
        const { onSubmit } = this.props;

        return (
            <Container>
                <Grid centered columns={2} padded>
                    <Grid.Column>
                        <Segment>
                            <Form onSubmit={() => onSubmit(this.state)}>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Game Name"
                                        name="game_name"
                                        onChange={(ev, data) =>
                                            this.setState({ game_name: data.value })
                                        }
                                        value={this.state.game_name}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Alchemer Survey ID"
                                        name="survey_id"
                                        onChange={(ev, data) => {
                                            let parsed = parseInt(data.value, 10);
                                            if (Number.isNaN(parsed)) {
                                                parsed = 0;
                                            }
                                            this.setState({ survey_id: parsed });
                                        }}
                                        value={this.state.survey_id}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Select
                                        fluid
                                        label="Is Active?"
                                        name="active"
                                        onChange={(ev, data) =>
                                            this.setState({ active: data.value })
                                        }
                                        options={[
                                            { value: true, text: 'Yes' },
                                            { value: false, text: 'No' },
                                        ]}
                                        selection
                                        value={this.state.active}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Select
                                        fluid
                                        label="Is Static?"
                                        name="is_static"
                                        onChange={(ev, data) =>
                                            this.setState({ is_static: data.value })
                                        }
                                        options={[
                                            { value: true, text: 'Yes' },
                                            { value: false, text: 'No' },
                                        ]}
                                        selection
                                        value={this.state.is_static}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        label="Link (Include ?token=%s if static=No)"
                                        name="link"
                                        onChange={(ev, data) => this.setState({ link: data.value })}
                                        value={this.state.link}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.TextArea
                                        label="Custom Template (optional)"
                                        name="custom_template"
                                        onChange={(ev, data) =>
                                            this.setState({ custom_template: data.value })
                                        }
                                        value={this.state.custom_template}
                                    />
                                </Form.Field>
                                <Button
                                    size="mini"
                                    content="SAVE"
                                    color="teal"
                                    labelPosition="right"
                                    icon="save outline"
                                    type="submit"
                                />
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}

DeeplinkForm.propTypes = {
    onSubmit: T.func.isRequired,
    deeplink: T.object,
};

DeeplinkForm.defaultProps = {
    deeplink: undefined,
};

export default DeeplinkForm;
